<template>
    <div>

        <div class="mb-8">
            <div class="-my-2 py-2 sm:-mx-6 sm:px-6 lg:-mx-8 lg:px-8">
                <div class="align-middle inline-block min-w-full shadow rounded-lg bg-white">
                    <div>
                        <div class="px-5 pb-3">

							<div class="block md:flex justify-between items-center py-2 border-b border-gray-200">
							<div class="">
								Sector Alignment vs <span>{{ this.selectedIndex.name }}</span>
							</div>

								<div class="flex items-center text-xs">

									<div class="text-gray-600 pr-2">Index Alignment</div>
									<div class="w-2 h-2 rounded-full"
										 :style="{ backgroundColor: getValueColor(this.activeIndexAccuracy)}"></div>
									<div class="pl-1">
										{{ decimalPercentFormat(this.activeIndexAccuracy) }}
									</div>
									<svg class="ml-1 w-4 h-4 text-gray-400 hover:text-gray-600 transition ease-in-out duration-150 cursor-pointer" fill="currentColor" viewBox="0 0 20 20"
										 v-tooltip="{ html: 'sectorAlignmentTooltip', class: 'fade-in' }">
										xmlns="http://www.w3.org/2000/svg">
										<path fill-rule="evenodd"
											  d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-8-3a1 1 0 00-.867.5 1 1 0 11-1.731-1A3 3 0 0113 8a3.001 3.001 0 01-2 2.83V11a1 1 0 11-2 0v-1a1 1 0 011-1 1 1 0 100-2zm0 8a1 1 0 100-2 1 1 0 000 2z"
											  clip-rule="evenodd"></path>
									</svg>

									<div id="sectorAlignmentTooltip" class="text-xs text-gray-300">
										<b>Index Alignment</b> measures how close your portfolio positions align to the sector weighting of this index. <br>
										Relatively high or low percents are not necessarily bad, but can demonstrate over- or under-indexing against a benchmark.
									</div>


								</div>


							<!-- Market Index dropdown -->
							<div class="relative sm:inline-block text-left ">

								<button @click="indexDropdownOpen = !indexDropdownOpen" type="button"
										class="inline-flex justify-between w-full rounded-md border border-gray-300 px-4 py-2 bg-white text-sm leading-5 font-medium text-gray-700 hover:text-gray-500 focus:outline-none focus:border-blue-300 focus:ring-blue active:bg-gray-50 active:text-gray-800 transition ease-in-out duration-150">
									<span>{{ selectedIndex.symbol }}</span>
									<svg class="-mr-1 ml-2 h-5 w-5" fill="currentColor" viewBox="0 0 20 20">
										<path fill-rule="evenodd"
											  d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
											  clip-rule="evenodd"/>
									</svg>
								</button>

								<!--Full width button to close if you click outside-->
								<button v-if="indexDropdownOpen" @click="indexDropdownOpen = false"
										tabindex="-1" class="fixed inset-0 h-full w-full cursor-default"></button>

								<transition enter-active-class="transition ease-out duration-100"
											enter-class="transform opacity-0 scale-95"
											enter-to-class="transform opacity-100 scale-100"
											leave-active-class="transition ease-in duration-75"
											leave-class="transform opacity-100 scale-100"
											leave-to-class="transform opacity-0 scale-95">

									<div v-if="indexDropdownOpen"
										 class="origin-top-right absolute right-0 mt-2 w-56 rounded-md shadow-lg z-10">
										<div class="rounded-md bg-white ring-1 ring-black ring-opacity-5">
											<div class="py-1">

												<button v-if="!this.validUser"
													@click="openRegisterModal()"
													class="inline-flex items-center -mt-1 hover:text-black hover:shadow-inner transition duration-150 ease-in-out relative block px-4 py-2 text-xs leading-normal text-gray-700 border-b border-gray-400 text-left w-full bg-gradient-to-tr from-pink-200 hover:from-pink-300 to-white rounded-t-md hover:bg-gray-200 hover:text-gray-900 focus:outline-none"
												>

													<svg xmlns="http://www.w3.org/2000/svg" class="w-8 mr-2" viewBox="0 0 20 20" fill="currentColor">
														<path d="M8 9a3 3 0 100-6 3 3 0 000 6zM8 11a6 6 0 016 6H2a6 6 0 016-6zM16 7a1 1 0 10-2 0v1h-1a1 1 0 100 2h1v1a1 1 0 102 0v-1h1a1 1 0 100-2h-1V7z" />
													</svg>
													Create an account to compare against other indexes

												</button>
												<button v-for="(marketIndex,index) in marketIndexes" :key=index
														@click="changeIndex(index)"
														:disabled="!validUser"
														:class="[validUser ? 'cursor-pointer text-gray-700' : 'cursor-not-allowed text-gray-500']"
														class="inline-flex relative block px-4 py-2 text-sm leading-5  text-left w-full hover:bg-gray-100 hover:text-gray-900 focus:outline-none focus:bg-gray-100 focus:text-gray-900">


													{{marketIndex.name}}
													<span v-show="marketIndex.id === selectedIndex.id"
														  class="absolute inset-y-0 right-0 flex items-center pr-2">
														<svg class="h-5 w-5 text-gray-700" fill="none"
															 stroke-linecap="round" stroke-linejoin="round"
															 stroke-width="2" viewBox="0 0 24 24"
															 stroke="currentColor">
															<path d="M5 13l4 4L19 7"></path>
														</svg>
													</span>
												</button>

											</div>
										</div>
									</div>
								</transition>
							</div>
							</div>

							<div class="block md:flex mt-2">
								<div style="height: 270px; width: 325px;">
									<canvas id="sectorChart"></canvas>
								</div>
								<div class="md:ml-6 w-full">
									<table class="min-w-full divide-y divide-gray-200">
										<thead class="bg-gray-50">
											<tr>
												<th scope="col" class="px-1 py-1 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
													Sector
												</th>
												<th scope="col" class="px-1 py-1 text-right text-xs text-blue-600 uppercase tracking-wider">
													You
												</th>
												<th scope="col" class="px-1 py-1 text-right text-xs text-pink-700 uppercase tracking-wider">
													Index
												</th>
												<th scope="col" class="px-1 py-1 text-right text-xs font-medium text-gray-500 uppercase tracking-wider">
													Diff
												</th>
											</tr>
										</thead>
										<tbody class="bg-white divide-y divide-gray-200">
											<tr v-for="(values,sector) in activeIndex" :key=sector style="font-size: 70%" class="hover:bg-gray-200">
												<td class="px-2 whitespace-nowrap text-gray-600" style="padding-top: 2px; padding-bottom: 1px;">
													{{ sector }}
												</td>
												<td class="px-1 whitespace-nowrap text-gray-600 text-right proportional-nums" style="padding-top: 2px; padding-bottom: 2px;">
													{{ decimalPercentFormat(values.currentWeight) }}
												</td>
												<td class="px-1 whitespace-nowrap text-gray-600 text-right proportional-nums" style="padding-top: 2px; padding-bottom: 2px;">
													{{ decimalPercentFormat(values.sectorWeight) }}
												</td>
												<td class="px-1 whitespace-nowrap text-gray-600 text-right proportional-nums" :class="values.class" style="padding-top: 2px; padding-bottom: 2px;">
													{{ decimalPercentFormat(values.diff) }}
												</td>
											</tr>

										</tbody>
									</table>
								</div>
							</div>
							<div class="absolute -ml-4 -mt-3">
								<svg class="ml-1 w-4 h-4 text-gray-400 hover:text-gray-600 transition ease-in-out duration-150 cursor-pointer" fill="currentColor" viewBox="0 0 20 20"
									 v-tooltip="{ html: 'sectorAlignmentHelp', class: 'fade-in' }">
									xmlns="http://www.w3.org/2000/svg">
									<path fill-rule="evenodd"
										  d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-8-3a1 1 0 00-.867.5 1 1 0 11-1.731-1A3 3 0 0113 8a3.001 3.001 0 01-2 2.83V11a1 1 0 11-2 0v-1a1 1 0 011-1 1 1 0 100-2zm0 8a1 1 0 100-2 1 1 0 000 2z"
										  clip-rule="evenodd"></path>
								</svg>

								<div id="sectorAlignmentHelp" class="text-xs text-gray-300">
									<ul class="list-disc">
										<li>Plot uses logarithmic scale from center and compares <span class="text-blue-600 text-bold">your portfolio</span> versus the <span class="text-pink-600 text-bold">{{this.selectedIndex.name}}</span>.</li>
										<li>Sectors represent Global Industry Classification Standard (GICS).</li>
										<li>Portfolio sector weight calculated based on position values.</li>
										<li>ETFs and other funds are broken down into individual constituents.</li>
										<li>Index weights are based on relative market capitalization of constituents.</li>
									</ul>
								</div>

							</div>


                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { Chart, Scale, RadialLinearScale, registerables } from 'chart.js';
import {displayHelpers} from "../../mixins/displayHelpers";
import {isNullOrUndef} from "chart.js/helpers";

export class LogScale extends RadialLinearScale {

	getDistanceFromCenterForValue(value) {
		if (isNullOrUndef(value)) {
			return NaN;
		}

		// Take into account half font size + the yPadding of the top value
		const scalingFactor = this.drawingArea / (this.max - this.min);
		if (this.options.reverse) {
			return (this.max - value) * scalingFactor;
		}
		if(value < 0.01) {
			return 2;
		}

		let maxVal = (this.max - this.min) * scalingFactor;
		let pointVal = (value - this.min) * scalingFactor;

		// console.log(maxVal * this.getCustomLog(pointVal,maxVal));
		return maxVal * this.getCustomLog(pointVal,maxVal);
	}

	getCustomLog(val,max) {
		return Math.log(val) / Math.log(max);
	}

}
LogScale.id = 'logScale';
// LogScale.defaults = defaultConfigObject;


    export default {
        name: 'SectorWeightCard',
		mixins: [displayHelpers],
        data() {
            return {
            	validUser: false,
            	radarChart: null,
				marketIndexes:
				[
					{"id": 1, "name": "Standard and Poor's 500", "symbol": "SPX"},
					{"id": 2, "name": "Dow Jones Industrial Avg", "symbol": "DJI"},
					{"id": 3, "name": "Russell 2000", "symbol": "RUT"},
					{"id": 4, "name": "Nasdaq-100", "symbol": "NDX"},
					{"id": 5, "name": "Nikkei 225", "symbol": "NI225"},
					// {"id": 4, "name": "Nasdaq Composite", "symbol": "IXIC"},
				],
				selectedIndex: {"id": 1, "name": "Standard and Poor's 500", "symbol": "SPX"},
				indexDropdownOpen: false,
                chartData: {
					labels: [],
					// labels: ['Communication Services','Consumer Discretionary','Consumer Staples','Energy','Financials','Health Care','Industrials','Materials','Real Estate','Technology','Utilities'],
					datasets: [
						{
							label: 'You',
							data: [],
							borderColor: 'rgba(44, 82, 130, 1)',
							backgroundColor: 'rgba(44, 82, 130, 0.4)',
							borderWidth: 1
						},
						{
							label: 'Index',
							data: [],
							borderColor: 'rgba(151,38,109,1)',
							// backgroundColor: 'rgba(113, 128, 150, 0.4)', // gray
							backgroundColor: 'rgba(151,38,109,0.4)',
							borderWidth: 1
						}
					]
				},
            }
        },
        props: {
			weights: null,
			accuracy: 0,
			user_data: null,
        },
		created() {
			Chart.register(...registerables);
			Chart.register(LogScale);

			this.validUser = !!this.user_data.email;

		},
        mounted() {
			let ctx = document.getElementById("sectorChart");

			this.buildChartData();




			this.radarChart = new Chart(ctx, {
				type: 'radar',
				data: this.chartData,
				options: {
					parsing: {
						xAxisKey: this.activeIndex,
						yAxisKey: 'sectorWeight'
					},
					scales: {
						x: {
							display: false,
						},
						y: {
							display: false
						},
						r: {
							pointLabels: {
								font: {
									// size: 10
								},
								callback: function(label) {
									if (/\s/.test(label) && label.length > 12) {
										return label.split(" ");
									}else{
										return label;
									}
								}
							},
							type:'logScale',
							beginAtZero: true,
							// min: 0.01,
							// max: 0.2,
							ticks: {
								display: false,
								stepSize: .1,
							},
							grid: {
								width: 5,
							}
							// display: false
						}
					},
					maintainAspectRatio: false,
					responsive: true,
					plugins: {
						legend: {
							display: false,
							position: 'bottom',
							labels: {
								boxWidth: 10,
								boxHeight: 10,
								font: {
									size: 10
								}
							}
						},
						tooltip: {
							callbacks: {
								label: function(context) {
									const dataLabel = context.dataset.label;
									return dataLabel + " - " + parseFloat((context.raw * 100).toFixed(1)) + '%';
								}
							}
						}
					},

				}
			})
        },
        computed: {
			activeIndex() {

				return this.weights[this.selectedIndex.symbol];
			},
			activeIndexName() {
				return this.selectedIndex.name;
			},
			activeIndexAccuracy() {
				return this.accuracy[this.selectedIndex.symbol]['accuracyToIndex'];
			}
        },
        methods: {
			openRegisterModal() {
				this.indexDropdownOpen = false;
				this.$root.$emit('openRegisterModal');
			},
			changeIndex(index) {
				this.selectedIndex = this.marketIndexes[index];
				// change the data on the chart??
				this.buildChartData();

				this.radarChart.update();
				this.indexDropdownOpen = false;
			},
			buildChartData() {
				const tempLabels = [];
				const tempIndexWeights = [];
				const tempYourWeights = [];
				Object.entries(this.activeIndex).map(function(sector) {

					tempLabels.push(sector[0])
					tempIndexWeights.push(sector[1]['sectorWeight']);
					tempYourWeights.push(sector[1]['currentWeight']);
				});

				this.chartData['labels'] = tempLabels;
				this.chartData['datasets'][0]['data'] = tempYourWeights;
				this.chartData['datasets'][1]['data'] = tempIndexWeights;


				this.chartData['datasets'][1]['label'] = this.selectedIndex.name;
			},
			getValueColor(value) {
				if(value >= 0.95) { return '#2ecc71'; } else
				if(value >= 0.9) { return '#90C840'; } else
				if(value >= 0.8) { return '#f1c40f'; } else
				if(value >= 0.75) { return '#EC8826'; } else
				{ return '#e74c3c'; }
			},
			// buildChartData() {
			// 	this.chartData = Object.entries(this.activeIndex).map((sector) => {
			// 		let [label, data] = sector
			// 		let { currentWeight, sectorWeight } = data
			//
			// 		return {
			// 			'labels': label,
			// 			'datasets': [
			// 				{ data: currentWeight },
			// 				{ data: sectorWeight }
			// 			]
			// 		}
			// 	});
			//
			// 	this.chartData['datasets'][1]['label'] = this.selectedIndex.name;
			// }

        }
    }
</script>

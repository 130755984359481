<template>
  <div>

	  <!-- auto pilot options modal -->
	  <transition enter-active-class="ease-out duration-200" enter-class="opacity-0" enter-to-class="opacity-100"
				  leave-active-class="ease-in duration-100" leave-class="opacity-100" leave-to-class="opacity-0">
		  <div v-if="autopilotOptionsModalOpen">
			  <auto-pilot-options-modal :open=autopilotOptionsModalOpen :settingsProp="primaryScenario"
										:subscribed="this.subscribed"
									  @close="autopilotOptionsModalOpen = false"></auto-pilot-options-modal>
		  </div>
	  </transition>

    <!--Black Background for when processing-->
    <div v-if="processing"
         class="fixed z-20 bottom-0 inset-x-0 px-4 pb-4 sm:inset-0 sm:flex sm:items-center sm:justify-center">
      <div class="fixed inset-0 transition-opacity">
        <div class="absolute inset-0 bg-gray-900 opacity-75"></div>
      </div>
      <!-- Processing dots/ feedback when you hit save -->
      <div class="dots absolute z-30">
        <div></div>
        <div></div>
        <div></div>
      </div>
    </div>

    <div class="mb-5">
      <div class="-my-2 py-2 sm:-mx-6 sm:px-6 lg:-mx-8 lg:px-8">
        <div class="align-middle inline-block min-w-full shadow rounded-lg bg-gray-800">
          <div class="">

            <div class="sm:flex justify-between pl-5 pr-3 py-3">


              <div class="flex items-center">
                <!-- Radar Icon --->
                <svg class="w-8 fill-current text-gray-400"
					 :class="[this.primaryScenario.autopilot_active ? 'animate-pulse' : '']"
					 xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                  <path d="M231 22c-19 2-44 8-62 15A228 228 0 0044 152a235 235 0 10446 119v-12l-1 8c-3 16-5 26-10 40-11 33-27 60-51 85-9 10-33 31-47 41a221 221 0 01-156 38 217 217 0 11200-349l-16 12-16 11-3-4A174 174 0 0083 257c1 38 12 69 34 99a176 176 0 00161 68 175 175 0 00150-149v-6l-3 10c-17 44-48 81-90 106a155 155 0 1142-229l-20 14-19 14-7-7a105 105 0 1022 119c1-4 1-4-7 4-21 20-52 34-80 38a87 87 0 1151-148l5 5-3 2-20 14-17 13-5-3c-18-12-43 0-47 21-3 19 11 36 31 36 6 0 8 0 13-3 11-5 18-16 18-27v-4l20-9a1633 1633 0 0138-19l36-17a3274 3274 0 00101-48l-29-50c-1-1-3 0-9 5l-8 5-4-4-13-14a233 233 0 00-193-71z"/>
                </svg>

                <div class="flex pl-3">
					<span class="text-gray-200 pr-2 font-normal self-center">Autopilot</span>

					<!-- Lock icon -->
					<svg v-if="!this.subscribed" class="w-6 h-6 pr-2 text-gray-100" fill="none" stroke="currentColor" viewBox="0 0 24 24"
						 xmlns="http://www.w3.org/2000/svg">
						<path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
							  d="M12 15v2m-6 4h12a2 2 0 002-2v-6a2 2 0 00-2-2H6a2 2 0 00-2 2v6a2 2 0 002 2zm10-10V7a4 4 0 00-8 0v4h8z"></path>
					</svg>

					<!-- Active/ Inactive tile-->
                  <div style="font-size: 60%;"
					   class="px-2 self-center text-xs leading-4 font-semibold rounded-full"
					   :class="[this.primaryScenario.autopilot_active ? 'bg-green-100 text-green-800' : 'bg-red-100 text-red-800']"
				  >
					  {{ primaryScenario.autopilot_active ? 'ACTIVE' : 'INACTIVE' }}
                  </div>
                </div>
              </div>

				<div class="flex">

					<div class="flex items-center">
						<div class="pr-3 whitespace-nowrap text-xs leading-5 text-gray-400">
							{{ this.optionsString }}
						</div>
					</div>

					<!-- show this second button on DESKTOP -->
					<span class="hidden sm:block">
						<button @click="autopilotOptionsModalOpen = !autopilotOptionsModalOpen" type="button"
								class="inline-flex justify-center w-full rounded-md border px-2 py-2 leading-6 font-medium shadow-sm focus:outline-none focus:border-blue-300 focus:ring-blue transition ease-in-out duration-150 text-xs sm:leading-5 text-gray-500 border-gray-600 hover:border-gray-400 hover:text-gray-400">
							<svg class="w-4 h-4 self-center" fill="none" stroke="currentColor" viewBox="0 0 24 24"
								 xmlns="http://www.w3.org/2000/svg">
								<path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
									  d="M12 6V4m0 2a2 2 0 100 4m0-4a2 2 0 110 4m-6 8a2 2 0 100-4m0 4a2 2 0 110-4m0 4v2m0-6V4m6 6v10m6-2a2 2 0 100-4m0 4a2 2 0 110-4m0 4v2m0-6V4"></path>
							</svg>
						</button>
					</span>

				</div>

				<span class="sm:hidden">
					<!-- MOBILE -->
					<button @click="autopilotOptionsModalOpen = !autopilotOptionsModalOpen" type="button"
							class="inline-flex justify-center w-full cursor-pointer rounded-md border border-gray-600 p-2 mt-2 text-gray-500 shadow-sm hover:text-blue-300 focus:outline-none focus:border-blue-300 focus:ring-blue transition ease-in-out duration-150 text-xs sm:leading-5">
						<svg
							class="w-4 h-4 self-center"
							fill="none" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
							viewBox="0 0 24 24" stroke="currentColor">
							<path
								d="M12 6V4m0 2a2 2 0 100 4m0-4a2 2 0 110 4m-6 8a2 2 0 100-4m0 4a2 2 0 110-4m0 4v2m0-6V4m6 6v10m6-2a2 2 0 100-4m0 4a2 2 0 110-4m0 4v2m0-6V4"></path>
						</svg>
						<span class="sm:hidden text-xs text-center leading-3 self-center mx-2">Options</span>
					</button>
				</span>

            </div>
          </div>
        </div>
      </div>
    </div>

  </div>

</template>

<script>
export default {
  name: 'AutopilotCard',
	data() {
  	return {
    	processing: false,
		autopilotOptionsModalOpen: false,
    }
  },
  props: {
	  primaryScenario: null,
	  user_data: null,
	  subscribed: {default: false},
  },
  computed: {
  		optionsString() {
  			if (!this.primaryScenario.autopilot_options)
  			{ return "Not yet configured" } else
			{
				const frequency = this.primaryScenario.autopilot_options.frequency ?? null;
				const dow = this.primaryScenario.autopilot_options.dow ?? null;
				const notice = this.primaryScenario.autopilot_options.notice ?? null;
				const confirm = this.primaryScenario.autopilot_options.confirm ?? null;

				return frequency + " | " + dow + " | " + (Number.isInteger(notice) && notice > 0 ? notice + "D Notice" : 'No Notice') + " | " + (confirm ? "Confirm" : "No Confirm");
			}
		}
  },
  methods: {
    //
  },

}
</script>

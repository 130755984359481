<template>
<div>

    <!--Black Background for when processing-->
    <div v-if="processingSync" class="fixed z-20 bottom-0 inset-x-0 px-4 pb-4 sm:inset-0 sm:flex sm:items-center sm:justify-center">
      <div class="fixed inset-0 transition-opacity">
        <div class="absolute inset-0 bg-gray-900 opacity-75"></div>
      </div>
      <!-- Processing dots/ feedback when you hit save -->
      <div class="dots absolute z-30">
        <div></div>
        <div></div>
        <div></div>
      </div>
    </div>

    <!-- Help text at top -->
    <div>
        <div class="mb-5">
            <div class="align-middle inline-block min-w-full shadow rounded-lg bg-teal-600">
                <div class="flex p-4 text-white">
                    <div class="w-8 self-center">
                        <svg class="w-6 text-white" fill="none" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" viewBox="0 0 24 24"
                             stroke="currentColor">
                            <path
                                d="M5 3v4M3 5h4M6 17v4m-2-2h4m5-16l2.286 6.857L21 12l-5.714 2.143L13 21l-2.286-6.857L5 12l5.714-2.143L13 3z"></path>
                        </svg>
                    </div>
                    <span class="pl-2 text-sm leading-tight">
                        Your TD Ameritrade account(s) are shown below. Select "Sync Account" to add it as a scenario. You will need to configure the target allocations for each position after syncing.
                    </span>
                </div>
            </div>
        </div>
    </div>


    <!-- Repeat for each account from accounts-->
    <div v-for="(account, index) in accounts" :key="index" class="flex flex-col">
        <div class="-my-2 py-2 mb-6 sm:-mx-6 sm:px-6 lg:-mx-8 lg:px-8">
            <div class="align-middle inline-block min-w-full shadow overflow-hidden sm:rounded-lg">
                <div class="border-b">
                    <div class="flex justify-between px-5 py-3 bg-white">

                        <div class="flex items-center">
                            <!-- TDA Logo --->
                            <svg class="w-8" viewBox="0 0 400 357.728">
                                <path d="M0 357.728h400V0H0v357.728z" fill="#54b948"/>
                                <g clip-path="url(#a)" transform="matrix(6.6246 0 0 -6.6246 -7574.363 596.214)">
                                    <path
                                        d="M1183.01 47.682h-14.064v25.336h7.064V52.17h6.794c4.69 0 6.66 3.249 6.66 11.616 0 8.417-2.238 10.863-7 10.863h-15.494V47.682h-6.995v26.967h-10.265v4.498h34.934c8.426 0 12.429-4.36 12.429-15.294 0-14.405-5.977-16.171-14.063-16.171"
                                        fill="#fff"/>
                                </g>
                            </svg>
                            <div class="block pl-3 text-xs">
                                <div class="text-gray-600">Account #</div>
                                <div class="">{{account.tda_account_id}}</div>
                            </div>
                            <div class="block pl-10 text-xs">
                                <div class="text-gray-600">Type</div>
                                <div class="">{{account.type}}</div>
                            </div>
                            <div class="block pl-10 text-xs">
                                <div class="text-gray-600">Value</div>
                                <div class="">{{moneyFormat(account.portfolio_value)}}</div>
                            </div>
                        </div>

                        <div v-if="account.matched_scenario"
                            class="text-xs text-gray-500 font-italic text-center self-center">
                            Account already linked!
                        </div>

                        <div class="flex items-center">

                            <span v-if="!account.matched_scenario" class="py-1">
                                <button @click="syncScenario(account.tda_account_id)"
                                    type="button"
                                    class="inline-flex justify-center w-full rounded-md border border-gray-300 px-4 py-2 bg-white leading-6 font-medium text-gray-700 shadow-sm hover:text-gray-500 focus:outline-none focus:border-blue-300 focus:ring-blue transition ease-in-out duration-150 text-xs sm:leading-5">
                                    Sync Account
                                    <svg class="w-4 h-4 ml-2 self-center" fill="none" stroke-linecap="round"
                                         stroke-linejoin="round" stroke-width="2" viewBox="0 0 24 24"
                                         stroke="currentColor">
                                        <path d="M12 4v16m8-8H4"></path>
                                    </svg>
                                </button>
                            </span>

                            <span v-if="account.matched_scenario" class="py-1">

                                <button @click="changeScenario(account.matched_scenario_id)"
                                    type="button"
                                    class="inline-flex justify-center w-full rounded-md border border-gray-300 px-4 py-2 bg-white leading-6 font-medium text-gray-700 shadow-sm hover:text-gray-500 focus:outline-none focus:border-blue-300 focus:ring-blue transition ease-in-out duration-150 text-xs sm:leading-5">
                                    Go to Scenario
                                    <svg class="w-4 h-4 ml-3 self-center" fill="none" stroke-linecap="round"
                                         stroke-linejoin="round" stroke-width="2" viewBox="0 0 24 24"
                                         stroke="currentColor">
                                        <path d="M9 5l7 7-7 7"></path>
                                    </svg>
                                </button>
                            </span>

                        </div>
                    </div>
                    <table class="min-w-full">
                        <thead>
                            <tr>
                                <th class="pl-5 pr-3 py-3 border-b border-t border-gray-200 bg-gray-100 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
                                    Symbol
                                </th>
                                <th class="px-3 py-3 border-b border-t border-gray-200 bg-gray-100 text-xs text-left leading-4 font-medium text-gray-500 uppercase tracking-wider">
                                    Shares
                                </th>
                                <th class="px-3 py-3 border-b border-t border-gray-200 bg-gray-100 text-xs text-right leading-4 font-medium text-gray-500 uppercase tracking-wider">
                                    Last Price
                                </th>
                                <th class="px-3 py-3 border-b border-t border-gray-200 bg-gray-100 text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider text-right">
                                    Market Value
                                </th>
                                <th class="pl-3 pr-5 py-3 border-b border-t border-gray-200 bg-gray-100 text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider text-right">
                                    Current %
                                </th>
                            </tr>
                        </thead>
                        <tbody class="bg-white">


                            <tr v-for="(position,index) in account.positions" :key=index>
                                <td class="pl-5 pr-3 py-1 whitespace-nowrap border-b border-gray-200">
                                    <div class="text-sm leading-5 text-gray-900">{{position.symbol}} {{position.symbol === 'USD' ? '(Cash)' : ''}}</div>
                                </td>
                                <td class="px-3 whitespace-nowrap border-b border-gray-200">
                                    <div class="text-sm leading-5 text-gray-500">{{position.shares}}</div>
                                </td>
                                <td class="px-3 whitespace-nowrap border-b border-gray-200">
                                    <div class="text-sm leading-5 text-gray-500 text-right">{{moneyFormat(position.share_price)}}</div>
                                </td>
                                <td class="px-3 whitespace-nowrap border-b border-gray-200 text-sm leading-5 text-gray-900 text-right">
                                    {{moneyFormat(position.market_value)}}
                                </td>
                                <td class="pl-3 pr-5 whitespace-nowrap text-right border-b border-gray-200 text-sm leading-5 font-medium">
                                   {{decimalPercentFormat(position.current_percent)}}
                                </td>
                            </tr>




                        </tbody>
                    </table>
                </div>
            </div>
        </div>

    </div>
</div>
</template>

<script>
    export default {
        name: "accountCard",
        data() {
            return {
                processingSync: false
            }
        },
        props: {
            accounts: null,
        },
        methods: {
            syncScenario(tda_account_id) {
                this.processingSync = true;
                axios.post('/tda-confirm', {
                    // pass the scenario ID of the item selected from the list based on its index
                    accountID: tda_account_id,
                })
                    .then(response => {
                        window.location.href = '/edit'
                        console.log(response);
                        // this.$emit('save');
                    })
                    .catch(error => {
                        this.errors = error.response.data.errors
                        // window.location.href = '/dashboard'
                        // this.$emit('stopprocessing');
                    });
            },
            changeScenario(scenarioID) {
              this.processingSync = true;
              axios.post('/changeScenario', {
                  // pass the scenario ID of the scenario selected
                  change: scenarioID,
                })
                    .then(response => {
                      window.location.href = '/dashboard';
                    })
                    .catch(error => {
                      window.location.href = '/dashboard';
                    });
            },

            moneyFormat(value) {
                const formatter = new Intl.NumberFormat('en-US', {
                    style: 'currency',
                    currency: 'USD',
                });
                return formatter.format(value);
            },
            decimalPercentFormat(value) {
                return parseFloat((value * 100).toFixed(1)) + '%';
            }
        },
        computed: {
         //
        },
    }
</script>

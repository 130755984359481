<template>
    <transition
                leave-active-class="transition ease-out -translate-y-1/2 duration-1000"
                leave-class="transform opacity-100 scale-100"
                leave-to-class="transform opacity-0 scale-95">

        <div v-if="visible" :class="cardColors('background')"
             class="inline-flex items-center p-3 rounded-md mb-5 text-xs w-full">

            <!-- Green check icon-->
            <div v-if="this.data.type === 'success'" class="mr-2 text-green-500">
                <svg class="w-5" fill="none" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" viewBox="0 0 24 24"
                     stroke="currentColor">
                    <path d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z"></path>
                </svg>
            </div>

            <!-- Info icon-->
            <div v-if="this.data.type === 'info'" class="mr-2 text-blue-500">
                <svg class="w-5" fill="none" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" viewBox="0 0 24 24"
                     stroke="currentColor">
                    <path d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"></path>
                </svg>
            </div>

            <!-- Warning icon-->
            <div v-if="this.data.type === 'warning'" class="mr-2 text-red-500">
                <svg class="w-5" fill="none" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" viewBox="0 0 24 24"
                     stroke="currentColor">
                    <path
                        d="M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z"></path>
                </svg>
            </div>

            <div class="flex-grow">
                {{this.data.message}}
            </div>

            <!--Pie timer-->
            <div class="wrapper relative w-5 h-5 ml-4" :class="cardColors('background')">
                <div class="pie spinner" :class="cardColors('timer')"></div>
                <div class="pie filler" :class="cardColors('timer')"></div>
                <div class="mask"></div>
            </div>

        </div>
    </transition>

</template>

<script>
    export default {
        name: 'notificationCard',
        data() {
            return {
                visible: true,
            }
        },
        props: {
            data: null,
        },
        computed: {
            ///
        },
        methods: {
            cardColors(usage) {
                switch (this.data.type) {
                    case "info":
                        if(usage === 'background') {return 'bg-blue-200 text-blue-700'}
                        if(usage === 'timer') {return 'bg-blue-300'}
                        if(usage === 'icon') {return 'text-blue-500'}
                        break;
                    case "success":
                        if(usage === 'background') {return 'bg-green-200 text-green-700'}
                        if(usage === 'timer') {return 'bg-green-300'}
                        if(usage === 'icon') {return 'text-green-500'}
                        break;
                    case "warning":
                        if(usage === 'background') {return 'bg-red-200 text-red-700'}
                        if(usage === 'timer') {return 'bg-red-300'}
                        if(usage === 'icon') {return 'text-red-500'}
                        break;
                }
            }
        },
        created() {
            setTimeout(() => this.visible = false, 11000);
        },
    }
</script>

<style scoped>

    .wrapper, .wrapper * {
        -moz-box-sizing: border-box;
        -webkit-box-sizing: border-box;
        box-sizing: border-box;
    }

    .wrapper .pie {
        width: 50%;
        height: 100%;
        transform-origin: 100% 50%;
        position: absolute;
    }

    .wrapper .spinner {
        border-radius: 100% 0 0 100% / 50% 0 0 50%;
        z-index: 200;
        border-right: none;
        animation: rota 10s linear forwards;
    }

    .wrapper:hover .spinner,
    .wrapper:hover .filler,
    .wrapper:hover .mask {
        animation-play-state: running;
    }

    .wrapper .filler {
        border-radius: 0 100% 100% 0 / 0 50% 50% 0;
        left: 50%;
        opacity: 0;
        z-index: 100;
        animation: opa 10s steps(1, end) forwards reverse;
        border-left: none;
    }

    .wrapper .mask {
        width: 50%;
        height: 100%;
        position: absolute;
        background: inherit;
        opacity: 1;
        z-index: 300;
        animation: opa 10s steps(1, end) forwards;
    }

    @keyframes rota {
        0% {
            transform: rotate(0deg);
        }
        100% {
            transform: rotate(360deg);
        }
    }
    @keyframes opa {
        0% {
            opacity: 1;
        }
        50%, 100% {
            opacity: 0;
        }
    }
</style>

<template>
    <div>

        <div class="mb-8">
            <div class="-my-2 py-2 sm:-mx-6 sm:px-6 lg:-mx-8 lg:px-8">
                <div class="align-middle inline-block min-w-full shadow rounded-lg bg-white">
                    <div>
                        <div class="px-5 pb-3">

							<div class="block md:flex justify-between items-center py-2 border-b border-gray-200">

								<div class="flex">

									<div class="">
									ETF Comparison
									</div>
									<div class="flex items-center ml-2">
									<p class="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-gray-300 text-gray-500">
										BETA
									</p>
									</div>
								</div>

								<div class="flex items-center text-xs">

									<div class="text-gray-600 pr-2">Avg Funds Overlap</div>
									<div class="w-2 h-2 rounded-full"
										 :style="{ backgroundColor: getRatioColor(this.overlapTypeAvg)}"></div>
									<div class="pl-1">
										{{ decimalPercentFormat(this.overlapTypeAvg) }}
									</div>
									<svg class="ml-1 w-4 h-4 text-gray-400 hover:text-gray-600 transition ease-in-out duration-150 cursor-pointer" fill="currentColor" viewBox="0 0 20 20"
										 v-tooltip="{ html: 'etfCompareTooltop', class: 'fade-in' }">
										xmlns="http://www.w3.org/2000/svg">
										<path fill-rule="evenodd"
											  d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-8-3a1 1 0 00-.867.5 1 1 0 11-1.731-1A3 3 0 0113 8a3.001 3.001 0 01-2 2.83V11a1 1 0 11-2 0v-1a1 1 0 011-1 1 1 0 100-2zm0 8a1 1 0 100-2 1 1 0 000 2z"
											  clip-rule="evenodd"></path>
									</svg>

									<div id="etfCompareTooltop" class="text-xs text-gray-300">
										<b>ETF Comparison</b> allows you to measure how similar ETFs in your portfolio are to each other based on holdings. <br>
										High similarity suggests ETFs are highly correlated and may not function as diversification versus other holdings.
									</div>


								</div>

								<!-- Toggle button -->
								<!--								<button @click="countMode = !countMode" v-model="countMode" :class="[countMode ? 'bg-indigo-600' : 'bg-gray-200', 'relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500']">-->
								<!--									<span class="sr-only">Use setting</span>-->
								<!--									<span aria-hidden="true" :class="[countMode ? 'translate-x-5' : 'translate-x-0', 'pointer-events-none inline-block h-5 w-5 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200']" />-->
								<!--								</button>-->

							<!-- Overlap By Selection dropdown -->

								<div class="flex items-center">
									<div class="text-xs text-gray-600 mr-2">
										Overlap by
									</div>

									<div class="relative sm:inline-block text-left ">

										<button @click="OverlapTypeDropdownOpen = !OverlapTypeDropdownOpen" type="button"
												class="inline-flex justify-between w-full rounded-md border border-gray-300 px-4 py-2 bg-white text-sm leading-5 font-medium text-gray-700 hover:text-gray-500 focus:outline-none focus:border-blue-300 focus:ring-blue active:bg-gray-50 active:text-gray-800 transition ease-in-out duration-150">
											<span>{{ selectedOverlapType.name }}</span>
											<svg class="-mr-1 ml-2 h-5 w-5" fill="currentColor" viewBox="0 0 20 20">
												<path fill-rule="evenodd"
													  d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
													  clip-rule="evenodd"/>
											</svg>
										</button>

										<!--Full width button to close if you click outside-->
										<button v-if="OverlapTypeDropdownOpen" @click="OverlapTypeDropdownOpen = false"
												tabindex="-1"
												class="fixed inset-0 h-full w-full cursor-default"></button>

										<transition enter-active-class="transition ease-out duration-100"
													enter-class="transform opacity-0 scale-95"
													enter-to-class="transform opacity-100 scale-100"
													leave-active-class="transition ease-in duration-75"
													leave-class="transform opacity-100 scale-100"
													leave-to-class="transform opacity-0 scale-95">

											<div v-if="OverlapTypeDropdownOpen"
												 class="origin-top-right absolute right-0 mt-2 w-48 rounded-md shadow-lg z-10">
												<div class="rounded-md bg-white ring-1 ring-black ring-opacity-5">
													<div class="py-1">

														<button v-if="!this.validUser" @click="openRegisterModal()"
																class="inline-flex items-center -mt-1 hover:text-black hover:shadow-inner transition duration-150 ease-in-out relative block px-4 py-2 text-xs leading-normal text-gray-700 border-b border-gray-400 text-left w-full bg-gradient-to-tr from-pink-200 hover:from-pink-300 to-white rounded-t-md hover:bg-gray-200 hover:text-gray-900 focus:outline-none">

															<svg xmlns="http://www.w3.org/2000/svg" class="w-8 mr-2"
																 viewBox="0 0 20 20" fill="currentColor">
																<path
																	d="M8 9a3 3 0 100-6 3 3 0 000 6zM8 11a6 6 0 016 6H2a6 6 0 016-6zM16 7a1 1 0 10-2 0v1h-1a1 1 0 100 2h1v1a1 1 0 102 0v-1h1a1 1 0 100-2h-1V7z"/>
															</svg>
															Create an account to view overlap by weight

														</button>
														<button v-for="(overlapType,index) in overlapTypes" :key=index
																@click="changeOverlapType(index)" :disabled="!validUser"
																:class="[validUser ? 'cursor-pointer text-gray-700' : 'cursor-not-allowed text-gray-500']"
																class="inline-flex relative block px-4 py-2 text-sm leading-5  text-left w-full hover:bg-gray-100 hover:text-gray-900 focus:outline-none focus:bg-gray-100 focus:text-gray-900">

															{{ overlapType.formalName }}
															<span v-show="overlapType.id === selectedOverlapType.id"
																  class="absolute inset-y-0 right-0 flex items-center pr-2">
																<svg class="h-5 w-5 text-gray-700" fill="none"
																	 stroke-linecap="round" stroke-linejoin="round"
																	 stroke-width="2" viewBox="0 0 24 24"
																	 stroke="currentColor">
																	<path d="M5 13l4 4L19 7"></path>
																</svg>
															</span>
														</button>

													</div>
												</div>
											</div>
										</transition>
									</div>
								</div>
							</div>

							<div class="grid grid-cols-5 mt-2">
								<div class="col-span-5 md:col-span-2 w-full" >
									<canvas id="etfCompareMatrix"></canvas>
								</div>
								<div class="col-span-5 md:col-span-3 w-full pl-2 pt-1">
									<table class="min-w-full divide-y divide-gray-200">
										<thead class="bg-gray-50">
											<tr>
												<th scope="col" colspan="5" class="px-1 pt-1 text-left text-xs font-medium text-gray-600 uppercase tracking-wider">
													Largest Overlapping Pairs
												</th>
											</tr>
											<tr>
												<th scope="col" class="px-1 py-1 text-left text-xs font-bold text-gray-500 uppercase tracking-wider">
													ETF 1
												</th>
												<th scope="col" class="px-1 py-1 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
													Funds
												</th>
												<th></th>
												<th scope="col" class="px-1 py-1 text-left text-xs font-bold text-gray-500 uppercase tracking-wider">
													ETF 2
												</th>
												<th scope="col" class="px-1 py-1 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
													Funds
												</th>
												<th scope="col" class="px-1 py-1 text-right text-xs font-medium text-gray-600 uppercase tracking-wider">
													OVERLAP
												</th>
											</tr>
										</thead>
										<tbody class="bg-white divide-y divide-gray-200">
											<tr v-for="(record,index) in topEtfOverlaps" :key=index style="font-size: 70%" class="hover:bg-gray-200">
												<td class="px-2 whitespace-nowrap text-gray-600" style="padding-top: 2px; padding-bottom: 1px;">
													{{ topEtfOverlaps[Math.max(0,index-1)].x === record.x ? (index === 0 ? record.x : '') : record.x }}
												</td>
												<td class="px-2 whitespace-nowrap text-gray-600" style="padding-top: 2px; padding-bottom: 1px;">
													{{ topEtfOverlaps[Math.max(0,index-1)].x === record.x ? (index === 0 ? record.etf1Count : '') : record.etf1Count }}
												</td>
												<td class="pr-2 text-gray-500">
													vs
												</td>
												<td class="px-1 whitespace-nowrap text-gray-600 text-left proportional-nums" style="padding-top: 2px; padding-bottom: 2px;">
													{{ record.y }}
												</td>
												<td class="px-1 whitespace-nowrap text-gray-600 text-left proportional-nums" style="padding-top: 2px; padding-bottom: 2px;">
													{{ record.etf2Count }}
												</td>
												<td class="px-1 whitespace-nowrap text-gray-600 text-right proportional-nums" style="padding-top: 2px; padding-bottom: 2px;"
												:style="{background: getValueColor((selectedOverlapType.slug === 'v' ? record.v : record.weightSum),record.x,record.y)}">
													{{ selectedOverlapType.slug === 'v' ? decimalPercentFormat(record.v) : decimalPercentFormat(record.weightSum)}}
<!--													{{ decimalPercentFormat(record.v) }}-->
												</td>
<!--												<td class="px-1 whitespace-nowrap text-gray-600 text-right proportional-nums" style="padding-top: 2px; padding-bottom: 2px;">-->
<!--													{{ decimalPercentFormat(record.weightSum) }}-->
<!--												</td>-->
											</tr>

										</tbody>
									</table>
<!--									<div v-show="countMode" class="h-full">-->
<!--										<canvas id="etfCompareEuler"></canvas>-->
<!--									</div>-->
								</div>
							</div>
							<div class="absolute -ml-4 -mt-3">
								<svg class="ml-1 w-4 h-4 text-gray-400 hover:text-gray-600 transition ease-in-out duration-150 cursor-pointer" fill="currentColor" viewBox="0 0 20 20"
									 v-tooltip="{ html: 'etfCompareHelp', class: 'fade-in' }">
									xmlns="http://www.w3.org/2000/svg">
									<path fill-rule="evenodd"
										  d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-8-3a1 1 0 00-.867.5 1 1 0 11-1.731-1A3 3 0 0113 8a3.001 3.001 0 01-2 2.83V11a1 1 0 11-2 0v-1a1 1 0 011-1 1 1 0 100-2zm0 8a1 1 0 100-2 1 1 0 000 2z"
										  clip-rule="evenodd"></path>
								</svg>

								<div id="etfCompareHelp" class="text-xs text-gray-300">
									Heat map displays the overlap of funds between two ETFs, showing the <br>
									combinations of all of the ETFs in your portfolio. Only publicly traded <br>
									securities are included in an ETFs fund count. ETFs are compared in both <br>
									directions and will often differ due to a difference in fund count between ETFs.<br>
									<br>
									<span class="mx-2">Overlap Percentage:</span>
									<br>
									<div class="flex justify-around mt-2">
										<p>0%</p>
										<p>10%</p>
										<p>20%</p>
										<p>30%</p>
										<p>40%+</p>
									</div>
									<div class="flex items-center"><span class="h-2 rounded-full mx-2 w-full"
									style="background: linear-gradient(90deg, rgba(46,204,113,1) 0%, rgba(144,200,64,1) 20%, rgba(241,196,15,1) 40%, rgba(236,136,38,1) 60%, rgba(231,76,60,1) 80%);">

									</span></div>
								</div>

							</div>


                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import {Chart, registerables} from 'chart.js';
import {displayHelpers} from "../../mixins/displayHelpers";
// import { getRelativePosition } from "chart.js/helpers";
import {MatrixController, MatrixElement} from 'chartjs-chart-matrix';
// import { EulerDiagramChart, EulerDiagramController } from 'chartjs-chart-venn';


export default {
        name: 'EtfCompareCard',
		mixins: [displayHelpers],
        data(vm) {
            return {
				countMode: false,
            	validUser: false,
            	matrixChart: null,
				eulerChart: null,
				overlapTypes:
				[
					{"id": 1, "formalName": "Fund Count", "name": "Count", "slug": "v"},
					{"id": 2, "formalName": "Fund Weight", "name": "Weight", "slug": "weightSum"},
				],
				selectedOverlapType: {"id": 1,"formalName": "Fund Count", "name": "Count", "slug": "v"},
				OverlapTypeDropdownOpen: false,
				testData: {},
                chartData: {
            		datasets: [{
						data: this.etf_compare.records,
						backgroundColor(context) {
							return vm.getValueColor(context.raw.v,context.raw.x,context.raw.y);
						},
						borderWidth: 1,
						width(c) {
							// console.log(Math.sqrt(c.dataset.data.length));
							const b = Math.sqrt(c.dataset.data.length);
							const a = c.chart.chartArea || {};
							// console.log(a.top,a.bottom,a.right,a.left,a.width,a.height);
							// return (a.right - a.left) / 6 - 1;
							return ((a.width) / b) -5;
						},
						height(c) {
							const a = c.chart.chartArea || {};
							const b = Math.sqrt(c.dataset.data.length);
							return ((a.height) / b) -5;
							// return (a.bottom - a.top) / 6 - 1;
						}
					}],

				},
				// eulerData: {
				// 	labels: [
				// 		'ETF 1',
				// 		'ETF 2',
				// 		'Overlap',
				// 	],
				// 	datasets: [
				// 		{
				// 			label: 'ETF Overlap',
				// 			data: [
				// 				{ sets: ['ETF 1'], value: 100 },
				// 				{ sets: ['ETF 2'], value: 50 },
				// 				{ sets: ['ETF 1', 'ETF 2'], value: 25 },
				// 			],
				// 		},
				// 	],
				// }
            }
        },
        props: {
			user_data: null,
			etf_compare: []
        },
		created() {
			Chart.register(...registerables);
			Chart.register(MatrixController, MatrixElement);
			// Chart.register(EulerDiagramController,EulerDiagramChart);
			this.validUser = !!this.user_data.email;

		},
        mounted() {
			let ctx = document.getElementById("etfCompareMatrix");
			// let ctx2 = document.getElementById("etfCompareEuler");

			// this.eulerChart = new Chart(ctx2, {
			// 	type: 'euler',
			// 	data: this.eulerData,
			// 	options: {
			// 		plugins: {
			// 			legend: {
			// 				display: false
			// 			},
			// 		}
			// 	},
			// });

			this.matrixChart = new Chart(ctx, {
				type: 'matrix',
				data: this.chartData,
				options: {
					// onHover: (event, activeElements) => {
					// 	if (activeElements?.length > 0) {
					// 		event.native.target.style.cursor = 'pointer';
					// 	} else {
					// 		event.native.target.style.cursor = 'auto';
					// 	}
					// },
					// onClick: (e) => {
					//
					// 	const points = this.matrixChart.getElementsAtEventForMode(e, 'nearest', { intersect: true }, true);
					//
					// 	if (points.length) {
					// 		this.countMode = true;
					// 		const matchedElement = this.chartData.datasets.data[points[0].index];
					// 		this.eulerData.labels[0] = matchedElement.x;
					// 		this.eulerData.labels[1] = matchedElement.y;
					// 		this.eulerData.datasets[0].data = [
					// 			{ sets: [matchedElement.x], value: matchedElement.etf1Count },
					// 			{ sets: [matchedElement.y], value: matchedElement.etf2Count },
					// 			{ sets: [matchedElement.x, matchedElement.y], value: matchedElement.overlapCount },
					// 		];
					// 		this.eulerChart.update();
					//
					//
					// 	}
					//
					// },
					borderRadius: 5,
					aspectRatio: 1,
					scales: {
						x: {
							type: 'category',
							display: true,
							ticks: {
								font: {
									size: 10
								},
								autoSkip: false
							},
							grid: {
								display: false,
								drawBorder: false
							},
						},
						y: {

							reverse: true,
							type: 'category',
							display: true,
							offset: true,
							ticks: {
								font: {
									size: 10
								},
								autoSkip: false
							},
							grid: {
								display: false,
								drawBorder: false
							}
						}
					},
					maintainAspectRatio: true,
					responsive: true,
					plugins: {
						matrix: {
							sort: false
						},
						legend: {
							display: false,
						},
						tooltip: {
							callbacks: {
								title() {
									return '';
								},
								label(context) {
									if(context.raw.x === context.raw.y) { return 'Compared against self' }
									// return [context.raw.x + ' vs ' + context.raw.y, (100*context.raw.v).toFixed(2) +"% Overlap"];
									return [(100*context.raw.v).toFixed(2) +"% of " + context.raw.x + '\'s ' + context.raw.etf1Count, 'holdings also in ' + context.raw.y];
								}
							}
						}
					},
				}
			})

			this.matrixChart.update();

		},
        computed: {
			topEtfOverlaps() {
				if(this.selectedOverlapType.slug === 'v') {
					return this.etf_compare.records.filter(etf => etf.x !== etf.y).sort((a,b) => b.v - a.v).slice(0,9);
				} else {
					return this.etf_compare.records.filter(etf => etf.x !== etf.y).sort((a,b) => b.weightSum - a.weightSum).slice(0,9);
				}
			},
			overlapTypeAvg() {
				return this.selectedOverlapType.slug === 'v' ? this.etf_compare.aggregates.aggSumPercent : this.etf_compare.aggregates.aggWeightPercent;
			}
        },
        methods: {
			openRegisterModal() {
				this.OverlapTypeDropdownOpen = false;
				this.$root.$emit('openRegisterModal');
			},
			changeOverlapType(index) {
				this.selectedOverlapType = this.overlapTypes[index];
				// rebuild the dataset based on the overlap selected
				this.buildChartData(index);
				this.matrixChart.update();
				this.OverlapTypeDropdownOpen = false;
			},

			getValueColor(valPassed,x,y) {

				if(arguments.length > 1) {
					if(x===y) { return 'rgb(255,255,255)'}
				}

				// if(valPassed === 1) { return 'rgb(94,94,94,0.8)'}
				// if(valPassed === 1) { return 'rgb(255,255,255)'}
				if(valPassed === 0) { return 'rgb(46,204,113,0.2)'}

				const gradient = [
					[
						0,
						[46,204,113]
					],
					[
						10,
						[144,200,64]
					],
					[
						20,
						[241,196,15]
					],
					[
						30,
						[236,136,38]
					],
					[
						40,
						[231,76,60]
					],
					[
						99,
						[231,76,60]
					],
					[
						100,
						[231,76,60]
					]
				];

				let colorRange = [];

				gradient.every(function (value,index) {

					let valAdjusted = valPassed * 100

					if(valAdjusted<=value[0]) {
						colorRange = [index-1,index]
						return false;
					}
					return true;
				});

				let valAdjusted = valPassed * 100
				//Get the two closest colors
				var firstcolor = gradient[colorRange[0]][1];
				var secondcolor = gradient[colorRange[1]][1];

				//Calculate ratio between the two closest colors

				var firstcolor_x = 500*(gradient[colorRange[0]][0]/100);
				var secondcolor_x = 500*(gradient[colorRange[1]][0]/100)-firstcolor_x;

				var slider_x = 500*(valPassed)-firstcolor_x;
				var ratio = slider_x/secondcolor_x

				var opacity = Math.max(0.2, Math.min(valPassed, 0.6));

				return 'rgba(' + this.pickHex( secondcolor,firstcolor, ratio ) +',' + opacity +')';

			},
			pickHex(color1, color2, weight) {
				const w = weight * 2 - 1;
				const w1 = (w + 1) / 2;
				const w2 = 1 - w1;
				return [Math.round(color1[0] * w1 + color2[0] * w2),
					Math.round(color1[1] * w1 + color2[1] * w2),
					Math.round(color1[2] * w1 + color2[2] * w2)];
			},
			getRatioColor(value) {
				if(value <= 0.05) { return '#2ecc71'; } else
				if(value <= 0.1) { return '#90C840'; } else
				if(value <= 0.17) { return '#f1c40f'; } else
				if(value <= 0.25) { return '#EC8826'; } else
				{ return '#e74c3c'; }
			},
			buildChartData(index) {
				console.log(index);
				this.chartData['datasets'][0]['data'] = this.etf_compare.records.map((record) => {
					if(index === 0) { // overlap by count
						return { ...record, v: record.overlapCountPercent };
					} else { // overlap by weight
						return { ...record, v: record.weightSum };
					}
				});
			}


        }
    }
</script>

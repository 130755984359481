<template>
	<div>

		<div class="fixed z-20 bottom-0 inset-x-0 px-4 pb-4 sm:inset-0 sm:flex sm:items-center sm:justify-center">


			<div class="fixed inset-0 transition-opacity">
				<div class="absolute inset-0 bg-gray-900 opacity-75"></div>
			</div>

			<div class="bg-white rounded-lg overflow-hidden shadow-xl transform transition-all sm:max-w-2xl sm:w-full"
				 role="dialog" aria-modal="true" aria-labelledby="modal-headline">
				<div class="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
					<div class="sm:flex sm:items-start">
						<div
							class="mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full bg-blue-100 sm:mx-0 sm:h-10 sm:w-10">

							<svg class="h-6 w-6 text-blue-800" fill="none" stroke-linecap="round"
								 stroke-linejoin="round" stroke-width="2" viewBox="0 0 24 24" stroke="currentColor">
								<path
									d="M10.325 4.317c.426-1.756 2.924-1.756 3.35 0a1.724 1.724 0 002.573 1.066c1.543-.94 3.31.826 2.37 2.37a1.724 1.724 0 001.065 2.572c1.756.426 1.756 2.924 0 3.35a1.724 1.724 0 00-1.066 2.573c.94 1.543-.826 3.31-2.37 2.37a1.724 1.724 0 00-2.572 1.065c-.426 1.756-2.924 1.756-3.35 0a1.724 1.724 0 00-2.573-1.066c-1.543.94-3.31-.826-2.37-2.37a1.724 1.724 0 00-1.065-2.572c-1.756-.426-1.756-2.924 0-3.35a1.724 1.724 0 001.066-2.573c-.94-1.543.826-3.31 2.37-2.37.996.608 2.296.07 2.572-1.065z"></path>
								<path d="M15 12a3 3 0 11-6 0 3 3 0 016 0z"></path>
							</svg>
						</div>
						<div class="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
							<h3 class="text-lg leading-6 font-medium text-gray-900" id="modal-headline">
								Balancer Options </h3>
							<div class="mt-2">
								<p class="text-sm leading-5 text-gray-500 mb-4">
									These options control how the balancer approaches the re-allocation of this scenario through recommendations.
								</p>

								<!--Temporary save Warning-->
								<div v-if="isScenarioPublicAndNotOwner"
									 class="flex items-center p-3 bg-blue-200 w-full rounded-lg mb-5 text-xs text-blue-900">

									<svg xmlns="http://www.w3.org/2000/svg" class="w-20 mr-3 p-1 text-blue-800" fill="none" viewBox="0 0 24 24" stroke="currentColor">
										<path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
									</svg>
									<span class="text-left">
										As a public scenario, you can temporarily change balancer options locally, though the defaults will remain unchanged. Consider registering or copying this scenario to save balancer options.
									</span>
								</div> <!--End temp save Warning-->

								<div class="sm:flex mb-5">

									<!--Dropdown-->
									<div class="relative inline-block text-left w-full sm:w-auto">
										<label class="block text-sm leading-5 font-medium text-gray-700">
											Share Type
										</label>
										<div class="flex">
											<span class="rounded-md shadow-sm w-full">
												<button @click="shareTypeOpen = !shareTypeOpen" type="button"
														class="inline-flex justify-between w-full sm:w-40 rounded-md border border-gray-300 px-4 py-2 bg-white text-sm leading-5 font-medium text-gray-700 hover:text-gray-500 focus:outline-none focus:border-blue-300 focus:ring-blue active:bg-gray-50 active:text-gray-800 transition ease-in-out duration-150">
													<span>{{ capitalize(shareType) }}</span>
													<svg class="-mr-1 ml-2 h-5 w-5" fill="currentColor" viewBox="0 0 20 20">
														<path fill-rule="evenodd"
															  d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
															  clip-rule="evenodd"/>
													</svg>
												</button>
											</span>
											<span class="sm:hidden ml-2">
												<button
													@click="shareTypeHelpVisible = !shareTypeHelpVisible"
													type="button"
													class="inline-flex justify-center h-full rounded-md border border-gray-300 bg-white text-gray-500 shadow-sm hover:text-gray-700 focus:outline-none focus:border-gray-500 focus:ring-gray transition ease-in-out duration-150">
													<!-- i icon info -->
													<svg v-show="!shareTypeHelpVisible"
														 class="w-5 h-5 m-2 self-center"
														 xmlns="http://www.w3.org/2000/svg" fill="none"
														 viewBox="0 0 24 24" stroke="currentColor">
														<path stroke-linecap="round" stroke-linejoin="round"
															  stroke-width="2"
															  d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"/>
													</svg>
													<!-- arrow up close -->
													<svg v-show="shareTypeHelpVisible"
														 class="w-5 h-5 m-2 self-center"
														 xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"
														 fill="currentColor">
														<path fill-rule="evenodd"
															  d="M4.293 15.707a1 1 0 010-1.414l5-5a1 1 0 011.414 0l5 5a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414 0zm0-6a1 1 0 010-1.414l5-5a1 1 0 011.414 0l5 5a1 1 0 01-1.414 1.414L10 5.414 5.707 9.707a1 1 0 01-1.414 0z"
															  clip-rule="evenodd"/>
													</svg>
												</button>
											</span>
										</div>


										<!--Full width button to close if you click outside-->
										<button v-if="shareTypeOpen" @click="shareTypeOpen = false" tabindex="-1"
												class="fixed inset-0 h-full w-full cursor-default"></button>

										<transition enter-active-class="transition ease-out duration-100"
													enter-class="transform opacity-0 scale-95"
													enter-to-class="transform opacity-100 scale-100"
													leave-active-class="transition ease-in duration-75"
													leave-class="transform opacity-100 scale-100"
													leave-to-class="transform opacity-0 scale-95">

											<div v-if="shareTypeOpen"
												 class="z-20 origin-top-right absolute right-0 mt-2 w-40 rounded-md shadow-lg z-10">
												<div class="rounded-md bg-white ring-1 ring-black ring-opacity-5">
													<div class="py-1">
														<button v-for="(option,index) in shareTypeOptions" :key=index
																@click="(shareType = capitalize(option)) && (shareTypeOpen = false)"
																class="relative block px-4 py-2 text-sm leading-5 text-gray-700 text-left w-full hover:bg-gray-100 hover:text-gray-900 focus:outline-none focus:bg-gray-100 focus:text-gray-900">
															{{ capitalize(option) }}
															<span v-show="capitalize(option) === capitalize(shareType)"
																  class="absolute inset-y-0 right-0 flex items-center pr-2">
																<svg class="h-5 w-5 text-gray-700" fill="none"
																	 stroke-linecap="round" stroke-linejoin="round"
																	 stroke-width="2" viewBox="0 0 24 24"
																	 stroke="currentColor">
																	<path d="M5 13l4 4L19 7"></path>
																</svg>
															</span>
														</button>
													</div>
												</div>
											</div>
										</transition>
									</div>
									<!--End Dropdown-->

									<!--Help text-->
									<transition name="slide">
										<div v-if="shareTypeHelpVisible">
											<div
												class="text-xs ml-2 mt-2 sm:mt-5 text-gray-600 tracking-tight text-left">
												<span
													v-if="capitalize(shareType) === 'Whole'">The balancer will make recommendations in whole (integer) number of shares. Most brokers only support purchases in whole share values.</span>
												<span
													v-else>The balancer will use fractional shares to make recommendations. 'Rebalance strategy' will have no effect as assets can be more granularly managed.</span>
											</div>
										</div>
									</transition>

								</div> <!--Close flex-->

								<div class="sm:flex mb-5">

									<!--Dropdown-->
									<div class="relative inline-block text-left w-full sm:w-auto">
										<label class="block text-sm leading-5 font-medium text-gray-700">
											Rebalance Strategy
										</label>
										<div class="flex">
											<span class="rounded-md shadow-sm w-full">
												<button @click="rebalanceStrategyOpen = !rebalanceStrategyOpen"
														type="button"
														class="inline-flex justify-between w-full sm:w-40 rounded-md border border-gray-300 px-4 py-2 bg-white text-sm leading-5 font-medium text-gray-700 hover:text-gray-500 focus:outline-none focus:border-blue-300 focus:ring-blue active:bg-gray-50 active:text-gray-800 transition ease-in-out duration-150">
													<span>{{ capitalize(strategy) }}</span>
													<svg class="-mr-1 ml-2 h-5 w-5" fill="currentColor"
														 viewBox="0 0 20 20">
														<path fill-rule="evenodd"
															  d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
															  clip-rule="evenodd"/>
													</svg>
												</button>
											</span>
											<span class="sm:hidden ml-2">
												<button
													@click="rebalanceStrategyHelpVisible = !rebalanceStrategyHelpVisible"
													type="button"
													class="inline-flex justify-center h-full rounded-md border border-gray-300 bg-white text-gray-500 shadow-sm hover:text-gray-700 focus:outline-none focus:border-gray-500 focus:ring-gray transition ease-in-out duration-150">
													<!-- i icon info -->
													<svg v-show="!rebalanceStrategyHelpVisible"
														 class="w-5 h-5 m-2 self-center"
														 xmlns="http://www.w3.org/2000/svg" fill="none"
														 viewBox="0 0 24 24" stroke="currentColor">
														<path stroke-linecap="round" stroke-linejoin="round"
															  stroke-width="2"
															  d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"/>
													</svg>
													<!-- arrow up close -->
													<svg v-show="rebalanceStrategyHelpVisible"
														 class="w-5 h-5 m-2 self-center"
														 xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"
														 fill="currentColor">
														<path fill-rule="evenodd"
															  d="M4.293 15.707a1 1 0 010-1.414l5-5a1 1 0 011.414 0l5 5a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414 0zm0-6a1 1 0 010-1.414l5-5a1 1 0 011.414 0l5 5a1 1 0 01-1.414 1.414L10 5.414 5.707 9.707a1 1 0 01-1.414 0z"
															  clip-rule="evenodd"/>
													</svg>
												</button>
											</span>
										</div>


										<!--Full width button to close if you click outside-->
										<button v-if="rebalanceStrategyOpen" @click="rebalanceStrategyOpen = false"
												tabindex="-1"
												class="fixed inset-0 h-full w-full cursor-default"></button>

										<transition enter-active-class="transition ease-out duration-100"
													enter-class="transform opacity-0 scale-95"
													enter-to-class="transform opacity-100 scale-100"
													leave-active-class="transition ease-in duration-75"
													leave-class="transform opacity-100 scale-100"
													leave-to-class="transform opacity-0 scale-95">

											<div v-if="rebalanceStrategyOpen"
												 class="z-20 origin-top-right absolute right-0 mt-2 w-40 rounded-md shadow-lg z-10">
												<div class="rounded-md bg-white ring-1 ring-black ring-opacity-5">
													<div class="py-1">
														<button v-for="(option,index) in rebalanceOptions" :key=index
																@click="(strategy = capitalize(option)) && (rebalanceStrategyOpen = false)"
																class="relative block px-4 py-2 text-sm leading-5 text-gray-700 text-left w-full hover:bg-gray-100 hover:text-gray-900 focus:outline-none focus:bg-gray-100 focus:text-gray-900">
															{{ capitalize(option) }}
															<span v-show="capitalize(option) === capitalize(strategy)"
																  class="absolute inset-y-0 right-0 flex items-center pr-2">
																<svg class="h-5 w-5 text-gray-700" fill="none"
																	 stroke-linecap="round" stroke-linejoin="round"
																	 stroke-width="2" viewBox="0 0 24 24"
																	 stroke="currentColor">
																	<path d="M5 13l4 4L19 7"></path>
																</svg>
															</span>
														</button>
													</div>
												</div>
											</div>
										</transition>
									</div>
									<!--End Dropdown-->

									<!--Help text-->
									<transition name="slide">
										<div v-if="rebalanceStrategyHelpVisible">
											<div
												class="text-xs ml-2 mt-2 sm:mt-5 text-gray-600 tracking-tight text-left">
												<span
													v-if="capitalize(strategy) === 'Balanced'">Balanced will prioritize evenness in corrective actions and may leave some cash unspent. Useful if you make make regular small deposits and deposit often.</span>
												<span
													v-else>Aggressive will attempt to spend as much cash as possible in your account. Useful if you make few, large cash contributions and rebalance infrequently.</span>
											</div>
										</div>
									</transition>

								</div> <!--Close flex-->

									   <!--Cash Warning-->
								<div v-if="cashWarning"
									 class="flex items-center p-3 bg-red-200 w-full rounded-lg mb-5 text-xs text-red-900">
									<svg class="w-20 mr-3 text-red-800" fill="none" stroke-linecap="round"
										 stroke-linejoin="round" stroke-width="2" viewBox="0 0 24 24"
										 stroke="currentColor">
										<path
											d="M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z"></path>
									</svg>
									<span class="text-left">
										You have a target allocation of cash above 0%, with the 'Aggressive' rebalance strategy selected above. To satisfy your cash target, it's unlikely 'Aggressive' will have any effect as no excess funds will be available. You may wish to switch to the 'Balanced' strategy.
									</span>
								</div> <!--End Cash Warning-->


								<div class="sm:flex mb-3">
									<div class="w-full sm:w-40">
										<div class="relative inline-block text-left w-full sm:w-auto">
											<label class="block text-sm leading-5 font-medium text-gray-700">
												Selling Threshold
											</label>

											<div class="flex">
												<div class="sm:w-40">
													<div
														class="h-10 relative rounded-md shadow-sm border border-gray-300 flex"
														:class="[sellingThresholdActive === 'NoSell' ? 'bg-gray-100' : '']">

														<div class="relative w-1/2 h-10">
															<div
																class="absolute inset-y-0 right-0 flex items-center pointer-events-none mr-6">
																<span class="sm:text-sm sm:leading-5"
																	  :class="[sellingThresholdActive === 'NoSell' ? 'text-gray-300' : 'text-gray-500']">
																	%
																</span>
															</div>
															<input v-model="sellingThreshold" placeholder="3"
																   @click="(sellingThresholdActive = 'Threshold') && (noSelling = false)"
																   :class="[sellingThresholdActive === 'Threshold' ? 'border border-blue-700 -mt-px -ml-px rounded-l-md' : 'text-gray-300']"
																   class="form-input block h-10 w-full sm:w-20 sm:text-sm sm:leading-5 bg-transparent text-right pr-10">
														</div>

														<label for="toggle" @click="sellingThresholdActive = 'NoSell'"
															   :class="[sellingThresholdActive === 'NoSell' ? 'border-blue-700 bg-white text-gray-800' : 'border-gray-300 bg-gray-100 text-gray-300']"
															   class="text-sm border -my-px -mr-px rounded-r-md w-full h-10 cursor-pointer self-center py-2 text-center">
															No Selling
														</label>
														<input v-model="noSelling" type="checkbox" id="toggle"
															   class="absolute" style="left: -100vw">
													</div>

												</div>


												<span class="sm:hidden ml-2">
													<button
														@click="sellingThresholdHelpVisible = !sellingThresholdHelpVisible"
														type="button"
														class="inline-flex justify-center h-full rounded-md border border-gray-300 bg-white text-gray-500 shadow-sm hover:text-gray-700 focus:outline-none focus:border-gray-500 focus:ring-gray transition ease-in-out duration-150">
														<!-- i icon info -->
														<svg v-show="!sellingThresholdHelpVisible"
															 class="w-5 h-5 m-2 self-center"
															 xmlns="http://www.w3.org/2000/svg" fill="none"
															 viewBox="0 0 24 24" stroke="currentColor">
															<path stroke-linecap="round" stroke-linejoin="round"
																  stroke-width="2"
																  d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"/>
														</svg>
														<!-- arrow up close -->
														<svg v-show="sellingThresholdHelpVisible"
															 class="w-5 h-5 m-2 self-center"
															 xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"
															 fill="currentColor">
															<path fill-rule="evenodd"
																  d="M4.293 15.707a1 1 0 010-1.414l5-5a1 1 0 011.414 0l5 5a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414 0zm0-6a1 1 0 010-1.414l5-5a1 1 0 011.414 0l5 5a1 1 0 01-1.414 1.414L10 5.414 5.707 9.707a1 1 0 01-1.414 0z"
																  clip-rule="evenodd"/>
														</svg>
													</button>
												</span>
											</div>

										</div>

									</div>
									<!--Help text-->
									<transition name="slide">
										<div v-if="sellingThresholdHelpVisible">
											<div
												class="text-xs ml-2 mt-1 sm:mt-5 text-gray-600 tracking-tight text-left">
												<span v-if="sellingThresholdActive === 'NoSell'">
													The solver will only consider available cash as means to balance the scenario; no assets will be sold. Requires regular deposits, or a healthy cash balance.
												</span>
												<span v-else>
													The solver will only recommend to sell an asset if the difference between the current and target percent is greater than {{ sellingThreshold }}%.
												</span>
											</div>
										</div>
									</transition>

								</div>


							</div>
						</div>
					</div>
				</div>
				<div class="bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
					<span v-if="!isScenarioPublicAndNotOwner" @click="updateBalancerSettings()" class="flex w-full rounded-md shadow-sm sm:ml-3 sm:w-auto">
						<button type="button"
								class="inline-flex justify-center w-full rounded-md border border-transparent px-4 py-2 bg-blue-800 text-base leading-6 font-medium text-white shadow-sm hover:bg-blue-600 focus:outline-none focus:border-blue-900 focus:ring-red transition ease-in-out duration-150 sm:text-sm sm:leading-5">
							Save
						</button>
					</span>
					<span v-if="isScenarioPublicAndNotOwner" @click="tempURLredirect()" class="flex w-full rounded-md shadow-sm sm:ml-3 sm:w-auto">
						<button type="button"
								class="inline-flex justify-center w-full rounded-md border border-transparent px-4 py-2 bg-blue-800 text-base leading-6 font-medium text-white shadow-sm hover:bg-blue-600 focus:outline-none focus:border-blue-900 focus:ring-red transition ease-in-out duration-150 sm:text-sm sm:leading-5">
							Temporary Save
						</button>
					</span>
					<span v-if="isScenarioPublicAndNotOwner && hasTempSettings" @click="redirectToDefault()" class="flex w-full rounded-md shadow-sm sm:ml-3 sm:w-auto">
						<button type="button"
								class="inline-flex justify-center w-full rounded-md border border-gray-300 px-4 py-2 bg-white text-base leading-6 font-medium text-gray-700 shadow-sm hover:text-gray-500 focus:outline-none focus:border-blue-300 focus:ring-blue transition ease-in-out duration-150 sm:text-sm sm:leading-5">
							Reset to Default
						</button>
					</span>
					<span @click="$emit('close')" class="mt-3 flex w-full rounded-md shadow-sm sm:mt-0 sm:w-auto">
						<button type="button"
								class="inline-flex justify-center w-full rounded-md border border-gray-300 px-4 py-2 bg-white text-base leading-6 font-medium text-gray-700 shadow-sm hover:text-gray-500 focus:outline-none focus:border-blue-300 focus:ring-blue transition ease-in-out duration-150 sm:text-sm sm:leading-5">
							Cancel
						</button>
					</span>
				</div>
			</div>

		</div>

	</div>
</template>

<script>

import {displayHelpers} from "../../../mixins/displayHelpers";

export default {
	name: "balancerOptionsModal",
	mixins: [displayHelpers],
	data() {
		return {
			rebalanceStrategyOpen: false,
			rebalanceOptions: ['Balanced', 'Aggressive'],
			shareTypeOpen: false,
			shareTypeOptions: ['Whole', 'Fractional'],
			hasTempSettings: false,

			shareType: null,
			strategy: null,

			noSelling: false,
			sellingThreshold: null,
			sellingThresholdActive: null,

			shareTypeHelpVisible: (window.innerWidth > 640),
			rebalanceStrategyHelpVisible: (window.innerWidth > 640),
			sellingThresholdHelpVisible: (window.innerWidth > 640),
		}
	},
	computed: {
		calculateTheThreshold() {
			if(this.sellingThresholdActive === 'NoSell') {
				return 'No Selling';
			} else {
				return (this.sellingThreshold / 100);
			}
		},
		calculateTheThresholdForURL(){
			if(this.sellingThresholdActive === 'NoSell') {
				return 'nosell';
			} else {
				return (this.sellingThreshold / 1);
			}
		},
		cashWarning() {
			return (this.cashProp > 0 && this.strategy === 'Aggressive');
		},
		isScenarioPublicAndNotOwner() {
			return this.settingsProp.public && !this.settingsProp.user_is_owner;
		},
		calculatedTempURL() {
			let url = "?sharetype="+ this.shareType +"&strategy=" + this.strategy + "&sellthreshold=" + this.calculateTheThresholdForURL;
			return window.location.pathname + url.toLowerCase();
		},

	},
	props: {
		open: {type: Boolean, default: false},
		settingsProp: null,
		cashProp: null,
	},
	created() {

		let urlParams = new URLSearchParams(window.location.search);

		if(urlParams.has('sharetype') && urlParams.get('strategy') && urlParams.get('sellthreshold')) {
			this.hasTempSettings = true;

			this.strategy = this.capitalizeFirstLetter(urlParams.get('strategy'));
			this.shareType = this.capitalizeFirstLetter(urlParams.get('sharetype'));
			// selling threshold
			if (urlParams.get('sellthreshold') === 'nosell') {
				this.sellingThresholdActive = 'NoSell';
			} else {
				this.sellingThreshold = (parseInt(urlParams.get('sellthreshold')));
				this.sellingThresholdActive = 'Threshold';
			}


		} else { // no params passed so just use the defaults

			this.strategy = this.settingsProp.rebalance_strategy;
			this.shareType = this.settingsProp.rebalance_share_type;

			// selling threshold
			if (this.settingsProp.rebalance_selling_threshold === 'No Selling') {
				this.sellingThresholdActive = 'NoSell';
			} else {
				this.sellingThreshold = (this.settingsProp.rebalance_selling_threshold * 100);
				this.sellingThresholdActive = 'Threshold';
			}

		}
	},

	methods: {
		capitalize(string) {
			if (typeof string !== 'string') {
				return ''
			} else {
				return string.charAt(0).toUpperCase() + string.slice(1);
			}
		},
		updateBalancerSettings() {
			axios.post('/updateScenarioOptions', {
				scenarioID: this.settingsProp.id,
				userID: this.settingsProp.user_id,
				strategy: this.strategy,
				shareType: this.shareType,
				threshold: this.calculateTheThreshold,
			})
				.then(response => {
					location.reload();
					this.$emit('close');
				})
				.catch(error => {
					location.reload();
					this.$emit('close');
				});
		},
		tempURLredirect() {
			window.location.href = this.calculatedTempURL;
		},
		redirectToDefault() {
			window.location.href = window.location.pathname;
		}
	},


}
</script>

<style>
.slide-enter-active {
	-moz-transition-duration: 0.3s;
	-webkit-transition-duration: 0.3s;
	-o-transition-duration: 0.3s;
	transition-duration: 0.3s;
	-moz-transition-timing-function: ease-in;
	-webkit-transition-timing-function: ease-in;
	-o-transition-timing-function: ease-in;
	transition-timing-function: ease-in;
}

.slide-leave-active {
	-moz-transition-duration: 0.3s;
	-webkit-transition-duration: 0.3s;
	-o-transition-duration: 0.3s;
	transition-duration: 0.3s;
	-moz-transition-timing-function: cubic-bezier(0, 1, 0.5, 1);
	-webkit-transition-timing-function: cubic-bezier(0, 1, 0.5, 1);
	-o-transition-timing-function: cubic-bezier(0, 1, 0.5, 1);
	transition-timing-function: cubic-bezier(0, 1, 0.5, 1);
}

.slide-enter-to, .slide-leave {
	max-height: 100px;
	overflow: hidden;
}

.slide-enter, .slide-leave-to {
	overflow: hidden;
	max-height: 0;
}
</style>

export const displayHelpers = {
    methods: {
        // this properly formats numbers into dollar figures with correct spacing, commas, etc.
        moneyFormat(value) {
            const formatter = new Intl.NumberFormat('en-US', {
                style: 'currency',
                currency: 'USD',
            });
            return formatter.format(value);
        },
        // this converts a decimal to a formatted percent
        decimalPercentFormat(value,digits = 1) {
            return parseFloat((value * 100).toFixed(digits)) + '%';
        },
        // this will correctly round long numbers to 2 decimal places, while leaving integers alone
        roundTo(n, digits) {
            let negative = false;
            if (digits === undefined) { digits = 2; }
            if (Number.isInteger(n)) { return n; }

            if (n < 0) {
                negative = true;
                n = n * -1;
            }
            const multiplicator = Math.pow(10, digits);
            n = parseFloat((n * multiplicator).toFixed(11));
            n = (Math.round(n) / multiplicator).toFixed(2);
            if (negative) {
                n = (n * -1).toFixed(2);
            }
            return n;
        },
        // used when determining if a "show more" set of dots should be shown
        checkLength(text) {
            if (text.length >22 )
                return "..."
        },
        // used to scroll the name of the stock over
        scrollOver(symbol) {
            const elementId = this.$refs[symbol][0];
            let textWidth = elementId.offsetWidth;
            let boxWidth = elementId.parentElement.clientWidth;

            if (textWidth > boxWidth) {
                let translateValue = Math.min(boxWidth - textWidth, 0);
                elementId.style.transitionDuration = -0.01 * translateValue + "s";
                elementId.style.transform = "translateX("+translateValue+"px)";
                this.$refs[symbol+'arrow'][0].style.display = "none";
            }
        },
        // used to scroll the text back
        scrollBack(symbol) {
            const elementID = this.$refs[symbol][0];
            elementID.style.transitionDuration = "0.3s";
            elementID.style.transform = "translateX(0)";
            this.$refs[symbol+'arrow'][0].style.display = "initial";
        },
        capitalizeFirstLetter(string) {
            return string.charAt(0).toUpperCase() + string.slice(1);
        }
    }
}
<template>
	<a class="shadow-lg border border-gray-700 rounded-xl p-4 flex space-x-4 bg-gradient-to-br from-gray-900 to-gray-800 text-gray-500 cursor-pointer transform hover:scale-105 transition duration-150 hover:shadow-2xl" :href="'/scenario/share/' + this.scenario.uuid">

		<!-- Donut chart -->
		<div class="flex justify-center items-center">

			<div style="height: 100px; width: 100px;">
				<canvas :id="this.scenario.uuid"></canvas>
			</div>

		</div>

		<div class="flex-1">


			<h2 class="font-semibold text-white">{{ this.scenario.name }}</h2>

			<div class="flex mb-2">
			<div class="flex items-center text-xs">
				<div class="text-gray-600 pr-2">Accuracy</div>
				<div class="w-2 h-2 rounded-full" :style="{ backgroundColor: getValueColor(this.scenario.originalAccuracy)}"></div>
				<div class="pl-1">
					{{ decimalPercentFormat(this.scenario.originalAccuracy) }}
				</div>
			</div>
				<svg xmlns="http://www.w3.org/2000/svg" class="h-4 w-4 mx-4" fill="none" viewBox="0 0 24 24" stroke="currentColor">
					<path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M17 8l4 4m0 0l-4 4m4-4H3" />
				</svg>
			<div class="flex items-center text-xs">
				<div class="text-gray-600 pr-2">Corrected Accuracy</div>
				<div class="w-2 h-2 rounded-full" :style="{ backgroundColor: getValueColor(this.scenario.correctedAccuracy)}"></div>
				<div class="pl-1">
					{{ decimalPercentFormat(this.scenario.correctedAccuracy) }}
				</div>
			</div>
			</div>

			<div class="flex items-center mb-2 space-x-4">
				<span class="text-gray-600 text-xs pr-2">Actions: </span>
				<span v-if="this.scenario.actions.sells"><span class="text-sm">{{ this.scenario.actions.sells }} x</span> <span class="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-green-900 text-green-400">BUY</span></span>
				<span v-if="this.scenario.actions.buys"><span class="text-sm">{{ this.scenario.actions.buys }} x</span> <span class="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-red-900 text-red-400">SELL</span></span>
				<span v-if="this.scenario.actions.holds"><span class="text-sm">{{ this.scenario.actions.holds }} x</span> <span class="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-gray-600 text-gray-200">HOLD</span></span>
			</div>

			<div class="flex items-center text-xs">
				<div class="text-gray-600 pr-2">Index Alignment vs SP500</div>
				<div class="w-2 h-2 rounded-full" :style="{ backgroundColor: getValueColor(this.scenario.indexAlignment)}"></div>
				<div class="pl-1">
					{{ decimalPercentFormat(this.scenario.indexAlignment) }}
				</div>
			</div>


<!--			<p class="leading-normal text-xs">Create scenarios with custom allocation rules and get actionable steps for ideal rebalancing, including selling threshold, cash buffers, and fractional shares.</p>-->
		</div>
	</a>
</template>

<script>
import {Chart, registerables} from "chart.js";
import { displayHelpers } from "../../mixins/displayHelpers";

export default {
	name: "exampleScenarioCard",
	mixins: [displayHelpers],
	data() {
		return {
			donutChart: null,
			chartData: {
				// labels: [],
				labels: this.scenario.labels,
				datasets: [
					{
						label: 'You',
						data: this.scenario.chartDummyData,
						borderColor: this.scenario.colors,
						backgroundColor: this.scenario.colors,
						borderWidth: 1
					},
				]
			},
		}
	},
	props: {
		scenario: null,
	},
	created() {
		Chart.register(...registerables);

	},
	mounted() {
		let ctx = document.getElementById(this.scenario.uuid);

		// this.buildChartData();




		this.donutChart = new Chart(ctx, {
			type: 'doughnut',
			data: this.chartData,
			options: {
				scales: {
					x: {display: false},
					y: {display: false},
				},
				maintainAspectRatio: false,
				responsive: true,
				plugins: {
					tooltip: {
						// enabled: true
						displayColors: false,
						callbacks: {
							label: context => context.label
						}
					},
					legend: {
						display: false,
					}
				},

			}
		})
	},
	computed: {
		//
	},
	methods: {
		buildChartData() {
			const tempLabels = [];
			const tempIndexWeights = [];
			const tempYourWeights = [];
			Object.entries(this.activeIndex).map(function(sector) {

				tempLabels.push(sector[0])
				tempIndexWeights.push(sector[1]['sectorWeight']);
				tempYourWeights.push(sector[1]['currentWeight']);
			});

			this.chartData['labels'] = tempLabels;
			this.chartData['datasets'][0]['data'] = tempYourWeights;
			this.chartData['datasets'][1]['data'] = tempIndexWeights;


			this.chartData['datasets'][1]['label'] = this.selectedIndex.name;
		},
		getValueColor(value) {
			if(value >= 0.95) { return '#2ecc71'; } else
			if(value >= 0.9) { return '#90C840'; } else
			if(value >= 0.8) { return '#f1c40f'; } else
			if(value >= 0.75) { return '#EC8826'; } else
			{ return '#e74c3c'; }
		},


	}
}
</script>

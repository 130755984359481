<template>
    <div v-if="visible">

        <div class="mb-5 max-w-xl">

            <div class="align-middle inline-block min-w-full shadow rounded-lg bg-gray-700">
                <div class="sm:flex p-4 text-white">
                    <div class="w-8 self-center">
                        <svg class="hidden sm:block w-6 text-white" fill="none" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" viewBox="0 0 24 24"
                             stroke="currentColor">
                            <path
                                d="M5 3v4M3 5h4M6 17v4m-2-2h4m5-16l2.286 6.857L21 12l-5.714 2.143L13 21l-2.286-6.857L5 12l5.714-2.143L13 3z"></path>
                        </svg>
                    </div>
                    <span class="sm:pl-2 text-sm leading-tight">
                        This scenario is linked with a broker; non-zero positions cannot be removed. You may need to re-sync with your broker before updating targets.
                    </span>

                </div>
            </div>

        </div>

    </div>
</template>

<script>
    export default {
        name: 'linkedScenarioCard',
        data() {
            return {
                //
            }
        },
        props: {
            visible: {type: Boolean , default: false},
        }
    }
</script>

<template>
    <div>
        <confirm-delete-modal :open=deleteModalOpen :data="primaryScenario"
                              @close="deleteModalOpen = false">
        </confirm-delete-modal>

		<confirm-share-modal :open=shareModalOpen :data="primaryScenario"
							  @close="shareModalOpen = false"
							 @copyToClipboard="copyToClipboard">
		</confirm-share-modal>

        <div class="mb-5">
            <div class="-my-2 py-2 sm:-mx-6 sm:px-6 lg:-mx-8 lg:px-8">
                <div class="align-middle inline-block min-w-full shadow rounded-lg bg-white">
                    <div>
                        <div class="p-5">
                            <div class="sm:flex justify-between items-end">

                                <!--SCENARIO DROPDOWN-->



								<div class="block">
									<div class="flex">
								<div class="relative sm:inline-block text-left">

									<button @click="scenarioDropdownOpen = !scenarioDropdownOpen" type="button"
											class="inline-flex justify-between w-full rounded-md border border-gray-300 px-4 py-2 bg-white text-sm leading-5 font-medium text-gray-700 hover:text-gray-500 focus:outline-none focus:border-blue-300 focus:ring-blue active:bg-gray-50 active:text-gray-800 transition ease-in-out duration-150">
										<span
											:class="[ primaryScenario.name === 'None' ? 'text-gray-500 italic' : '']">{{ primaryScenario.name }}</span>
										<svg class="-mr-1 ml-2 h-5 w-5" fill="currentColor" viewBox="0 0 20 20">
											<path fill-rule="evenodd"
												  d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
												  clip-rule="evenodd"/>
										</svg>
									</button>

									<!--Full width button to close if you click outside-->
									<button v-if="scenarioDropdownOpen" @click="scenarioDropdownOpen = false"
											tabindex="-1" class="fixed inset-0 h-full w-full cursor-default"></button>

									<transition enter-active-class="transition ease-out duration-100"
												enter-class="transform opacity-0 scale-95"
												enter-to-class="transform opacity-100 scale-100"
												leave-active-class="transition ease-in duration-75"
												leave-class="transform opacity-100 scale-100"
												leave-to-class="transform opacity-0 scale-95">

										<div v-if="scenarioDropdownOpen"
											 class="origin-top-right absolute right-0 mt-2 w-56 rounded-md shadow-lg z-10">
											<div class="rounded-md bg-white ring-1 ring-black ring-opacity-5">
												<div class="py-1">
													<button v-for="(scenario,index) in options" :key=index
															@click="changeScenario(index)"
															class="inline-flex relative block px-4 py-2 text-sm leading-5 text-gray-700 text-left w-full hover:bg-gray-100 hover:text-gray-900 focus:outline-none focus:bg-gray-100 focus:text-gray-900">

														<!-- TDA Logo --->
														<svg v-if="scenario.custom === 0" class="w-6 mr-2" viewBox="0 0 400 357.728">
															<path d="M0 357.728h400V0H0v357.728z" fill="#54b948"/>
															<g clip-path="url(#a)" transform="matrix(6.6246 0 0 -6.6246 -7574.363 596.214)">
																<path
																	d="M1183.01 47.682h-14.064v25.336h7.064V52.17h6.794c4.69 0 6.66 3.249 6.66 11.616 0 8.417-2.238 10.863-7 10.863h-15.494V47.682h-6.995v26.967h-10.265v4.498h34.934c8.426 0 12.429-4.36 12.429-15.294 0-14.405-5.977-16.171-14.063-16.171"
																	fill="#fff"/>
															</g>
														</svg>

														{{scenario.name}}
														<span v-show="scenario.id === primaryScenario.id"
															  class="absolute inset-y-0 right-0 flex items-center pr-2">
															<svg class="h-5 w-5 text-gray-700" fill="none"
																 stroke-linecap="round" stroke-linejoin="round"
																 stroke-width="2" viewBox="0 0 24 24"
																 stroke="currentColor">
																<path d="M5 13l4 4L19 7"></path>
															</svg>
														</span>
													</button>
												</div>
											</div>
										</div>
									</transition>
								</div>


								<button @mouseover="visibilityHover = true" @mouseleave="visibilityHover = false" class="ml-3 flex text-xs items-center text-gray-500"
										:class="isPublic ? 'hover:cursor-pointer hover:text-gray-800' : 'cursor-default'"
										@click="makePrivate()">
									<!-- Eye open -->
									<svg v-if="isPublic" xmlns="http://www.w3.org/2000/svg" class="h-4 w-4" viewBox="0 0 20 20" fill="currentColor">
										<path d="M10 12a2 2 0 100-4 2 2 0 000 4z" />
										<path fill-rule="evenodd" d="M.458 10C1.732 5.943 5.522 3 10 3s8.268 2.943 9.542 7c-1.274 4.057-5.064 7-9.542 7S1.732 14.057.458 10zM14 10a4 4 0 11-8 0 4 4 0 018 0z" clip-rule="evenodd" />
									</svg>
									<!-- eye closed -->
									<svg v-if="!isPublic" xmlns="http://www.w3.org/2000/svg" class="h-4 w-4" viewBox="0 0 20 20" fill="currentColor">
										<path fill-rule="evenodd" d="M3.707 2.293a1 1 0 00-1.414 1.414l14 14a1 1 0 001.414-1.414l-1.473-1.473A10.014 10.014 0 0019.542 10C18.268 5.943 14.478 3 10 3a9.958 9.958 0 00-4.512 1.074l-1.78-1.781zm4.261 4.26l1.514 1.515a2.003 2.003 0 012.45 2.45l1.514 1.514a4 4 0 00-5.478-5.478z" clip-rule="evenodd" />
										<path d="M12.454 16.697L9.75 13.992a4 4 0 01-3.742-3.741L2.335 6.578A9.98 9.98 0 00.458 10c1.274 4.057 5.065 7 9.542 7 .847 0 1.669-.105 2.454-.303z" />
									</svg>
									<p class="ml-1">{{ isPublic ? "Public " : "Private" }} <span v-show="visibilityHover && isPublic" class="italic"> - Make private?</span></p>
								</button>
									</div>
										<div class="text-xs text-gray-600 mt-3 mx-3">
											<span class="">{{ this.primaryScenario.description }}</span>
										</div>
								</div>


								<!-- buttons -->
								<div class="flex ml-3">
									<div class="flex w-20">
										<button @click="shareClick()" type="button" v-tooltip="{ content: (this.isPublic ? 'This scenario is public, click to copy URL for sharing.' : 'Sharing will make this scenario public and generate a sharable URL.'), class: 'text-xs text-gray-300 fade-in' }"
												class="inline-flex justify-center w-full rounded-md border  p-2 bg-white  shadow-sm focus:outline-none transition ease-in-out duration-150 text-xs sm:leading-5"
												:class="[isCopied ? 'text-green-500 border-green-500' : 'text-gray-600 hover:text-gray-500 border-gray-300']"
										>
											<!-- Share icon--->
											<svg v-show="!isCopied" xmlns="http://www.w3.org/2000/svg" class="w-4 h-4 self-center" viewBox="0 0 20 20" fill="currentColor">
												<path d="M15 8a3 3 0 10-2.977-2.63l-4.94 2.47a3 3 0 100 4.319l4.94 2.47a3 3 0 10.895-1.789l-4.94-2.47a3.027 3.027 0 000-.74l4.94-2.47C13.456 7.68 14.19 8 15 8z" />
											</svg>
											<!-- Check mark icon-->
											<svg v-show="isCopied" xmlns="http://www.w3.org/2000/svg" class="w-4 h-4 self-center cursor-pointer" viewBox="0 0 20 20" fill="currentColor">
												<path fill-rule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clip-rule="evenodd" />
											</svg>
											<p class="ml-1">{{ (isCopied ? 'Copied!' : 'Share') }}</p>
										</button>
									</div>
									<div class="flex-1 sm:flex-initial ml-2" v-if="scenarioLoaded">
										<a :href="'/edit/' + this.primaryScenario.id"
										   class="w-full inline-flex rounded-md border border-gray-300 p-2 bg-white hover:text-blue-500 cursor-pointer text-gray-700 shadow-sm hover:text-gray-500 focus:outline-none transition ease-in-out duration-150 text-xs sm:leading-5">
											<svg class="w-5 h-5" fill="none"
												 stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
												 viewBox="0 0 24 24" stroke="currentColor">
												<path
													d="M15.232 5.232l3.536 3.536m-2.036-5.036a2.5 2.5 0 113.536 3.536L6.5 21.036H3v-3.572L16.732 3.732z"></path>
											</svg>
											<span class="text-xs text-center w-full leading-3 self-center">Edit</span>
										</a>
									</div>
									<!-- Button - Trash Can -->
									<span class="flex-1 sm:flex-initial ml-2" v-if="scenarioLoaded">
										<button @click="deleteModalOpen = !deleteModalOpen" type="button"
												class="w-full inline-flex rounded-md border border-gray-300 hover:border-red-500 p-2 bg-white text-gray-700 shadow-sm hover:text-red-500 cursor-pointer focus:outline-none transition ease-in-out duration-150 text-xs sm:leading-5">
											<svg class="w-4 h-4" fill="none" style="margin: 2px;"
												 stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
												 viewBox="0 0 24 24" stroke="currentColor">
												<path
													d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16"></path>
											</svg>
											<span class="sm:hidden text-xs text-center w-full leading-3 self-center">Delete</span>
										</button>
									</span>
									<!-- Button  End -->
								</div>

							</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'ScenarioCard',
        data() {
            return {
                scenarioDropdownOpen: false,
                deleteModalOpen: false,
				shareModalOpen: false,
				isCopied: false,
                scenarioLoaded: false,
				isPublic: false,
				visibilityHover: false,
                blankScenario: {
                    name: 'None',
                    description: ''
                },
            }
        },
        props: {
            primaryScenario: null,
            options: null,
        },
        mounted() {
          (this.primaryScenario === undefined) ? this.scenarioLoaded = false : this.scenarioLoaded = true;
          if(this.primaryScenario === undefined) {
          	this.primaryScenario = this.blankScenario
		  } else {
          	this.isPublic = this.primaryScenario.public;
		  }

        },
        computed: {
			//
        },
        methods: {
            changeScenario(index) {
                if(this.options[index].id === this.primaryScenario.id) {
                    // if you selected the one already loaded, just close the prompt
                    this.scenarioDropdownOpen = false;
                } else {
                    axios.post('/changeScenario', {
                        // pass the scenario ID of the item selected from the list based on its index
                        change: this.options[index].id,
                    })
                        .then(response => {
							window.location.href = '/dashboard';
                            // location.reload();
                        })
                        .catch(error => {
                            location.reload();
                        });
                }
            },
            edit() {
                window.location.href = '/edit/' + this.primaryScenario.id;
            },
			shareClick() {
            	if(this.isPublic) {
            		this.copyToClipboard()
				} else {
					this.shareModalOpen = true
				}
			},
			makePrivate() {
            	if(this.isPublic) {
					this.$root.$emit('processing');
					axios.post('/changeVisibility', {
						isPublic: false
					})
						.then(response => {
							this.isPublic = false;
							this.$root.$emit('stopprocessing');
						})
						.catch(error => {
							window.location.href = '/dashboard'
						});
				}
			},
			copyToClipboard() {
            	this.isPublic = true;
				this.isCopied = true;
				const baseUrl = window.location.origin
				const sharedURL = baseUrl + '/scenario/share/' + this.primaryScenario.uuid

				try {
					navigator.clipboard.writeText(sharedURL);
					console.log('Page URL copied to clipboard');
				} catch (err) {
					console.error('Failed to copy: ', err);
				}

				setTimeout(() => this.isCopied = false, 3000);

			}
        }
    }
</script>

<template>

    <!-- Change Email -->
    <div class="mb-5">
        <div class="-my-2 py-2 sm:-mx-6 sm:px-6 lg:-mx-8 lg:px-8">
            <div class="align-middle inline-block min-w-full shadow rounded-lg bg-white">
                <div>
                    <div class="p-5">
                        <div class="relative">

                            <div class="flex justify-between">

                                <!--Email Input-->
                                <div class="pb-3 flex-grow">
                                    <div class="text-md pb-4">
                                        Change Email
                                    </div>
                                    <!-- Current Email Input-->
                                    <div class="pb-4">
                                        <label for="current_email"
                                               class="block text-sm leading-5 font-medium text-gray-700">
                                            Current Email
                                        </label>
                                        <div class="flex">
                                            <input v-model="this.user.email"
                                                   disabled
                                                   class="h-8 w-1/2 appearance-none bg-transparent cursor-not-allowed bg-gray-100 text-gray-700 py-1 px-2 leading-tight focus:outline-none border border-gray-300 rounded-md text-sm focus:border-blue-700"
                                                   id="current_email" type="email">
                                            <div v-if="success" class="ml-3 text-sm text-green-600 self-center">
                                                Email successfully updated.
                                            </div>
                                        </div>
                                    </div>

                                    <!--New Email Input-->
                                    <div class="pb-4">
                                        <label for="newEmail" class="block text-sm leading-5 font-medium text-gray-700">
                                            New Email
                                        </label>
                                        <input v-model="new_email"
                                               @focus="clear()"
                                            :disabled="processingSave"
                                            :class="[processingSave ? 'cursor-not-allowed bg-gray-100' : '']"
                                            class="h-8 w-1/2 appearance-none bg-transparent text-gray-700 py-1 px-2 leading-tight focus:outline-none border border-gray-300 rounded-md text-sm focus:border-blue-700"
                                            type="email" id="newEmail">
                                    </div>

                                    <!--Password Input-->
                                    <div class="pb-4">
                                        <label for="confirmPasswordEmail"
                                               class="block text-sm leading-5 font-medium text-gray-700">
                                            Password
                                        </label>
                                        <input v-model="email_password"
                                               @focus="clear()"
                                            :disabled="processingSave"
                                            :class="[processingSave ? 'cursor-not-allowed bg-gray-100' : '']"
                                            class="h-8 w-1/2 appearance-none bg-transparent text-gray-700 py-1 px-2 leading-tight focus:outline-none border border-gray-300 rounded-md text-sm focus:border-blue-700"
                                            type="password" id="confirmPasswordEmail">
                                    </div>

                                    <div v-if="errors" class="flex items-center p-3 bg-red-200 w-full rounded-md text-xs text-red-900">
                                        <div class="w-5 h-5 mr-3 text-red-800">
                                            <svg
                                                fill="none" stroke-linecap="round" stroke-linejoin="round"
                                                stroke-width="2" viewBox="0 0 24 24" stroke="currentColor">
                                                <path
                                                    d="M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z"></path>
                                            </svg>
                                        </div>
                                        <div>
                                            <div>{{this.errors}}</div>
                                        </div>

                                    </div>


                                </div>

                                <!-- Save button -->
                                <span>
                                    <button
                                        @click="changeEmail()"
                                        :disabled="processingSave"
                                        :class="[processingSave ? 'cursor-not-allowed text-gray-100 bg-gray-100' : 'text-gray-700 bg-white hover:text-gray-500']"
                                        type="button"
                                        class="inline-flex justify-center w-full rounded-md border border-gray-300 px-4 py-2 text-base leading-6 font-medium shadow-sm focus:outline-none focus:border-blue-300 focus:ring-blue transition ease-in-out duration-150 sm:text-sm sm:leading-5">
                                        Save
                                        <svg class="w-4 h-4 ml-3 self-center" fill="none" stroke-linecap="round"
                                             stroke-linejoin="round" stroke-width="2" viewBox="0 0 24 24"
                                             stroke="currentColor">
                                            <path d="M9 5l7 7-7 7"></path>
                                        </svg>
                                        <div v-if="processingSave" class="dots absolute z-30 mt-1">
                                            <div></div>
                                            <div></div>
                                            <div></div>
                                        </div>
                                    </button>
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

</template>

<script>
    export default {
        name: "emailChangeCard",
        props: {
            user: null,
        },
        data() {
            return {
                processingSave: false,
                new_email: '',
                email_password: '',
                errors: '',
                success: false,
            }
        },

        methods: {
            clear() {
                this.errors = null;
                this.success = false;
            },
            changeEmail() {
                this.processingSave = true,
                    axios.post('/users/change_email', {password: this.email_password, email: this.new_email})
                        .then((response) => {

                            this.user.email = this.new_email;
                            this.email_password = '';
                            this.new_email = '';
                            this.processingSave = false;
                            this.success = true;

                        })
                        .catch((error) => {
                            this.errors = error.response.data
                            this.processingSave = false;
                        });
            },
        },
    }
</script>

<template>
  <div>

    <confirm-resync-change :open=resyncChangeModalOpen
                          @close="resyncChangeModalOpen = false">
    </confirm-resync-change>

    <!--Black Background for when processing-->
    <div v-if="processing" class="fixed z-20 bottom-0 inset-x-0 px-4 pb-4 sm:inset-0 sm:flex sm:items-center sm:justify-center">
      <div class="fixed inset-0 transition-opacity">
        <div class="absolute inset-0 bg-gray-900 opacity-75"></div>
      </div>
		<div class="flex justify-center z-30 animate-pulse">
			<p class="text-white text-2xl">Resyncing with TD Ameritrade...</p>
			<!-- Processing dots/ feedback when you hit save -->
			<div class="dots absolute mt-12">
				<div></div>
				<div></div>
				<div></div>
			</div>
		</div>
    </div>

    <div class="mb-5">
      <div class="-my-2 py-2 sm:-mx-6 sm:px-6 lg:-mx-8 lg:px-8">
        <div class="align-middle inline-block min-w-full shadow rounded-lg bg-white"
			:class="[!isAuthValid ? 'border border-red-500' : '']"
		>
          <div class="">

			  <div class="flex justify-between px-5 py-3">

				  <div class="flex items-center">
					  <!-- TDA Logo --->
					  <svg class="w-8" viewBox="0 0 400 357.728">
						  <path d="M0 357.728h400V0H0v357.728z" fill="#54b948"/>
						  <g clip-path="url(#a)" transform="matrix(6.6246 0 0 -6.6246 -7574.363 596.214)">
							  <path
								  d="M1183.01 47.682h-14.064v25.336h7.064V52.17h6.794c4.69 0 6.66 3.249 6.66 11.616 0 8.417-2.238 10.863-7 10.863h-15.494V47.682h-6.995v26.967h-10.265v4.498h34.934c8.426 0 12.429-4.36 12.429-15.294 0-14.405-5.977-16.171-14.063-16.171"
								  fill="#fff"/>
						  </g>
					  </svg>
					  <div class="block pl-3 text-xs">
						  <div class="text-gray-600">Account #</div>
						  <div class="">{{ lastFour(this.primaryScenario.account_id) }}</div>
					  </div>
				  </div>

				  <!-- Refreshing data callout -->
				  <div v-if="isDataStale || !isAuthValid" class="flex">
					  <div class="flex text-gray-600 items-center text-center text-xs px-4">
						  <span v-if="silentProcessing" class="animate-pulse">Refreshing data</span>
						  <span v-else-if="refreshedFromServer" class="flex items-center text-green-600">
							  <svg xmlns="http://www.w3.org/2000/svg" class="h-4 w-4" fill="none" viewBox="0 0 24 24" stroke="currentColor">
								  <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 13l4 4L19 7" />
							  </svg>
							  Data refreshed; reload page to update
						  </span>
						  <span v-else-if="errorMessage" class="text-red-600">
							  {{  this.errorMessage }} {{ this.primaryScenario.autopilot_active ? " Autopilot has been disabled." : "" }}
						  </span>
						  <span v-else class="text-red-600">
								Auth expired, please reconnect to update your data.
						  </span>
					  </div>
				  </div>



				  <!-- Last synced -->
				  <div v-if="isAuthValid" class="flex">
					  <div class="flex items-center pr-2 text-xs text-right">
<!--						  <div class="text-gray-600">Last Sync</div>-->
						  <div class="text-gray-600">Last synced {{this.primaryScenario.last_synced_readable}}</div>
					  </div>
					  <span>
						  <button @click="[(isDataStale && refreshedFromServer) ? refreshPage() : resync()]" type="button" :disabled="silentProcessing"
								  :class="[silentProcessing ? 'cursor-not-allowed' : '']"
								  class="inline-flex justify-center w-full rounded-md border border-gray-300 px-2 py-2 bg-white leading-6 font-medium text-gray-700 shadow-sm hover:text-gray-500 focus:outline-none focus:border-blue-300 focus:ring-blue transition ease-in-out duration-150 text-xs sm:leading-5">
							  <svg :class="[silentProcessing ? 'animate-spin' : '']"
								   class="w-4 h-4 self-center" fill="none" stroke="currentColor" viewBox="0 0 24 24"
								   xmlns="http://www.w3.org/2000/svg">
								  <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
										d="M4 4v5h.582m15.356 2A8.001 8.001 0 004.582 9m0 0H9m11 11v-5h-.581m0 0a8.003 8.003 0 01-15.357-2m15.357 2H15"></path>
							  </svg>
						  </button>
					  </span>


				  </div>




				  <!-- Auth Expiry Icon -->
				  <div v-if="!isAuthValid" class="flex">
					  <span>
						  <button @click="redirectTDAuth()" type="button"
								  class="inline-flex justify-center w-full rounded-md border border-gray-300 px-2 py-2 bg-white leading-6 font-medium text-gray-700 shadow-sm hover:text-gray-500 focus:outline-none focus:border-blue-300 focus:ring-blue transition ease-in-out duration-150 text-xs sm:leading-5">
							  <svg class="w-4 h-4 self-center" fill="none" stroke="currentColor" viewBox="0 0 24 24"
								   xmlns="http://www.w3.org/2000/svg">
								  <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
										d="M12 11c0 3.517-1.009 6.799-2.753 9.571m-3.44-2.04l.054-.09A13.916 13.916 0 008 11a4 4 0 118 0c0 1.017-.07 2.019-.203 3m-2.118 6.844A21.88 21.88 0 0015.171 17m3.839 1.132c.645-2.266.99-4.659.99-7.132A8 8 0 008 4.07M3 15.364c.64-1.319 1-2.8 1-4.364 0-1.457.39-2.823 1.07-4"></path>
							  </svg>
							  <div class="flex items-center pl-2 text-xs">
								  <div class="">Reconnect</div>
							  </div>
						  </button>
					  </span>

				  </div>




			  </div>


          </div>
        </div>
      </div>
    </div>

  </div>

</template>

<script>
    import ConfirmResyncChange from "../TDAConfirm/confirmResyncChange";
    export default {
        name: 'TDAuthCard',
      components: {ConfirmResyncChange},
      data() {
            return {
                processing: false,
                resyncChangeModalOpen: false,
				isDataStale: false,
				silentProcessing: false, // used when reloading data behind the scenes
				refreshedFromServer: false,
				errorMessage: null,
				isAuthValid: true,

            }
        },
        props: {
            primaryScenario: null,
            user_data: null,
        },
		created() {

				this.isAuthValid = this.user_data.tda_account['is_valid'];

				if (this.isAuthValid) {
					// determine how long it's been since syncing with broker
					const lastSynced = new Date(this.primaryScenario.last_synced);
					const now = new Date();
					const hours_old = ((now - lastSynced) / 1000) / 60 / 60;

					if (hours_old >= 8) {
						// TRIGGER REFRESH OF DATA
						this.silentProcessing = true;
						this.isDataStale = true;
						this.resync(true);
					}
				}

		},
        methods: {
        	refreshPage() {
				location.reload();
			},
            redirectTDAuth() {
              window.location.href = '/td-auth'
            },
            lastFour(value) {
              const str = value.toString();
              return str.slice(0, -4).replace(/./g, '*') + str.slice(-4);
            },
            resync(silentProcessing = false) {
            	if (silentProcessing) {
            		this.silentProcessing = true;
				} else {
					this.processing = true;
				}
                axios.post('/tda-resync/' + this.primaryScenario.id)
                    .then(response => {
                      if (response.status === 206) {
                        // load warning popup due to change
                        this.resyncChangeModalOpen = true;
                        this.processing = false;
                      } else { // normal successful update
                      	if(silentProcessing) {
                      		this.refreshedFromServer = true;
                      		this.silentProcessing = false;
						} else {
							location.reload();
						}
                      }
                    })
                    .catch(error => {
                    	console.log(error.response.status);
						if (error.response.status === 511) { // this means TD rejected the credentials passed
							this.errorMessage = error.response.data.message;
							this.isAuthValid = false;
						} else {
							this.errorMessage = "There was a temporary error refreshing your data."
						}
						this.silentProcessing = false;

                    //   location.reload();
                    });
            }
        }
    }
</script>

<template>
    <div>
        <div v-if="open" class="fixed z-20 bottom-0 inset-x-0 px-4 pb-4 sm:inset-0 sm:flex sm:items-center sm:justify-center">

            <transition enter-active-class="ease-out duration-300"
                        enter-class="opacity-0"
                        enter-to-class="opacity-100"
                        leave-active-class="ease-in duration-200"
                        leave-class="opacity-100"
                        leave-to-class="opacity-0">

                <div class="fixed inset-0 transition-opacity">
                    <div class="absolute inset-0 bg-gray-900 opacity-75"></div>
                </div>

            </transition>


            <transition enter-active-class="ease-out duration-300"
                        enter-class="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                        enter-to-class="opacity-100 translate-y-0 sm:scale-100" leave-active-class="ease-in duration-200"
                        leave-class="opacity-100 translate-y-0 sm:scale-100"
                        leave-to-class="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95">

                <div class="bg-white rounded-lg overflow-hidden shadow-xl transform transition-all sm:max-w-xs sm:w-full"
                     role="dialog" aria-modal="true" aria-labelledby="modal-headline">
                    <div class="bg-white p-8 ">

                        <!--Logo-->
                        <div class="text-center">
                            <svg
                                class="mx-auto h-8 mb-3 text-blue-700 hover:text-blue-500 transform duration-75 hover:rotate-45"
                                viewBox="0 0 229 229">
                                <path
                                    d="M183.4 45.9l-26.5 26.5c.7.5 1.2 1 1.6 1.6 15.4 15.3 23.2 33.8 23.2 55.5 0 21.7-7.7 40.2-23.2 55.5-15.3 15.3-33.8 23-55.3 23-21.7 0-40.2-7.7-55.5-23l-1.9-1.9 26.5-26.3c-.5-.5-1-1.1-1.5-1.8-15.3-15.3-23-33.8-23-55.5s7.7-40.2 23-55.6c15.3-15.3 33.8-23 55.5-23 21.6 0 40.1 7.7 55.5 23 .3.7.9 1.4 1.6 2zM72.1 156.8l84.6-84.6.2.2-84.5 84.4h-.3z"
                                    clip-rule="evenodd" fill="none" stroke="CurrentColor" stroke-width="16"
                                    stroke-miterlimit="3"/>
                            </svg>

							<h3 class="text-lg leading-6 font-medium text-gray-900" id="modal-headline">
								Proceed as Guest
							</h3>
							<div class="mt-5">
								<button @click="saveAsGuest()" type="submit" class="group relative w-full flex justify-center py-2 px-4 border border-transparent text-sm leading-5 font-medium rounded-md text-white bg-gray-800 hover:bg-blue-700 focus:outline-none focus:border-blue-600 focus:ring-indigo active:bg-blue-900 transition duration-150 ease-in-out">
									<span class="absolute left-0 inset-y-0 flex items-center pl-3">
										<svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5 transition ease-in-out duration-150" fill="currentColor" viewBox="0 0 24 24" stroke="currentColor">
											<path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M16 7a4 4 0 11-8 0 4 4 0 018 0zM12 14a7 7 0 00-7 7h14a7 7 0 00-7-7z" />
										</svg>
									</span>
									Continue
								</button>
							</div>


							<div class="my-4 flex items-center justify-center">
							<hr class="my-3 w-full">
							<div class="absolute px-2 bg-white text-xs text-gray-500">OR</div>
							</div>




                            <h3 class="text-lg leading-6 font-medium text-gray-900" id="modal-headline-account">
                                Create An Account
                            </h3>

<!--                            <p class="text-xs text-gray-500 mt-1 mb-2">-->
<!--                                Create an account for additional features and privacy.-->
<!--                            </p>-->

							<!-- Feature list -->
							<div class="pb-3 pt-2">
								<div class="flex mt-1">
									<svg xmlns="http://www.w3.org/2000/svg" class="h-4 w-4 text-green-500" viewBox="0 0 20 20" fill="currentColor">
										<path fill-rule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z" clip-rule="evenodd" />
									</svg>
									<p class="text-xs ml-2 text-gray-700">Keep scenario completely private</p>
								</div>
								<div class="flex mt-1">
									<svg xmlns="http://www.w3.org/2000/svg" class="h-4 w-4 text-green-500" viewBox="0 0 20 20" fill="currentColor">
										<path fill-rule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z" clip-rule="evenodd" />
									</svg>
									<p class="text-xs ml-2 text-gray-700">Automated stock price updates</p>
								</div>
								<div class="flex mt-1">
									<svg xmlns="http://www.w3.org/2000/svg" class="h-4 w-4 text-green-500" viewBox="0 0 20 20" fill="currentColor">
										<path fill-rule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z" clip-rule="evenodd" />
									</svg>
									<p class="text-xs ml-2 text-gray-700">Additional filters, pivots, and aggregations</p>
								</div>

							</div>

                        </div>

                            <div class="pb-3">
                                <label class="block text-xs leading-5 font-medium text-gray-700">
                                    Name
                                </label>
                                <input v-model="name" required
                                       class="h-8 w-full appearance-none bg-transparent text-gray-700 py-1 px-2 leading-tight focus:outline-none border border-gray-300 rounded-md text-sm focus:border-blue-700"
                                       type="text">
                            </div>
                            <div class="pb-3">
                                <label class="block text-xs leading-5 font-medium text-gray-700">
                                    Email
                                </label>
                                <input v-model="email" required
                                       class="h-8 w-full appearance-none bg-transparent text-gray-700 py-1 px-2 leading-tight focus:outline-none border border-gray-300 rounded-md text-sm focus:border-blue-700"
                                       type="email">
                            </div>
                            <div class="">
                                <label class="block text-xs leading-5 font-medium text-gray-700">
                                    Password
                                </label>
                                <input v-model="password" required
                                       v-on:keyup.enter="register()"
                                       class="h-8 w-full appearance-none bg-transparent text-gray-700 py-1 px-2 leading-tight focus:outline-none border border-gray-300 rounded-md text-sm focus:border-blue-700"
                                       type="password">
                            </div>
<!--                            <div class="pb-3">-->
<!--                                <label class="block text-xs leading-5 font-medium text-gray-700">-->
<!--                                    Confirm Password-->
<!--                                </label>-->
<!--                                <input v-model="confirmed" required-->
<!--                                       class="h-8 appearance-none bg-transparent text-gray-700 py-1 px-2 leading-tight focus:outline-none border border-gray-300 rounded-md text-sm focus:border-blue-700"-->
<!--                                       type="password">-->
<!--                            </div>-->


                        <div v-if="errors" class="flex items-center p-3 bg-red-200 w-full rounded-md mb-5 text-xs text-red-900">
                            <div class="w-5 h-5 mr-3 text-red-800">
                                <svg
                                     fill="none" stroke-linecap="round" stroke-linejoin="round"
                                     stroke-width="2" viewBox="0 0 24 24" stroke="currentColor">
                                    <path
                                        d="M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z"></path>
                                </svg>
                            </div>
                            <div>
                                <div v-for="error in errors">{{ error[0] }}</div>
                            </div>

                        </div>

<!--                        <hr>-->
                        <div class="mt-5">
                            <button @click="register()" type="submit" class="group relative w-full flex justify-center py-2 px-4 border border-transparent text-sm leading-5 font-medium rounded-md text-white bg-gray-800 hover:bg-blue-700 focus:outline-none focus:border-blue-600 focus:ring-indigo active:bg-blue-900 transition duration-150 ease-in-out">
                                <span class="absolute left-0 inset-y-0 flex items-center pl-3">
                                    <svg class="h-5 w-5 transition ease-in-out duration-150" fill="currentColor" viewBox="0 0 20 20">
                                        <path fill-rule="evenodd" d="M5 9V7a5 5 0 0110 0v2a2 2 0 012 2v5a2 2 0 01-2 2H5a2 2 0 01-2-2v-5a2 2 0 012-2zm8-2v2H7V7a3 3 0 016 0z" clip-rule="evenodd" />
                                    </svg>
                                </span>
                                Register
                            </button>
                        </div>
                        <div class="text-center -mb-4">
                            <a @click="$emit('close')" class="mt-2 text-xs text-blue-600 cursor-pointer">
                                Go back
                            </a>
                        </div>



                    </div>


<!--                    <div class="bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">-->
<!--                        <span @click="register()"-->
<!--                              class="flex w-full rounded-md shadow-sm sm:ml-3 sm:w-auto">-->
<!--                            <button type="button"-->
<!--                                    class="inline-flex justify-center w-full rounded-md border border-transparent px-4 py-2 bg-red-600 text-base leading-6 font-medium text-white shadow-sm hover:bg-red-500 focus:outline-none focus:border-red-700 focus:ring-red transition ease-in-out duration-150 sm:text-sm sm:leading-5">-->
<!--                                Register-->
<!--                            </button>-->
<!--                        </span>-->
<!--                        <span @click="$emit('close')"-->
<!--                              class="mt-3 flex w-full rounded-md shadow-sm sm:mt-0 sm:w-auto">-->
<!--                            <button type="button"-->
<!--                                    class="inline-flex justify-center w-full rounded-md border border-gray-300 px-4 py-2 bg-white text-base leading-6 font-medium text-gray-700 shadow-sm hover:text-gray-500 focus:outline-none focus:border-blue-300 focus:ring-blue transition ease-in-out duration-150 sm:text-sm sm:leading-5">-->
<!--                                Don't Save-->
<!--                            </button>-->
<!--                        </span>-->
<!--                    </div>-->
                </div>
            </transition>
        </div>
    </div>
</template>

<script>
    export default {
        name: "registerGuestModal",
        data() {
            return {
                name: '',
                email: '',
                password: '',

                errors: null,
            }
        },
        props: {
            open: {type: Boolean , default: false},
            scenario: null,
            meta:null,
            isnew: null
        },
        methods: {
            register() {
                this.errors = null;
                this.$emit('processing');
                axios.post('/register', {
                    // pass the scenario ID of the item selected from the list based on its index
                    name: this.name,
                    email: this.email,
                    password: this.password,
                    password_confirmation: this.password,
                })
                    .then(response => {
						this.$emit('isNowRegistered');
                        this.$emit('save');
                    })
                    .catch(error => {
                        this.errors = error.response.data.errors
                        // window.location.href = '/dashboard'
                        this.$emit('stopprocessing');
                    });
            },
			saveAsGuest() {
				this.errors = null;
				this.$emit('processing');
				this.$emit('save');
			}
        },


    }
</script>

<template>
	<div>

		<div class="fixed z-20 bottom-0 inset-x-0 px-4 pb-4 sm:inset-0 sm:flex sm:items-center sm:justify-center">


			<div class="fixed inset-0 transition-opacity">
				<div class="absolute inset-0 bg-gray-900 opacity-75"></div>
			</div>

			<div class="bg-white rounded-lg overflow-hidden shadow-xl transform transition-all sm:max-w-2xl sm:w-full"
				 role="dialog" aria-modal="true" aria-labelledby="modal-headline">
				<div class="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
					<div class="sm:flex sm:items-start">
						<div
							class="mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full sm:mx-0 sm:h-10 sm:w-10">

							<!-- Radar Icon --->
							<svg class="fill-current text-gray-800" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
								<path d="M231 22c-19 2-44 8-62 15A228 228 0 0044 152a235 235 0 10446 119v-12l-1 8c-3 16-5 26-10 40-11 33-27 60-51 85-9 10-33 31-47 41a221 221 0 01-156 38 217 217 0 11200-349l-16 12-16 11-3-4A174 174 0 0083 257c1 38 12 69 34 99a176 176 0 00161 68 175 175 0 00150-149v-6l-3 10c-17 44-48 81-90 106a155 155 0 1142-229l-20 14-19 14-7-7a105 105 0 1022 119c1-4 1-4-7 4-21 20-52 34-80 38a87 87 0 1151-148l5 5-3 2-20 14-17 13-5-3c-18-12-43 0-47 21-3 19 11 36 31 36 6 0 8 0 13-3 11-5 18-16 18-27v-4l20-9a1633 1633 0 0138-19l36-17a3274 3274 0 00101-48l-29-50c-1-1-3 0-9 5l-8 5-4-4-13-14a233 233 0 00-193-71z"/>
							</svg>

						</div>
						<div class="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
							<h3 class="text-lg leading-6 font-medium text-gray-900 sm:h-10 sm:flex items-center" id="modal-headline">
								Autopilot Options
							</h3>
							<div class="mt-2">
								<p class="text-sm leading-5 text-gray-500 mb-4">
									These options allow you to precisely control how the autopilot trading functionality will operate for this account.
								</p>




								<div class="mb-5">
									<div class="-my-2 py-2 sm:-mx-6 sm:px-6 lg:-mx-8 lg:px-8">
										<div class="align-middle inline-block min-w-full shadow rounded-lg bg-gray-800">
											<div class="sm:flex justify-between pl-5 pr-3 py-3">

												<div class="flex items-center">

													<span class="text-gray-200 pr-2 font-normal self-center">Master Switch</span>

													<!-- Pro plan banner -->
													<a v-if="!this.subscribed" :href="'/billing'"
													   class="px-2 rounded-full flex items-center bg-gray-900"
													   v-tooltip="{ content: 'This is a PRO feature. Subscribe to utilize it!', class: 'text-xs text-gray-300 fade-in' }"
													>

														<svg xmlns="http://www.w3.org/2000/svg" class="w-5 h-5 p-1 text-red-500" viewBox="0 0 20 20" fill="currentColor">
															<path d="M11 17a1 1 0 001.447.894l4-2A1 1 0 0017 15V9.236a1 1 0 00-1.447-.894l-4 2a1 1 0 00-.553.894V17zM15.211 6.276a1 1 0 000-1.788l-4.764-2.382a1 1 0 00-.894 0L4.789 4.488a1 1 0 000 1.788l4.764 2.382a1 1 0 00.894 0l4.764-2.382zM4.447 8.342A1 1 0 003 9.236V15a1 1 0 00.553.894l4 2A1 1 0 009 17v-5.764a1 1 0 00-.553-.894l-4-2z" />
														</svg>

														<span class="text-transparent bg-clip-text bg-gradient-to-br from-pink-400 to-red-600 font-extralight tracking-wide text-xs">PRO</span>

													</a>
												</div>

												<div class="flex">

													<!-- Active/ Inactive tile-->
													<div style="font-size: 60%;"
														 class="px-2 mr-3 self-center text-xs leading-4 font-semibold rounded-full"
														 :class="[settingsProp.autopilot_active ? 'bg-green-100 text-green-800' : 'bg-red-100 text-red-800']">
														{{ settingsProp.autopilot_active ? 'ACTIVE' : 'INACTIVE' }}
													</div>

													<!-- toggle -->
													<div class="flex">
														<div class="self-center">
															<input v-model="autopilot_active" :disabled="!this.subscribed"
																   id="cmn-toggle" type="checkbox" name="trade-mode"
																   class="cmn-toggle-navy cmn-toggle"
																   >
															<label for="cmn-toggle" class="m-0" :class="[this.subscribed ? 'cursor-pointer' : 'cursor-not-allowed']"></label>
														</div>
													</div>

												</div>
											</div>
										</div>
									</div>
								</div>

								<!-- Rebalance Frequency -->
								<div class="sm:flex mb-5">

									<!--Dropdown-->
									<div class="relative inline-block text-left w-full sm:w-auto">
										<label class="block text-sm leading-5 font-medium text-gray-700">
											Rebalance Frequency
										</label>
										<div class="flex">
											<span class="rounded-md shadow-sm w-full">
												<button @click="rebalanceFrequencyOpen = !rebalanceFrequencyOpen" type="button"
														class="inline-flex justify-between w-full sm:w-40 rounded-md border border-gray-300 px-4 py-2 bg-white text-sm leading-5 font-medium text-gray-700 hover:text-gray-500 focus:outline-none focus:border-blue-300 focus:ring-blue active:bg-gray-50 active:text-gray-800 transition ease-in-out duration-150">
													<span>{{ capitalize(rebalanceFrequency) }}</span>
													<svg class="-mr-1 ml-2 h-5 w-5" fill="currentColor" viewBox="0 0 20 20">
														<path fill-rule="evenodd"
															  d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
															  clip-rule="evenodd"/>
													</svg>
												</button>
											</span>
											<span class="sm:hidden ml-2">
												<button
													@click="rebalanceFrequencyHelpVisible = !rebalanceFrequencyHelpVisible"
													type="button"
													class="inline-flex justify-center h-full rounded-md border border-gray-300 bg-white text-gray-500 shadow-sm hover:text-gray-700 focus:outline-none focus:border-gray-500 focus:ring-gray transition ease-in-out duration-150">
													<!-- i icon info -->
													<svg v-show="!rebalanceFrequencyHelpVisible"
														 class="w-5 h-5 m-2 self-center"
														 xmlns="http://www.w3.org/2000/svg" fill="none"
														 viewBox="0 0 24 24" stroke="currentColor">
														<path stroke-linecap="round" stroke-linejoin="round"
															  stroke-width="2"
															  d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"/>
													</svg>
													<!-- arrow up close -->
													<svg v-show="rebalanceFrequencyHelpVisible"
														 class="w-5 h-5 m-2 self-center"
														 xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"
														 fill="currentColor">
														<path fill-rule="evenodd"
															  d="M4.293 15.707a1 1 0 010-1.414l5-5a1 1 0 011.414 0l5 5a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414 0zm0-6a1 1 0 010-1.414l5-5a1 1 0 011.414 0l5 5a1 1 0 01-1.414 1.414L10 5.414 5.707 9.707a1 1 0 01-1.414 0z"
															  clip-rule="evenodd"/>
													</svg>
												</button>
											</span>
										</div>


										<!--Full width button to close if you click outside-->
										<button v-if="rebalanceFrequencyOpen" @click="rebalanceFrequencyOpen = false" tabindex="-1"
												class="fixed inset-0 h-full w-full cursor-default"></button>

										<transition enter-active-class="transition ease-out duration-100"
													enter-class="transform opacity-0 scale-95"
													enter-to-class="transform opacity-100 scale-100"
													leave-active-class="transition ease-in duration-75"
													leave-class="transform opacity-100 scale-100"
													leave-to-class="transform opacity-0 scale-95">

											<div v-if="rebalanceFrequencyOpen"
												 class="z-20 origin-top-right absolute right-0 mt-2 w-40 rounded-md shadow-lg z-10">
												<div class="rounded-md bg-white ring-1 ring-black ring-opacity-5">
													<div class="py-1">
														<button v-for="(option,index) in rebalanceFrequencyOptions" :key=index
																@click="(rebalanceFrequency = capitalize(option)) && (rebalanceFrequencyOpen = false)"
																class="relative block px-4 py-2 text-sm leading-5 text-gray-700 text-left w-full hover:bg-gray-100 hover:text-gray-900 focus:outline-none focus:bg-gray-100 focus:text-gray-900">
															{{ capitalize(option) }}
															<span v-show="capitalize(option) === capitalize(rebalanceFrequency)"
																  class="absolute inset-y-0 right-0 flex items-center pr-2">
																<svg class="h-5 w-5 text-gray-700" fill="none"
																	 stroke-linecap="round" stroke-linejoin="round"
																	 stroke-width="2" viewBox="0 0 24 24"
																	 stroke="currentColor">
																	<path d="M5 13l4 4L19 7"></path>
																</svg>
															</span>
														</button>
													</div>
												</div>
											</div>
										</transition>
									</div>
									<!--End Dropdown-->

									<!--Help text-->
									<transition name="slide">
										<div v-if="rebalanceFrequencyHelpVisible">
											<div
												class="text-xs ml-2 mt-2 sm:mt-5 text-gray-600 tracking-tight text-left">
												<span>The autopilot engine will attempt to rebalance (if necessary) your portfolio on a {{ rebalanceFrequency }} basis.</span>
											</div>
										</div>
									</transition>

								</div> <!--Close flex-->

								<!-- Day of Week Preference -->
								<div class="sm:flex mb-5">

									<!--Dropdown-->
									<div class="relative inline-block text-left w-full sm:w-auto">
										<label class="block text-sm leading-5 font-medium text-gray-700">
											Day of Week Preference
										</label>
										<div class="flex">
											<span class="rounded-md shadow-sm w-full">
												<button @click="dowPreferenceOpen = !dowPreferenceOpen"
														type="button"
														class="inline-flex justify-between w-full sm:w-40 rounded-md border border-gray-300 px-4 py-2 bg-white text-sm leading-5 font-medium text-gray-700 hover:text-gray-500 focus:outline-none focus:border-blue-300 focus:ring-blue active:bg-gray-50 active:text-gray-800 transition ease-in-out duration-150">
													<span>{{ capitalize(dowPreference) }}</span>
													<svg class="-mr-1 ml-2 h-5 w-5" fill="currentColor"
														 viewBox="0 0 20 20">
														<path fill-rule="evenodd"
															  d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
															  clip-rule="evenodd"/>
													</svg>
												</button>
											</span>
											<span class="sm:hidden ml-2">
												<button
													@click="dowPreferenceHelpVisible = !dowPreferenceHelpVisible"
													type="button"
													class="inline-flex justify-center h-full rounded-md border border-gray-300 bg-white text-gray-500 shadow-sm hover:text-gray-700 focus:outline-none focus:border-gray-500 focus:ring-gray transition ease-in-out duration-150">
													<!-- i icon info -->
													<svg v-show="!dowPreferenceHelpVisible"
														 class="w-5 h-5 m-2 self-center"
														 xmlns="http://www.w3.org/2000/svg" fill="none"
														 viewBox="0 0 24 24" stroke="currentColor">
														<path stroke-linecap="round" stroke-linejoin="round"
															  stroke-width="2"
															  d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"/>
													</svg>
													<!-- arrow up close -->
													<svg v-show="dowPreferenceHelpVisible"
														 class="w-5 h-5 m-2 self-center"
														 xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"
														 fill="currentColor">
														<path fill-rule="evenodd"
															  d="M4.293 15.707a1 1 0 010-1.414l5-5a1 1 0 011.414 0l5 5a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414 0zm0-6a1 1 0 010-1.414l5-5a1 1 0 011.414 0l5 5a1 1 0 01-1.414 1.414L10 5.414 5.707 9.707a1 1 0 01-1.414 0z"
															  clip-rule="evenodd"/>
													</svg>
												</button>
											</span>
										</div>


										<!--Full width button to close if you click outside-->
										<button v-if="dowPreferenceOpen" @click="dowPreferenceOpen = false"
												tabindex="-1"
												class="fixed inset-0 h-full w-full cursor-default"></button>

										<transition enter-active-class="transition ease-out duration-100"
													enter-class="transform opacity-0 scale-95"
													enter-to-class="transform opacity-100 scale-100"
													leave-active-class="transition ease-in duration-75"
													leave-class="transform opacity-100 scale-100"
													leave-to-class="transform opacity-0 scale-95">

											<div v-if="dowPreferenceOpen"
												 class="z-20 origin-top-right absolute right-0 mt-2 w-40 rounded-md shadow-lg z-10">
												<div class="rounded-md bg-white ring-1 ring-black ring-opacity-5">
													<div class="py-1">
														<button v-for="(option,index) in dowPreferenceOptions" :key=index
																@click="(dowPreference = capitalize(option)) && (dowPreferenceOpen = false)"
																class="relative block px-4 py-2 text-sm leading-5 text-gray-700 text-left w-full hover:bg-gray-100 hover:text-gray-900 focus:outline-none focus:bg-gray-100 focus:text-gray-900">
															{{ capitalize(option) }}
															<span v-show="capitalize(option) === capitalize(dowPreference)"
																  class="absolute inset-y-0 right-0 flex items-center pr-2">
																<svg class="h-5 w-5 text-gray-700" fill="none"
																	 stroke-linecap="round" stroke-linejoin="round"
																	 stroke-width="2" viewBox="0 0 24 24"
																	 stroke="currentColor">
																	<path d="M5 13l4 4L19 7"></path>
																</svg>
															</span>
														</button>
													</div>
												</div>
											</div>
										</transition>
									</div>
									<!--End Dropdown-->

									<!--Help text-->
									<transition name="slide">
										<div v-if="dowPreferenceHelpVisible">
											<div
												class="text-xs ml-2 mt-2 sm:mt-5 text-gray-600 tracking-tight text-left">
												<span>Autopilot will prepare corrective trades (if any) the weekend prior to submitting them on {{ capitalize(dowPreference) }}'s.</span>
											</div>
										</div>
									</transition>

								</div> <!--Close flex-->

								<!-- notice threshold -->
								<div class="sm:flex mb-3">
									<div class="w-full sm:w-40">
										<div class="relative inline-block text-left w-full sm:w-auto">
											<label class="block text-sm leading-5 font-medium text-gray-700">
												Notice Period
											</label>

											<div class="flex">
												<div class="sm:w-40">
													<div
														class="h-10 relative rounded-md shadow-sm border border-gray-300 flex"
														:class="[noticeThresholdActive === 'NoNotice' ? 'bg-gray-100' : '']">

														<div class="relative sm:w-1/2 h-10">
															<div
																class="absolute inset-y-0 right-0 flex items-center pointer-events-none mr-6">
																<span class="sm:text-sm sm:leading-5"
																	  :class="[noticeThresholdActive === 'NoNotice' ? 'text-gray-300' : 'text-gray-500']"
																	  style="font-size: 70%">
																	Days
																</span>
															</div>
															<input v-model="noticeThreshold" placeholder="3"
																   @click="(noticeThresholdActive = 'Threshold') && (noNotice = false)"
																   :class="[noticeThresholdActive === 'Threshold' ? 'border border-blue-700 -mt-px -ml-px rounded-l-md' : 'text-gray-300']"
																   class="form-input block h-10 w-full sm:w-20 sm:text-sm sm:leading-5 bg-transparent text-center pr-10">
														</div>

														<label for="noNoticeCheckbox" @click="noticeThresholdActive = 'NoNotice'"
															   :class="[noticeThresholdActive === 'NoNotice' ? 'border-blue-700 bg-white text-gray-800' : 'border-gray-300 bg-gray-100 text-gray-300']"
															   class="text-sm border -my-px -mr-px rounded-r-md w-full h-10 cursor-pointer self-center py-2 text-center">
															No Notice
														</label>
														<input v-model="noNotice" type="checkbox" id="noNoticeCheckbox"
															   class="absolute" style="left: -100vw">
													</div>

												</div>


												<span class="sm:hidden ml-2">
													<button
														@click="noticeThresholdHelpVisible = !noticeThresholdHelpVisible"
														type="button"
														class="inline-flex justify-center h-full rounded-md border border-gray-300 bg-white text-gray-500 shadow-sm hover:text-gray-700 focus:outline-none focus:border-gray-500 focus:ring-gray transition ease-in-out duration-150">
														<!-- i icon info -->
														<svg v-show="!noticeThresholdHelpVisible"
															 class="w-5 h-5 m-2 self-center"
															 xmlns="http://www.w3.org/2000/svg" fill="none"
															 viewBox="0 0 24 24" stroke="currentColor">
															<path stroke-linecap="round" stroke-linejoin="round"
																  stroke-width="2"
																  d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"/>
														</svg>
														<!-- arrow up close -->
														<svg v-show="noticeThresholdHelpVisible"
															 class="w-5 h-5 m-2 self-center"
															 xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"
															 fill="currentColor">
															<path fill-rule="evenodd"
																  d="M4.293 15.707a1 1 0 010-1.414l5-5a1 1 0 011.414 0l5 5a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414 0zm0-6a1 1 0 010-1.414l5-5a1 1 0 011.414 0l5 5a1 1 0 01-1.414 1.414L10 5.414 5.707 9.707a1 1 0 01-1.414 0z"
																  clip-rule="evenodd"/>
														</svg>
													</button>
												</span>
											</div>

										</div>

									</div>
									<!--Help text-->
									<transition name="slide">
										<div v-if="noticeThresholdHelpVisible">
											<div
												class="text-xs ml-2 mt-1 sm:mt-5 text-gray-600 tracking-tight text-left">
												<span v-if="noticeThresholdActive === 'NoNotice'">
													Recommended trades will be placed immediately once identified, you will NOT be notified prior to trades. Consider this a "hands off" approach.
												</span>
												<span v-else>
													 When recommended trades are identified, you will be notified via email {{ noticeThreshold }} {{ (noticeThreshold == 1) ? "day" : "days"}} before trades are placed.
												</span>
											</div>
										</div>
									</transition>

								</div> <!-- End notice threshold -->

								<!-- confirmation -->
								<div class="sm:flex mb-3">
									<div class="w-full sm:w-40">
										<div class="relative inline-block text-left w-full sm:w-auto">
											<label class="block text-sm leading-5 font-medium text-gray-700">
												Confirm before trading?
											</label>

											<div class="flex">
												<div class="sm:w-40 w-full">
													<div class="h-10 relative rounded-md shadow-sm border border-gray-300 flex">

														<div class="flex flex-grow w-1/2 h-10 -my-px">


															<label for="confirmationYes" @click="confirmationActive = true"
																   :class="[confirmationActive === true ? 'border-blue-700 bg-white text-gray-800' : 'border-gray-300 bg-gray-100 text-gray-300']"
																   class="text-sm border -my-px -ml-px rounded-l-md w-full h-10 cursor-pointer self-center py-2 text-center">
																Yes
															</label>
															<input type="checkbox" id="confirmationYes"
																   class="absolute" style="left: -100vw">


															<label for="confirmationNo" @click="confirmationActive = false"
																   :class="[confirmationActive === false ? 'border-blue-700 bg-white text-gray-800' : 'border-gray-300 bg-gray-100 text-gray-300']"
																   class="text-sm border -my-px -mr-px rounded-r-md w-full h-10 cursor-pointer self-center py-2 text-center">
																No
															</label>
															<input type="checkbox" id="confirmationNo"
																   class="absolute" style="left: -100vw">
														</div>

												</div>
												</div>


												<span class="sm:hidden ml-2">
													<button
														@click="confirmationHelpVisible = !confirmationHelpVisible"
														type="button"
														class="inline-flex justify-center h-full rounded-md border border-gray-300 bg-white text-gray-500 shadow-sm hover:text-gray-700 focus:outline-none focus:border-gray-500 focus:ring-gray transition ease-in-out duration-150">
														<!-- i icon info -->
														<svg v-show="!confirmationHelpVisible"
															 class="w-5 h-5 m-2 self-center"
															 xmlns="http://www.w3.org/2000/svg" fill="none"
															 viewBox="0 0 24 24" stroke="currentColor">
															<path stroke-linecap="round" stroke-linejoin="round"
																  stroke-width="2"
																  d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"/>
														</svg>
														<!-- arrow up close -->
														<svg v-show="confirmationHelpVisible"
															 class="w-5 h-5 m-2 self-center"
															 xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"
															 fill="currentColor">
															<path fill-rule="evenodd"
																  d="M4.293 15.707a1 1 0 010-1.414l5-5a1 1 0 011.414 0l5 5a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414 0zm0-6a1 1 0 010-1.414l5-5a1 1 0 011.414 0l5 5a1 1 0 01-1.414 1.414L10 5.414 5.707 9.707a1 1 0 01-1.414 0z"
																  clip-rule="evenodd"/>
														</svg>
													</button>
												</span>
											</div>

										</div>

									</div>
									<!--Help text-->
									<transition name="slide">
										<div v-if="confirmationHelpVisible">
											<div
												class="text-xs ml-2 mt-1 sm:mt-5 text-gray-600 tracking-tight text-left">
												<span v-if="confirmationActive && noticeThresholdActive === 'Threshold'">
													Your notice email will require you to confirm trades before they are processed.
												</span>
												<span v-else-if="confirmationActive && noticeThresholdActive === 'NoNotice'">
													You will receive a confirmation email immediately as trades are ready; once you confirm, they will be processed.
												</span>
												<span v-else>
													Trades will be made automatically without any confirmation or prior notice. You will still be notified, but no confirmation is needed for trades to be made.
												</span>
											</div>
										</div>
									</transition>

								</div> <!-- End confirmation -->

								<!--Notification Warning-->
								<div v-if="notificationWarning"
									 class="flex items-center p-3 bg-red-200 w-full rounded-lg mt-5 text-xs text-red-900">
									<svg class="w-20 mr-3 text-red-800" fill="none" stroke-linecap="round"
										 stroke-linejoin="round" stroke-width="2" viewBox="0 0 24 24"
										 stroke="currentColor">
										<path
											d="M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z"></path>
									</svg>
									<span class="text-left">
										You have selected "No Notice" period with no confirmation before trading. This selection means that when trades are identified, they will be made immediately without prior notice. You may wish to include a small notice period instead.
									</span>
								</div> <!--End Notification Warning-->


							</div>
						</div>
					</div>
				</div>
				<div class="bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
					<span @click="updateAutopilotOptions()" class="flex w-full rounded-md shadow-sm sm:ml-3 sm:w-auto">
						<button type="button"
								class="inline-flex justify-center w-full rounded-md border border-transparent px-4 py-2 bg-blue-800 text-base leading-6 font-medium text-white shadow-sm hover:bg-blue-600 focus:outline-none focus:border-blue-900 focus:ring-red transition ease-in-out duration-150 sm:text-sm sm:leading-5">
							Save
						</button>
					</span>
					<span @click="$emit('close')" class="mt-3 flex w-full rounded-md shadow-sm sm:mt-0 sm:w-auto">
						<button type="button"
								class="inline-flex justify-center w-full rounded-md border border-gray-300 px-4 py-2 bg-white text-base leading-6 font-medium text-gray-700 shadow-sm hover:text-gray-500 focus:outline-none focus:border-blue-300 focus:ring-blue transition ease-in-out duration-150 sm:text-sm sm:leading-5">
							Cancel
						</button>
					</span>
				</div>
			</div>

		</div>

	</div>
</template>

<script>
export default {
	name: "autoPilotOptionsModal",
	data() {
		return { // set some default values as well as options available
			rebalanceFrequency: 'Monthly',
			rebalanceFrequencyOpen: false,
			rebalanceFrequencyOptions: ['Weekly','Bi-Weekly','Monthly','Bi-Monthly','Quarterly','Semi-Annually','Annual'],

			dowPreference: 'Tuesday',
			dowPreferenceOpen: false,
			dowPreferenceOptions: ['Monday','Tuesday','Wednesday','Thursday','Friday'],

			noticeThreshold: 3,
			noNotice: false,
			noticeThresholdActive: 'Threshold',

			confirmationActive: true,

			autopilot_active: false,

			rebalanceFrequencyHelpVisible: (window.innerWidth > 640),
			dowPreferenceHelpVisible: (window.innerWidth > 640),
			noticeThresholdHelpVisible: (window.innerWidth > 640),
			confirmationHelpVisible: (window.innerWidth > 640),
		}
	},
	computed: {
		calculateTheNotice() { // convert the notice into a number
			if(this.noticeThresholdActive === 'NoNotice') {
				return 0;
			} else {
				return this.noticeThreshold;
			}
		},
		notificationWarning() {
			// if you have both no notice and no confirmation, show a warning
			return (this.noNotice && !this.confirmationActive);
		},

	},
	props: {
		open: {type: Boolean, default: false},
		settingsProp: null,
		subscribed: {default: false},
	},
	created() {

		this.autopilot_active = !!this.settingsProp.autopilot_active;
		// set values based on options passed in
		// frequency
		this.rebalanceFrequency = this.settingsProp.autopilot_options.frequency;
		// day of week
		this.dowPreference = this.settingsProp.autopilot_options.dow;

		// notice
		if (this.settingsProp.autopilot_options.notice === 0) {
			this.noNotice = true;
			this.noticeThresholdActive = "NoNotice";
			this.noticeThreshold = 3; // this isn't used, but it'll be a good default if they switch back
		} else {
			this.noNotice = false;
			this.noticeThresholdActive = "Threshold";
			this.noticeThreshold = this.settingsProp.autopilot_options.notice;
		}

		// confirm  ... need double exclamation to convert 1/0 to true/false
		this.confirmationActive = !!this.settingsProp.autopilot_options.confirm;

	},

	methods: {
		capitalize(string) {
			if (typeof string !== 'string') {
				return ''
			} else {
				return string.charAt(0).toUpperCase() + string.slice(1);
			}
		},
		updateAutopilotOptions() {
			axios.post('/updateAutopilotOptions', {
				scenarioID: this.settingsProp.id,
				userID: this.settingsProp.user_id,
				frequency: this.rebalanceFrequency,
				dow: this.dowPreference,
				notice: this.calculateTheNotice,
				confirm: this.confirmationActive,
				isActive: this.autopilot_active,
			})
				.then(response => {
					location.reload();
					this.$emit('close');
				})
				.catch(error => {
					location.reload();
					this.$emit('close');
				});
		}
	},


}
</script>

<style>
.slide-enter-active {
	-moz-transition-duration: 0.3s;
	-webkit-transition-duration: 0.3s;
	-o-transition-duration: 0.3s;
	transition-duration: 0.3s;
	-moz-transition-timing-function: ease-in;
	-webkit-transition-timing-function: ease-in;
	-o-transition-timing-function: ease-in;
	transition-timing-function: ease-in;
}

.slide-leave-active {
	-moz-transition-duration: 0.3s;
	-webkit-transition-duration: 0.3s;
	-o-transition-duration: 0.3s;
	transition-duration: 0.3s;
	-moz-transition-timing-function: cubic-bezier(0, 1, 0.5, 1);
	-webkit-transition-timing-function: cubic-bezier(0, 1, 0.5, 1);
	-o-transition-timing-function: cubic-bezier(0, 1, 0.5, 1);
	transition-timing-function: cubic-bezier(0, 1, 0.5, 1);
}

.slide-enter-to, .slide-leave {
	max-height: 100px;
	overflow: hidden;
}

.slide-enter, .slide-leave-to {
	overflow: hidden;
	max-height: 0;
}
</style>

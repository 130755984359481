<template>
    <div v-if="visible">

        <div class="mb-5 max-w-xl">

            <div class="align-middle inline-block min-w-full shadow rounded-lg bg-teal-600">
                <div class="sm:flex p-4 text-white">
                    <div class="w-8 self-center">
                        <svg class="hidden sm:block w-6 text-white" fill="none" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" viewBox="0 0 24 24"
                             stroke="currentColor">
                            <path
                                d="M5 3v4M3 5h4M6 17v4m-2-2h4m5-16l2.286 6.857L21 12l-5.714 2.143L13 21l-2.286-6.857L5 12l5.714-2.143L13 3z"></path>
                        </svg>
                    </div>
                    <span class="sm:pl-2 text-sm leading-tight">
                        Create a scenario by adding rows for assets and cash, define a target percentage, and enter details about your current holdings.
                    </span>
                    <div class="flex whitespace-nowrap">
                        <span class="sm:pl-3 pt-2 sm:pt-0 self-center w-full sm:w-auto">
                            <button @click="$emit('loadexample')" type="button"
                                    class="inline-flex justify-center w-full rounded-md border border-gray-300 px-4 py-2 bg-white leading-6 font-medium text-gray-700 shadow-sm hover:text-gray-500 focus:outline-none focus:border-blue-300 focus:ring-blue transition ease-in-out duration-150 text-xs sm:leading-5">
                                <span>Show me an Example</span>
                                <svg class="w-4 h-4 ml-2 self-center" fill="none" stroke-linecap="round"
                                     stroke-linejoin="round" stroke-width="2" viewBox="0 0 24 24" stroke="currentColor">
                                    <path
                                        d="M9.663 17h4.673M12 3v1m6.364 1.636l-.707.707M21 12h-1M4 12H3m3.343-5.657l-.707-.707m2.828 9.9a5 5 0 117.072 0l-.548.547A3.374 3.374 0 0014 18.469V19a2 2 0 11-4 0v-.531c0-.895-.356-1.754-.988-2.386l-.548-.547z"></path>
                                </svg>
                            </button>
                        </span>
                    </div>
                </div>
            </div>

        </div>

    </div>
</template>

<script>
    export default {
        name: 'welcomeCard',
        data() {
            return {
                //
            }
        },
        props: {
            visible: {type: Boolean , default: false},
        }
    }
</script>

<template>
    <nav class="bg-gray-800">

		<transition enter-active-class="ease-out duration-200" enter-class="opacity-0" enter-to-class="opacity-100"
					leave-active-class="ease-in duration-100" leave-class="opacity-100" leave-to-class="opacity-0">
			<register-modal :open="registerModalOpen"
							:uuid="publicScenarioUUID"
							@close="registerModalOpen = false"
							@processing="processingSave = true"
							@stopprocessing="processingSave = false"
			>
			</register-modal>
		</transition>




		<!--Black Background for when processing-->
		<div v-if="processingSave" class="fixed z-40 bottom-0 inset-x-0 px-4 pb-4 sm:inset-0 sm:flex sm:items-center sm:justify-center">
			<div class="fixed inset-0 transition-opacity">
				<div class="absolute inset-0 bg-gray-900 opacity-75"></div>
			</div>
		</div>




        <div class="max-w-5xl mx-auto px-2 sm:px-6 lg:px-8">
            <div class="relative flex items-center justify-between h-16">
<!--                <div class="absolute inset-y-0 left-0 flex items-center sm:hidden">-->
<!--                    &lt;!&ndash; Mobile menu button&ndash;&gt;-->
<!--                    <button-->
<!--                        class="inline-flex items-center justify-center p-2 rounded-md text-gray-400 hover:text-white hover:bg-gray-700 focus:outline-none focus:bg-gray-700 focus:text-white transition duration-150 ease-in-out"-->
<!--                        aria-label="Main menu" aria-expanded="false">-->
<!--                        &lt;!&ndash; Icon when menu is closed. &ndash;&gt;-->
<!--                        &lt;!&ndash; Menu open: "hidden", Menu closed: "block" &ndash;&gt;-->
<!--                        <svg class="block h-6 w-6" stroke="currentColor" fill="none" viewBox="0 0 24 24">-->
<!--                            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"-->
<!--                                  d="M4 6h16M4 12h16M4 18h16"/>-->
<!--                        </svg>-->
<!--                        &lt;!&ndash; Icon when menu is open. &ndash;&gt;-->
<!--                        &lt;!&ndash; Menu open: "block", Menu closed: "hidden" &ndash;&gt;-->
<!--                        <svg class="hidden h-6 w-6" stroke="currentColor" fill="none" viewBox="0 0 24 24">-->
<!--                            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"-->
<!--                                  d="M6 18L18 6M6 6l12 12"/>-->
<!--                        </svg>-->
<!--                    </button>-->
<!--                </div>-->
                <div class="flex items-center justify-center sm:items-stretch sm:justify-start">
                    <div class="flex flex-shrink-0 items-center">
                        <a href="/dashboard">
                            <svg class="block h-8 text-white hover:text-teal-500 cursor-pointer transform duration-75 hover:rotate-45" viewBox="0 0 229 229">
                                <path
                                    d="M183.4 45.9l-26.5 26.5c.7.5 1.2 1 1.6 1.6 15.4 15.3 23.2 33.8 23.2 55.5 0 21.7-7.7 40.2-23.2 55.5-15.3 15.3-33.8 23-55.3 23-21.7 0-40.2-7.7-55.5-23l-1.9-1.9 26.5-26.3c-.5-.5-1-1.1-1.5-1.8-15.3-15.3-23-33.8-23-55.5s7.7-40.2 23-55.6c15.3-15.3 33.8-23 55.5-23 21.6 0 40.1 7.7 55.5 23 .3.7.9 1.4 1.6 2zM72.1 156.8l84.6-84.6.2.2-84.5 84.4h-.3z"
                                    clip-rule="evenodd" fill="none" stroke="CurrentColor" stroke-width="16" stroke-miterlimit="3"/>
                            </svg>
                        </a>

					</div>
					<!-- Create New Scenario Button -->
					<a href="/create" class="ml-8 flex px-3 py-2 rounded-md border border-gray-700 text-gray-400 hover:text-teal-500 hover:bg-gray-800 hover:ring transition duration-150 ease-in-out">

						<svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5 mr-2" viewBox="0 0 20 20" fill="currentColor">
							<path fill-rule="evenodd" d="M10 3a1 1 0 011 1v5h5a1 1 0 110 2h-5v5a1 1 0 11-2 0v-5H4a1 1 0 110-2h5V4a1 1 0 011-1z" clip-rule="evenodd" />
						</svg>
						<p class="text-sm font-medium" aria-current="page">New Scenario</p>
					</a>



					<!--                    <div class="hidden sm:block sm:ml-6">-->
<!--                        <div class="flex">-->
<!--                            <a href="#"-->
<!--                               class="px-3 py-2 rounded-md text-sm font-medium leading-5 text-white bg-gray-900 focus:outline-none focus:text-white focus:bg-gray-700 transition duration-150 ease-in-out">Dashboard</a>-->
<!--                            <a href="#"-->
<!--                               class="ml-4 px-3 py-2 rounded-md text-sm font-medium leading-5 text-gray-300 hover:text-white hover:bg-gray-700 focus:outline-none focus:text-white focus:bg-gray-700 transition duration-150 ease-in-out">Team</a>-->
<!--                            <a href="#"-->
<!--                               class="ml-4 px-3 py-2 rounded-md text-sm font-medium leading-5 text-gray-300 hover:text-white hover:bg-gray-700 focus:outline-none focus:text-white focus:bg-gray-700 transition duration-150 ease-in-out">Projects</a>-->
<!--                            <a href="#"-->
<!--                               class="ml-4 px-3 py-2 rounded-md text-sm font-medium leading-5 text-gray-300 hover:text-white hover:bg-gray-700 focus:outline-none focus:text-white focus:bg-gray-700 transition duration-150 ease-in-out">Calendar</a>-->
<!--                        </div>-->
<!--                    </div>-->
                </div>
                <div class="absolute inset-y-0 right-0 flex items-center pr-2 sm:static sm:inset-auto sm:ml-6 sm:pr-0">
                    <!--Bell Icon-->
<!--                    <button-->
<!--                        class="p-1 border-2 border-transparent text-gray-400 rounded-full hover:text-white focus:outline-none focus:text-white focus:bg-gray-700 transition duration-150 ease-in-out"-->
<!--                        aria-label="Notifications">-->
<!--                        <svg class="h-6 w-6" stroke="currentColor" fill="none" viewBox="0 0 24 24">-->
<!--                            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"-->
<!--                                  d="M15 17h5l-1.405-1.405A2.032 2.032 0 0118 14.158V11a6.002 6.002 0 00-4-5.659V5a2 2 0 10-4 0v.341C7.67 6.165 6 8.388 6 11v3.159c0 .538-.214 1.055-.595 1.436L4 17h5m6 0v1a3 3 0 11-6 0v-1m6 0H9"/>-->
<!--                        </svg>-->
<!--                    </button>-->

					<div v-if="validUser"
						 v-tooltip="{ html: 'marketHoursTooltip', class: 'fade-in' }"
						 style="font-size: 60%;" class="px-2 mr-2 self-center text-xs leading-4 rounded-full bg-gray-700 text-gray-400 flex items-center">
						<div class="w-2 h-2 mr-1 rounded-full"
							 :class="[isMarketOpen ? 'bg-green-500 animate-pulse' : 'bg-gray-600' ]"
							 >
						</div>
						Prices updated {{ this.$attrs.prices_updated }}
					</div>

					<div v-if="validUser" id="marketHoursTooltip" class="text-xs text-gray-300">
						<span v-if="isMarketOpen">
							The market is open, prices update every hour.
						</span>
						<span v-else>
							The market is closed. Prices will update when the market reopens.
						</span>
					</div>

					<!-- Register Button -->
					<button v-if="!validUser" @click="registerModalOpen = true" class="ml-3 flex px-3 py-2 rounded-md bg-gray-900 text-gray-400 hover:text-teal-500 hover:bg-gray-800 hover:ring transition duration-150 ease-in-out">

							<svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5 mr-2" viewBox="0 0 20 20" fill="currentColor">
								<path d="M8 9a3 3 0 100-6 3 3 0 000 6zM8 11a6 6 0 016 6H2a6 6 0 016-6zM16 7a1 1 0 10-2 0v1h-1a1 1 0 100 2h1v1a1 1 0 102 0v-1h1a1 1 0 100-2h-1V7z" />
							</svg>
							<p class="text-sm font-medium" aria-current="page">Register</p>

					</button>
					<a v-if="!validUser" href="/login" class="text-sm font-medium pl-3 text-white hover:text-teal-500" aria-current="page">Login</a>


                    <!-- Profile dropdown -->
                    <div v-if="validUser" class="ml-3 relative">
                        <div class="flex items-center cursor-pointer" @click="toggleUserDropdown()">
                            <button
                                    class="flex text-sm border-2 border-transparent rounded-full focus:outline-none focus:border-white transition duration-150 ease-in-out"
                                    id="user-menu" aria-label="User menu" aria-haspopup="true">
                                <img class="h-8 w-8 rounded-full"
                                     :src="user_data.avatar"
                                     alt=""/>
                            </button>

                            <span class="ml-2 text-gray-400">
                                {{ user_data.name }}
                            </span>
                            <svg class="text-gray-400 w-3 h-3 ml-2" fill="none" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                                 viewBox="0 0 24 24" stroke="currentColor">
                                <path d="M19 9l-7 7-7-7"></path>
                            </svg>

                        </div>

                        <button v-if="!userDropdownClosed" @click="userDropdownClosed = true" tabindex="-1"
                                class="fixed inset-0 h-full w-full cursor-default"></button>

                        <transition enter-active-class="transition ease-out duration-100"
                                    enter-class="transform opacity-0 scale-95"
                                    enter-to-class="transform opacity-100 scale-100"
                                    leave-active-class="transition ease-in duration-75"
                                    leave-class="transform opacity-100 scale-100"
                                    leave-to-class="transform opacity-0 scale-95">

                            <div v-if="!userDropdownClosed"
                                 class="origin-top-right absolute right-0 mt-2 w-48 rounded-md shadow-lg z-10">
                                <div class="py-1 rounded-md bg-white ring-1 ring-black ring-opacity-5" role="menu" aria-orientation="vertical"
                                     aria-labelledby="user-menu">
                                    <a href="/profile"
                                       class="block px-4 py-2 text-sm leading-5 text-gray-700 hover:bg-gray-100 focus:outline-none focus:bg-gray-100 transition duration-150 ease-in-out">Your Profile</a>
<!--									<a href="/billing"-->
<!--                                       class="block px-4 py-2 text-sm leading-5 text-gray-700 hover:bg-gray-100 focus:outline-none focus:bg-gray-100 transition duration-150 ease-in-out">Billing</a>-->
                                    <a href="/logout" @click.prevent="logout()"
                                       class="block px-4 py-2 text-sm leading-5 text-gray-700 hover:bg-gray-100 focus:outline-none focus:bg-gray-100 transition duration-150 ease-in-out">Sign out</a>
                                </div>
                            </div>
                        </transition>
                    </div>
                </div>
            </div>
        </div>


		<!-- Processing dots/ feedback when you hit save -->
		<div v-if="processingSave" class="dots absolute z-50" style="left: 48%; top: 50%">
			<div></div>
			<div></div>
			<div></div>
		</div>


		<!--
		  Mobile menu, toggle classes based on menu state.

		  Menu open: "block", Menu closed: "hidden"
		-->
<!--        <div class="hidden sm:hidden">-->
<!--            <div class="px-2 pt-2 pb-3">-->
<!--                <a href="#"-->
<!--                   class="block px-3 py-2 rounded-md text-base font-medium text-white bg-gray-900 focus:outline-none focus:text-white focus:bg-gray-700 transition duration-150 ease-in-out">Dashboard</a>-->
<!--                <a href="#"-->
<!--                   class="mt-1 block px-3 py-2 rounded-md text-base font-medium text-gray-300 hover:text-white hover:bg-gray-700 focus:outline-none focus:text-white focus:bg-gray-700 transition duration-150 ease-in-out">Team</a>-->
<!--                <a href="#"-->
<!--                   class="mt-1 block px-3 py-2 rounded-md text-base font-medium text-gray-300 hover:text-white hover:bg-gray-700 focus:outline-none focus:text-white focus:bg-gray-700 transition duration-150 ease-in-out">Projects</a>-->
<!--                <a href="#"-->
<!--                   class="mt-1 block px-3 py-2 rounded-md text-base font-medium text-gray-300 hover:text-white hover:bg-gray-700 focus:outline-none focus:text-white focus:bg-gray-700 transition duration-150 ease-in-out">Calendar</a>-->
<!--            </div>-->
<!--        </div>-->
    </nav>
</template>

<script>
    export default {
        name: "Navbar",
        data() {
            return {
                // user_data: { "data": {} },
                userDropdownClosed: true,
                validUser: false,
				registerModalOpen: false,
				processingSave: false,
            }
        },
        created() {
            this.validUser = !!this.user_data.email;
        },
		mounted() {
			this.$root.$on('openRegisterModal', this.openRegisterModal);
			this.$root.$on('processing', this.processing);
			this.$root.$on('stopprocessing', this.stopprocessing);
		},
        props: {
            user_data: null,
        },
        methods: {
            toggleUserDropdown() {
                this.userDropdownClosed = !this.userDropdownClosed;
            },
			openRegisterModal() {
            	this.registerModalOpen = true;
			},
			processing() {
            	this.processingSave = true;
			},
			stopprocessing() {
            	this.processingSave = false;
			},
			logout() {
                axios.post('/logout')
                    .then(response => {
                        window.location.href = '/'
                        // location.reload();
                    })
                    .catch(error => {
                        window.location.href = '/'
                    });
            }
        },
		computed: {
        	publicScenarioUUID() {
				const url = window.location.pathname;
				return /[^/]*$/.exec(url)[0]; // only return the UUID that is after the "scenario/share/"

			},
			isMarketOpen(){
				const now = new Date();
				const day = now.getUTCDay();
				const hours = now.getUTCHours();
				const min = now.getUTCMinutes();

				if(day >= 1 && day <= 5 ) { // not a Saturday (6) or Sunday (7)
					if (hours === 13 && min >=30) { // if it's 9am and past the 30min
						return true
					}
					// if it's between 10am ET and 4pm ET
					if (hours >=14 && hours < 20) {
						return true
					}
					// if neither are true then must be closed
					return false
				} else {
					return false
				}
			}
		}
    }
</script>


<style>
.dots {
  width: 5em;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: space-between;
}

.dots div {
  width: 1em;
  height: 1em;
  border-radius: 50%;
  background-color: #fc2f70;
  animation: fade 0.8s ease-in-out alternate infinite;
}

.dots div:nth-of-type(1) {
  animation-delay: -0.4s;
}

.dots div:nth-of-type(2) {
  animation-delay: -0.2s;
}

@keyframes fade {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}
</style>

<template>
    <div>

        <div class="mb-8">
            <div class="-my-2 py-2 sm:-mx-6 sm:px-6 lg:-mx-8 lg:px-8">
                <div class="align-middle inline-block min-w-full shadow rounded-lg bg-white">
                    <div>
                        <div class="px-5 pb-3">

							<div class="block md:flex justify-between items-center py-2 border-b border-gray-200">

								<div class="flex">
									<div>Trending Heatmap</div>
								</div>

								<div class="flex items-center text-xs">

									<div class="text-gray-600 pr-2">Rolling 30-Day Drift</div>
<!--									<div class="w-2 h-2 rounded-full"-->
<!--										 :style="{ backgroundColor: getRatioColor(this.overlapTypeAvg)}"></div>-->
									<div class="pl-1">
										<span v-if="this.trending.meta.rollingDrift">{{ decimalPercentFormat(this.trending.meta.rollingDrift,2) }}</span>
										<span v-else>N/A</span>
									</div>
									<svg class="ml-1 w-4 h-4 text-gray-400 hover:text-gray-600 transition ease-in-out duration-150 cursor-pointer" fill="currentColor" viewBox="0 0 20 20"
										 v-tooltip="{ html: 'trendingTopTooltip', class: 'fade-in' }">
										xmlns="http://www.w3.org/2000/svg">
										<path fill-rule="evenodd"
											  d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-8-3a1 1 0 00-.867.5 1 1 0 11-1.731-1A3 3 0 0113 8a3.001 3.001 0 01-2 2.83V11a1 1 0 11-2 0v-1a1 1 0 011-1 1 1 0 100-2zm0 8a1 1 0 100-2 1 1 0 000 2z"
											  clip-rule="evenodd"></path>
									</svg>

									<div id="trendingTopTooltip" class="text-xs text-gray-300">
										<b>Rolling drift</b> measures how much your portfolio as a whole moves away from, or towards your targets. <br>
										It is measured using by using the weighted average of the simple return of each of your positions in the last 30 days.
										<br><br>
										High percentage drift often suggests your portfolio has high beta assets or there is generally volatile markets.<br>
										Low percentage drift suggests limited movement in your assets or generally calm markets.<br>
										There are no guidelines suggesting an ideal range as this is dependent on your portfolio objectives.
										<br><br>
										At least 30 days of data is needed to calculate drift.
									</div>


								</div>

							<!-- Granularity/ Pivot Selection dropdown -->

								<div class="flex items-center">
									<div class="text-xs text-gray-600 mr-2">
										Pivot
									</div>

									<div class="relative sm:inline-block text-left ">

										<button @click="GranularityDropdownOpen = !GranularityDropdownOpen" type="button" :disabled="this.trending.meta.error"
												class="inline-flex justify-between w-full rounded-md border border-gray-300 px-4 py-2 bg-white text-sm leading-5 font-medium text-gray-700 hover:text-gray-500 focus:outline-none focus:border-blue-300 focus:ring-blue active:bg-gray-50 active:text-gray-800 transition ease-in-out duration-150">
											<span>{{ selectedGranularity.name }}</span>
											<svg class="-mr-1 ml-2 h-5 w-5" fill="currentColor" viewBox="0 0 20 20">
												<path fill-rule="evenodd"
													  d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
													  clip-rule="evenodd"/>
											</svg>
										</button>

										<!--Full width button to close if you click outside-->
										<button v-if="GranularityDropdownOpen" @click="GranularityDropdownOpen = false"
												tabindex="-1"
												class="fixed inset-0 h-full w-full cursor-default"></button>

										<transition enter-active-class="transition ease-out duration-100"
													enter-class="transform opacity-0 scale-95"
													enter-to-class="transform opacity-100 scale-100"
													leave-active-class="transition ease-in duration-75"
													leave-class="transform opacity-100 scale-100"
													leave-to-class="transform opacity-0 scale-95">

											<div v-if="GranularityDropdownOpen"
												 class="origin-top-right absolute right-0 mt-2 w-64 rounded-md shadow-lg z-10">
												<div class="rounded-md bg-white ring-1 ring-black ring-opacity-5">
													<div class="py-1">

														<button v-if="!this.validUser" @click="openRegisterModal()"
																class="inline-flex items-center -mt-1 hover:text-black hover:shadow-inner transition duration-150 ease-in-out relative block px-4 py-2 text-xs leading-normal text-gray-700 border-b border-gray-400 text-left w-full bg-gradient-to-tr from-pink-200 hover:from-pink-300 to-white rounded-t-md hover:bg-gray-200 hover:text-gray-900 focus:outline-none">

															<svg xmlns="http://www.w3.org/2000/svg" class="w-8 mr-2"
																 viewBox="0 0 20 20" fill="currentColor">
																<path
																	d="M8 9a3 3 0 100-6 3 3 0 000 6zM8 11a6 6 0 016 6H2a6 6 0 016-6zM16 7a1 1 0 10-2 0v1h-1a1 1 0 100 2h1v1a1 1 0 102 0v-1h1a1 1 0 100-2h-1V7z"/>
															</svg>
															Create an account to pivot to view heatmap by positions

														</button>
														<button v-for="(overlapType,index) in granularityOptions" :key=index
																@click="changeOverlapType(index)" :disabled="!validUser"
																:class="[validUser ? 'cursor-pointer text-gray-700' : 'cursor-not-allowed text-gray-500']"
																class="inline-flex relative block px-4 py-2 text-sm leading-5  text-left w-full hover:bg-gray-100 hover:text-gray-900 focus:outline-none focus:bg-gray-100 focus:text-gray-900">

															{{ overlapType.formalName }}
															<span v-show="overlapType.id === selectedGranularity.id"
																  class="absolute inset-y-0 right-0 flex items-center pr-2">
																<svg class="h-5 w-5 text-gray-700" fill="none"
																	 stroke-linecap="round" stroke-linejoin="round"
																	 stroke-width="2" viewBox="0 0 24 24"
																	 stroke="currentColor">
																	<path d="M5 13l4 4L19 7"></path>
																</svg>
															</span>
														</button>

													</div>
												</div>
											</div>
										</transition>
									</div>
								</div>
							</div>

							<div class="grid grid-cols-5 mt-2">
								<div class="col-span-5 w-full" >
									<canvas v-if="!this.trending.meta.error" id="trendingMatrixChart"></canvas>
									<div v-else class="text-gray-600 text-sm text-center py-1">
										{{ this.trending.meta.error }}
									</div>
								</div>



							</div>
							<div v-if='!this.trending.meta.error' class="absolute -ml-4 -mt-3">
								<svg class="ml-1 w-4 h-4 text-gray-400 hover:text-gray-600 transition ease-in-out duration-150 cursor-pointer" fill="currentColor" viewBox="0 0 20 20"
									 v-tooltip="{ html: 'trendingHelp', class: 'fade-in' }">
									xmlns="http://www.w3.org/2000/svg">
									<path fill-rule="evenodd"
										  d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-8-3a1 1 0 00-.867.5 1 1 0 11-1.731-1A3 3 0 0113 8a3.001 3.001 0 01-2 2.83V11a1 1 0 11-2 0v-1a1 1 0 011-1 1 1 0 100-2zm0 8a1 1 0 100-2 1 1 0 000 2z"
										  clip-rule="evenodd"></path>
								</svg>

								<div id="trendingHelp" class="text-xs text-gray-300 w-80">
									<span v-if="this.selectedGranularity.slug==='overall'">Trending heatmap visualizes your portfolio accuracy as snapshotted at the end of each day since creation.</span>
									<span v-else>Trending heatmap with position level detail visualizes the absolute difference between between your actual and target weighting.</span>
									<br><br>
									Black outlined squares denote a change from the previous day in either positions, targets, or recommendations.
									<br><br>
									Up to 8 months of data is snapshotted and saved.
									<br>
									<br><br>
									<span v-if="this.selectedGranularity.slug==='overall'">Portfolio Accuracy:</span>
									<span v-else>Position Weight Target-to-Actual Delta:</span>
									<br>
									<div v-if="this.selectedGranularity.slug==='overall'" class="flex justify-around mt-2">
										<p>100%</p>
										<p>90%</p>
										<p>80%</p>
										<p>70%</p>
										<p><=60%</p>
									</div>
									<div v-else class="flex justify-around mt-2">
										<p>0%</p>
										<p>5%</p>
										<p>10%</p>
										<p>15%</p>
										<p>20%</p>
										<p>25%+</p>
									</div>

									<div class="flex items-center"><span class="h-2 rounded-full mx-2 w-full"
																		 style="background: linear-gradient(90deg, rgba(46,204,113,1) 0%, rgba(144,200,64,1) 20%, rgba(241,196,15,1) 40%, rgba(236,136,38,1) 60%, rgba(231,76,60,1) 80%);">

									</span></div>
								</div>

							</div>


                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import {Chart, registerables} from 'chart.js';
import {displayHelpers} from "../../mixins/displayHelpers";
// import { getRelativePosition } from "chart.js/helpers";
import {MatrixController, MatrixElement} from 'chartjs-chart-matrix';
import 'chartjs-adapter-date-fns';


export default {
        name: 'trendingMatrixCard',
		mixins: [displayHelpers],
        data(vm) {
            return {
				countMode: false,
            	validUser: false,
            	matrixChart: null,
				granularityOptions:
				[
					{"id": 1, "formalName": "Overall Portfolio Accuracy/ Day", "name": "Accuracy/ Day", "slug": "overall"},
					{"id": 2, "formalName": "Position Diff to Target/ Week", "name": "Position/ Week", "slug": "position"},
				],
				selectedGranularity: {"id": 1, "formalName": "Overall Portfolio Accuracy/ Day", "name": "Accuracy/ Day", "slug": "overall"},
				GranularityDropdownOpen: false,
				testData: {},
                chartData: {
            		datasets: [{
						data: this.trending.portfolioLevel,
						backgroundColor(context) {
							return vm.getValueColor(context.raw.v);
						},
						borderWidth: 1,
						width(c) { // this calculated to 15 at the max window size
							const canvas = c.chart.canvas || {};
							return canvas.width / (43.1333 * c.chart.currentDevicePixelRatio);
						},
						height(c) {
							const canvas = c.chart.canvas || {};
							return canvas.width / (43.1333 * c.chart.currentDevicePixelRatio);
						}
					}],

				}
            }
        },
        props: {
			user_data: null,
			trending: [],
        },
		created() {
			Chart.register(...registerables);
			Chart.register(MatrixController, MatrixElement);

			this.validUser = !!this.user_data.email;

		},
        mounted() {
			let ctx = document.getElementById("trendingMatrixChart");

			if(!this.trending.meta.error) { // make sure there's no error before we try to load the chart

				this.trendingMatrixChart = new Chart(ctx, {
					type: 'matrix',
					data: this.chartData,
					options: {
						borderRadius: 3,
						aspectRatio: this.calculateAspectRatio, // this should be equal to the number of rows on the y axis minus 1?
						scales: this.scalePortfolioLevelTrending,
						// scales: this.scalePositionLevelTrending,
						// scales: this.scalePortfolioLevelTrending,
						// maintainAspectRatio: true,
						borderColor: (context) => {
							if(context.raw.changed) { return '#696969' }
						},
						responsive: true,
						plugins: {
							matrix: {
								sort: false
							},
							legend: {
								display: false,
							},
							tooltip: {
								callbacks: {
									title() {
										return '';
									},
									label: (context) => {

										if(context.raw.x.length > 3) { // this means its POSITION level
											if(context.raw.changed) {
												return [context.raw.y, 'Week of: ' + context.raw.x, this.decimalPercentFormat(context.raw.v) + ' Diff to Target', 'Change Recorded']
											}
											return [context.raw.y, 'Week of: ' + context.raw.x, this.decimalPercentFormat(context.raw.v) + ' Diff to Target']
										} else { // this means its PORTFOLIO level
											if(context.raw.changed) {
												return [context.raw.y, this.decimalPercentFormat(context.raw.v) + ' Accuracy','Day-over-day change!']
											}

											return [context.raw.y, this.decimalPercentFormat(context.raw.v) + ' Accuracy' ]
										}
									}
								}
							}
						},
					}
				})

				this.trendingMatrixChart.update();

			}


		},
        computed: {

			calculateAspectRatio () {

				let rowCount;

				if(this.selectedGranularity.slug === 'overall') {
					rowCount = this.trending.meta.portfolioTicksY;
				} else {
					rowCount = this.trending.meta.positionTicksY;
				}

				const width = (this.trendingMatrixChart || {}).width || 622;

				// 21 = pixels per row
				// 37 = pixels for x axis added to chart

				return width / ((rowCount * 20) + 37);
			},

			scalePortfolioLevelTrending () {

				// const dates = this.trending.positionLevel.map(d => new Date(d.x));
				// const earliestDate = new Date(Math.min(...dates));

				return {
					x: {
						display: true,
						offset: false,
						ticks: {
							stepSize: 1,
							maxRotation: 0,
							font: {
								size: 10
							},
							autoSkip: true
						},
						grid: {
							display: false,
							drawBorder: false
						},
						min: 1,
						max: 31,
						time: {
							unit: 'day',
							round: 'day',
							isoWeekday: 1,
							displayFormats: {
								day: 'dd'
							}
						}
					},
					y: {

						reverse: true,
						type: 'time',
						time: {
							unit: 'month',
							round: 'month',
							isoWeekday: 1,
							displayFormats: {
								day: 'MMM'
							}
						},
						left: 'left',
						display: true,
						offset: true,
						ticks: {
							padding: 5,
							font: {
								size: 10
							},
							autoSkip: false
						},
						grid: {
							display: false,
							drawBorder: false
						},
						min: (context) => {
							return this.trending.meta.earliestDate;
							// return earliestDate.toISOString().slice(0, 10); // YYYY-MM-DD format
						},
					}
				}
			},

			scalePositionLevelTrending () {

				return {
					x: {
						type: 'time',
						display: true,
						offset: false,

						ticks: {
							maxRotation: 0,
							font: {
								size: 10
							},
							autoSkip: true,
						},
						grid: {
							display: false,
							drawBorder: false
						},
						min: (context) => {
							return this.trending.meta.earliestDate;
							// return earliestDate.toISOString().slice(0, 10); // YYYY-MM-DD format
						},
						max: (context) => {
							return this.trending.meta.latestDate;
							// const latestDate = new Date(this.trending.meta.earliestDate);
							// return latestDate.setDate(latestDate.getDate() + 217);
						},
						time: {
							unit: 'month',
							round: 'week',
							isoWeekday: 1,
							displayFormats: {
								week: 'MMM yy'
							}
						}
					},
					y: {
						type: 'category',
						left: 'left',
						display: true,
						offset: true,
						ticks: {
							padding: 5,
							font: {
								size: 10
							},
							autoSkip: false
						},
						grid: {
							display: false,
							drawBorder: false
						}
					}
				}
			}

        },
        methods: {
			openRegisterModal() {
				this.GranularityDropdownOpen = false;
				this.$root.$emit('openRegisterModal');
			},
			changeOverlapType(index) {
				this.selectedGranularity = this.granularityOptions[index];
				// rebuild the dataset based on the overlap selected
				this.buildChartData(index);
				this.trendingMatrixChart.update();
				this.GranularityDropdownOpen = false;
			},

			getValueColor(valPassed,customOpacity = false) {

				if(this.selectedGranularity.slug === 'overall') {
					valPassed = 1 - valPassed;
				}

				if(valPassed === 0) { return 'rgb(46,204,113,0.7)'}

				let breakPoints = [0,10,20,30,40];

				if(this.selectedGranularity.slug === 'position') {
					breakPoints = [0,6,12,18,24]
				}

				const gradient = [
					[
						breakPoints[0],
						[46,204,113]
					],
					[
						breakPoints[1],
						[144,200,64]
					],
					[
						breakPoints[2],
						[241,196,15]
					],
					[
						breakPoints[3],
						[236,136,38]
					],
					[
						breakPoints[4],
						[231,76,60]
					],
					[
						99,
						[231,76,60]
					],
					[
						100,
						[231,76,60]
					]
				];

				let colorRange = [];

				gradient.every(function (value,index) {

					let valAdjusted = valPassed * 100

					if(valAdjusted<=value[0]) {
						colorRange = [index-1,index]
						return false;
					}
					return true;
				});

				let valAdjusted = valPassed * 100
				//Get the two closest colors
				var firstcolor = gradient[colorRange[0]][1];
				var secondcolor = gradient[colorRange[1]][1];

				//Calculate ratio between the two closest colors

				var firstcolor_x = 500*(gradient[colorRange[0]][0]/100);
				var secondcolor_x = 500*(gradient[colorRange[1]][0]/100)-firstcolor_x;

				var slider_x = 500*(valPassed)-firstcolor_x;
				var ratio = slider_x/secondcolor_x

				const opacity = typeof customOpacity === 'number' ? customOpacity : Math.max(0.6, Math.min(valPassed, 0.6));

				// var opacity = Math.max(0.6, Math.min(valPassed, 0.6));

				return 'rgba(' + this.pickHex( secondcolor,firstcolor, ratio ) +',' + opacity +')';

			},
			pickHex(color1, color2, weight) {
				const w = weight * 2 - 1;
				const w1 = (w + 1) / 2;
				const w2 = 1 - w1;
				return [Math.round(color1[0] * w1 + color2[0] * w2),
					Math.round(color1[1] * w1 + color2[1] * w2),
					Math.round(color1[2] * w1 + color2[2] * w2)];
			},
			getRatioColor(value) {
				if(value <= 0.05) { return '#2ecc71'; } else
				if(value <= 0.1) { return '#90C840'; } else
				if(value <= 0.17) { return '#f1c40f'; } else
				if(value <= 0.25) { return '#EC8826'; } else
				{ return '#e74c3c'; }
			},
			buildChartData(index) {

				if(index === 0) {
					// portfolio
					this.chartData.datasets[0].data = this.trending.portfolioLevel;
					this.trendingMatrixChart.options.scales = this.scalePortfolioLevelTrending;
					this.trendingMatrixChart.update();


				} else  {
					// position level
					this.chartData.datasets[0].data = this.trending.positionLevel;
					this.trendingMatrixChart.options.scales = this.scalePositionLevelTrending;
					this.trendingMatrixChart.update();

				}

				// now I need to calculate the axis and update the aspect ratio.
				// this is irrespective of the change

				this.trendingMatrixChart.options.aspectRatio = this.calculateAspectRatio
				this.trendingMatrixChart.resize();
			},


        }
    }
</script>

<template>
    <div>

        <div class="mb-5">
            <div class="-my-2 py-2 sm:-mx-6 sm:px-6 lg:-mx-8 lg:px-8">
                <div class="align-middle inline-block min-w-full shadow rounded-lg bg-white">
                    <div>
                        <div class="p-5">


							<div class="flex justify-between items-end">
								<div class="block">

									<!--SCENARIO Name-->
									<div class="whitespace-nowrap flex">
										<h3>{{ primaryScenario.name }}</h3>
										<div class="ml-3 flex text-xs items-center text-gray-500">
											<!-- Eye open -->
											<svg xmlns="http://www.w3.org/2000/svg" class="h-4 w-4" viewBox="0 0 20 20" fill="currentColor">
												<path d="M10 12a2 2 0 100-4 2 2 0 000 4z" />
												<path fill-rule="evenodd" d="M.458 10C1.732 5.943 5.522 3 10 3s8.268 2.943 9.542 7c-1.274 4.057-5.064 7-9.542 7S1.732 14.057.458 10zM14 10a4 4 0 11-8 0 4 4 0 018 0z" clip-rule="evenodd" />
											</svg>
											<!-- eye closed -->
<!--											<svg xmlns="http://www.w3.org/2000/svg" class="h-4 w-4" viewBox="0 0 20 20" fill="currentColor">
												<path fill-rule="evenodd" d="M3.707 2.293a1 1 0 00-1.414 1.414l14 14a1 1 0 001.414-1.414l-1.473-1.473A10.014 10.014 0 0019.542 10C18.268 5.943 14.478 3 10 3a9.958 9.958 0 00-4.512 1.074l-1.78-1.781zm4.261 4.26l1.514 1.515a2.003 2.003 0 012.45 2.45l1.514 1.514a4 4 0 00-5.478-5.478z" clip-rule="evenodd" />
												<path d="M12.454 16.697L9.75 13.992a4 4 0 01-3.742-3.741L2.335 6.578A9.98 9.98 0 00.458 10c1.274 4.057 5.065 7 9.542 7 .847 0 1.669-.105 2.454-.303z" />
											</svg>-->
											<p class="ml-1">Public</p>
										</div>
									</div>

									<div class="">
										<div class="text-xs text-gray-600">
											{{ this.primaryScenario.description }}
										</div>
									</div>
								</div>


								<div class="flex ml-3">
									<div class="flex w-20">
										<button @click="copyToClipboard()" type="button" v-tooltip="{ content: 'This scenario is public, click to copy URL for sharing.', class: 'text-xs text-gray-300 fade-in' }"
												class="inline-flex justify-center w-full rounded-md border  p-2 bg-white  shadow-sm focus:outline-none transition ease-in-out duration-150 text-xs sm:leading-5"
												:class="[isCopied ? 'text-green-500 border-green-500' : 'text-gray-600 hover:text-gray-500 border-gray-300']"
										>
											<!-- Share icon--->
											<svg v-show="!isCopied" xmlns="http://www.w3.org/2000/svg" class="w-4 h-4 self-center" viewBox="0 0 20 20" fill="currentColor">
												<path d="M15 8a3 3 0 10-2.977-2.63l-4.94 2.47a3 3 0 100 4.319l4.94 2.47a3 3 0 10.895-1.789l-4.94-2.47a3.027 3.027 0 000-.74l4.94-2.47C13.456 7.68 14.19 8 15 8z" />
											</svg>
											<!-- Check mark icon-->
											<svg v-show="isCopied" xmlns="http://www.w3.org/2000/svg" class="w-4 h-4 self-center cursor-pointer" viewBox="0 0 20 20" fill="currentColor">
												<path fill-rule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clip-rule="evenodd" />
											</svg>
											<p class="ml-1">{{ (isCopied ? 'Copied!' : 'Share') }}</p>
										</button>
									</div>
									<div class="flex-1 sm:flex-initial ml-0 md:ml-2" v-if="scenarioLoaded">
										<a :href="'/scenario/share/' + this.primaryScenario.uuid + '/edit'"
										   class="w-full inline-flex rounded-md border border-gray-300 p-2 bg-white hover:text-blue-500 cursor-pointer text-gray-700 shadow-sm hover:text-gray-500 focus:outline-none transition ease-in-out duration-150 text-xs sm:leading-5">
											<svg class="w-5 h-5" fill="none"
												 stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
												 viewBox="0 0 24 24" stroke="currentColor">
												<path
													d="M15.232 5.232l3.536 3.536m-2.036-5.036a2.5 2.5 0 113.536 3.536L6.5 21.036H3v-3.572L16.732 3.732z"></path>
											</svg>
											<span class="text-xs text-center w-full leading-3 self-center">Edit</span>
										</a>
									</div>
								</div>


							</div>

                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'VisitorScenarioCard',
        data() {
            return {

				isCopied: false,

                scenarioLoaded: false,
                blankScenario: {
                    name: 'None',
                    description: ''
                },
            }
        },
        props: {
            primaryScenario: null,
            options: null,
        },
        mounted() {
          (this.primaryScenario === undefined) ? this.scenarioLoaded = false : this.scenarioLoaded = true;
          if(this.primaryScenario === undefined) this.primaryScenario = this.blankScenario

        },
        computed: {
			//
        },
        methods: {
            changeScenario(index) {
                if(this.options[index].id === this.primaryScenario.id) {
                    // if you selected the one already loaded, just close the prompt
                    this.scenarioDropdownOpen = false;
                } else {
                    axios.post('/changeScenario', {
                        // pass the scenario ID of the item selected from the list based on its index
                        change: this.options[index].id,
                    })
                        .then(response => {
                            location.reload();
                        })
                        .catch(error => {
                            location.reload();
                        });
                }
            },
            edit() {
                window.location.href = '/edit/' + this.primaryScenario.id;
            },
			copyToClipboard() {
            	this.isCopied = true;
				const baseUrl = window.location.origin
				const sharedURL = baseUrl + '/scenario/share/' + this.primaryScenario.uuid

				try {
					navigator.clipboard.writeText(sharedURL);
					console.log('Page URL copied to clipboard');
				} catch (err) {
					console.error('Failed to copy: ', err);
				}

				setTimeout(() => this.isCopied = false, 3000);

			}



        }
    }
</script>

<template>
    <div>

        <confirm-delete-modal :open=deleteModalOpen :data="meta"
                              @close="deleteModalOpen = false">
        </confirm-delete-modal>


        <register-guest-modal :open="registerModalOpen" :scenario="table" :meta="meta" :isnew="isNewScenario"
                                @close="registerModalOpen = false"
                                @processing="processingSave = true"
                                @stopprocessing="processingSave = false"
                                @save="save"
							    @isNowRegistered="isGuest = false"
        >
        </register-guest-modal>


        <welcome-card :visible="welcomeCardVisible" @loadexample="addExample"></welcome-card>

		<!-- Show a note if this is a scenario that is linked with a broker -->
		<linked-scenario-note :visible="!metaprop.custom && isGuest === false && !isNewScenario"></linked-scenario-note>

<!--		<edit-notice-card>-->
<!--			<template v-slot:icon>-->
<!--				<svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">-->
<!--					<path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M7 12l3-3 3 3 4-4M8 21l4-4 4 4M3 4h18M4 4h16v12a1 1 0 01-1 1H5a1 1 0 01-1-1V4z" />-->
<!--				</svg>-->
<!--			</template>-->
<!--			<template v-slot:text>-->
<!--				<p>A paragraph for the main content.</p>-->
<!--			</template>-->
<!--		</edit-notice-card>-->

        <!--Black Background for when processing-->
        <div v-if="processingSave" class="fixed z-20 bottom-0 inset-x-0 px-4 pb-4 sm:inset-0 sm:flex sm:items-center sm:justify-center">
                <div class="fixed inset-0 transition-opacity">
                    <div class="absolute inset-0 bg-gray-900 opacity-75"></div>
                </div>
        </div>

        <!-- Meta data card with save button -->
        <div class="mb-5">
            <div class="-my-2 py-2 sm:-mx-6 sm:px-6 lg:-mx-8 lg:px-8">
                <div class="align-middle inline-block min-w-full shadow rounded-lg bg-white">
                    <div>
                        <div class="p-5">
                            <div class="relative">


                                <!-- Processing dots/ feedback when you hit save -->
                                <div v-if="processingSave" class="dots absolute z-30" style="left: 44%;">
                                    <div></div>
                                    <div></div>
                                    <div></div>
                                </div>

                                <div class="flex justify-between">
                                    <!--Name Input-->
                                    <div class="pb-3 sm:w-1/3 flex-grow">
                                        <label for="scenario_name" class="block text-xs leading-5 font-medium text-gray-700">
                                            Scenario Name
                                        </label>
                                        <input v-model="meta.name" placeholder="My first scenario..."
                                               class="w-full sm:w-auto h-8 appearance-none bg-transparent text-gray-700 py-1 px-2 leading-tight focus:outline-none border border-gray-300 rounded-md text-sm focus:border-blue-700"
                                               id="scenario_name" type="text">
                                    </div>

                                    <div class="px-3 pt-2 sm:pt-0 text-gray-600 self-center">
                                        <!--Trash can symbol-->
                                        <svg v-if="this.canBeDeleted"
                                             @click="deleteModalOpen = !deleteModalOpen"
                                             class="w-5 h-5 hover:text-red-500 cursor-pointer" fill="none"
                                             stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                                             viewBox="0 0 24 24" stroke="currentColor">
                                            <path
                                                d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16"></path>
                                        </svg>
                                    </div>

                                    <!-- Save button Desktop -->
                                    <span class="hidden sm:block py-4">
                                        <button :disabled="(!readyToSave || processingSave)"
												v-tooltip="{ content: 'Make sure you have a scenario name and target sum equal to 100% to save.', class: 'text-xs text-gray-300 fade-in' }"
                                                @click.prevent="( isGuest ? (registerModalOpen = !registerModalOpen) : save() )"
                                                type="button"
                                                :class="[!readyToSave ? 'cursor-not-allowed text-gray-400 bg-gray-100' : 'text-gray-700 bg-white hover:text-gray-500']"
                                                class="inline-flex justify-center w-full rounded-md border border-gray-300 px-4 py-2 text-base leading-6 font-medium shadow-sm focus:outline-none focus:border-blue-300 focus:ring-blue transition ease-in-out duration-150 sm:text-sm sm:leading-5">
                                            {{ canBeDeleted ? 'Save' : 'Save As' }}
                                            <svg class="w-4 h-4 ml-2 self-center" fill="none" stroke-linecap="round"
                                                 stroke-linejoin="round" stroke-width="2" viewBox="0 0 24 24"
                                                 stroke="currentColor">
                                                <path d="M9 5l7 7-7 7"></path>
                                            </svg>
                                        </button>
                                    </span>
                                </div>

                                <!--Description Input-->
                                <div>
                                    <label for="scenario_description" class="block text-xs leading-5 font-medium text-gray-700">
                                        Description
                                    </label>
                                    <textarea v-model="meta.description"
                                              placeholder="A sentence or two describing the specifics of this scenario..."
                                              class="h-12 appearance-none bg-transparent w-full text-gray-700 py-1 px-2 leading-tight focus:outline-none border border-gray-300 rounded-md text-sm focus:border-blue-700"
                                              type="text" id="scenario_description"></textarea>
                                </div>

								<!-- Save button Mobile -->
								<div class="sm:hidden">
									<button :disabled="(!readyToSave || processingSave)"
											v-tooltip="{ content: 'Make sure you have a scenario name and target sum equal to 100% to save.', class: 'text-xs text-gray-300 fade-in' }"
											@click.prevent="( isGuest ? (registerModalOpen = !registerModalOpen) : save() )"
											type="button"
											:class="[!readyToSave ? 'cursor-not-allowed text-gray-400 bg-gray-100' : 'text-gray-700 bg-white hover:text-gray-500']"
											class="mt-4 inline-flex justify-center w-full rounded-md border border-gray-300 px-4 py-2 text-base leading-6 font-medium shadow-sm focus:outline-none focus:border-blue-300 focus:ring-blue transition ease-in-out duration-150 sm:text-sm sm:leading-5">
										{{ canBeDeleted ? 'Save' : 'Save As' }}
										<svg class="w-4 h-4 ml-2 self-center" fill="none" stroke-linecap="round"
											 stroke-linejoin="round" stroke-width="2" viewBox="0 0 24 24"
											 stroke="currentColor">
											<path d="M9 5l7 7-7 7"></path>
										</svg>
									</button>
								</div>

                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- END Meta data card with save button -->

        <!-- Edit Table -->
        <div class="flex flex-col mb-3" @keyup.enter="addRow">
            <div class="-my-2 py-2 sm:-mx-6 sm:px-6 lg:-mx-8 lg:px-8">
                <div class="align-middle inline-block min-w-full shadow overflow-hidden rounded-lg">
                    <div class="border-b">
                        <div class="flex justify-between px-6 bg-white">
                            <span class="flex text-blue-dark py-4 font-normal text-sm self-center text-gray-600">Target Sum:
                                <span :class="[this.sumTargetPercent === 10000 ? 'text-green-500' : '']"
                                      class="text-gray-800 ml-1"> {{this.sumTargetPercent/100}}%</span>
                                <span class="self-center" v-show="this.sumTargetPercent === 10000">
                                    <svg class="ml-1 h-5 w-5 text-green-500" fill="none" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" viewBox="0 0 24 24" stroke="currentColor"><path d="M5 13l4 4L19 7"></path></svg>
                                </span>
                            </span>
                            <div class="flex items-center">

                                <!--<span class="py-4">
                                    <button :disabled="isFetching"
                                            :class="[isFetching ? 'cursor-not-allowed text-gray-100 bg-gray-100' : 'text-gray-700 bg-white hover:text-gray-500']"
                                            @click.prevent="fetchStockPrices" type="button"
                                            class="inline-flex justify-center w-full rounded-md border border-gray-300 px-4 py-2 leading-6 font-medium shadow-sm focus:outline-none focus:border-blue-300 focus:ring-blue transition ease-in-out duration-150 text-xs sm:leading-5">
                                        Fetch Stock Prices
                                        <svg class="w-4 h-4 ml-2 self-center" fill="none" stroke-linecap="round"
                                             stroke-linejoin="round" stroke-width="2" viewBox="0 0 24 24"
                                             stroke="currentColor">
                                            <path
                                                d="M8 16a5 5 0 01-.916-9.916 5.002 5.002 0 019.832 0A5.002 5.002 0 0116 16m-7 3l3 3m0 0l3-3m-3 3V10"></path>
                                        </svg>
                                        <div v-if="isFetching" class="dots absolute z-30 mt-1">
                                            <div></div>
                                            <div></div>
                                            <div></div>
                                        </div>
                                    </button>
                                </span>-->
                              <span class="pl-3 py-4">
                                  <button v-show="this.sumTargetPercent !== 10000" @click.prevent="autoBalance" type="button" v-tooltip="{ content: 'Auto-balance will update target percents proportionally to sum to 100%', class: 'text-xs text-gray-300 fade-in' }"
                                          class="inline-flex justify-center w-full rounded-md border border-gray-300 px-4 py-2 bg-white leading-6 font-medium text-gray-700 shadow-sm hover:text-gray-500 focus:outline-none focus:border-blue-300 focus:ring-blue transition ease-in-out duration-150 text-xs sm:leading-5">
                                    Auto-Balance
                                      <svg class="w-4 h-4 ml-2 self-center" fill="none" stroke="currentColor"
                                           viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                                              d="M3 6l3 1m0 0l-3 9a5.002 5.002 0 006.001 0M6 7l3 9M6 7l6-2m6 2l3-1m-3 1l-3 9a5.002 5.002 0 006.001 0M18 7l3 9m-3-9l-6-2m0-2v2m0 16V5m0 16H9m3 0h3"></path>
                                      </svg>
                                  </button>
                                </span>
                            </div>
                        </div>
                        <div class="min-w-full">

                            <div class="flex">
                                <div
                                    class="w-24 px-3 py-3 border-b border-t border-gray-200 bg-gray-100 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider flex">
                                    Symbol

									<svg class="ml-1 w-4 h-4 text-gray-400 hover:text-gray-600 transition ease-in-out duration-150 cursor-pointer" fill="currentColor" viewBox="0 0 20 20"
										 v-tooltip="{ content: 'Enter in assets based on their traded ticker symbol. Most US and some foreign assets supported.', class: 'fade-in w-full md:w-1/6' }">
										xmlns="http://www.w3.org/2000/svg">
										<path fill-rule="evenodd"
											  d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-8-3a1 1 0 00-.867.5 1 1 0 11-1.731-1A3 3 0 0113 8a3.001 3.001 0 01-2 2.83V11a1 1 0 11-2 0v-1a1 1 0 011-1 1 1 0 100-2zm0 8a1 1 0 100-2 1 1 0 000 2z"
											  clip-rule="evenodd"></path>
									</svg>

                                </div>

                                <div
                                    class="hidden sm:table-cell w-48 px-3 py-3 border-b border-t border-gray-200 bg-gray-100 text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
                                    Asset Name
                                </div>
                                <div
                                    class="text-center py-3 border-b border-t border-gray-200 bg-gray-100 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider flex">
                                    Target %

									<svg class="ml-1 w-4 h-4 text-gray-400 hover:text-gray-600 transition ease-in-out duration-150 cursor-pointer" fill="currentColor" viewBox="0 0 20 20"
										 v-tooltip="{ content: 'Enter in the desired target percentage for each asset in your portfolio. The target sum should equal 100% across all assets.', class: 'fade-in w-full md:w-1/6' }">
										xmlns="http://www.w3.org/2000/svg">
										<path fill-rule="evenodd"
											  d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-8-3a1 1 0 00-.867.5 1 1 0 11-1.731-1A3 3 0 0113 8a3.001 3.001 0 01-2 2.83V11a1 1 0 11-2 0v-1a1 1 0 011-1 1 1 0 100-2zm0 8a1 1 0 100-2 1 1 0 000 2z"
											  clip-rule="evenodd"></path>
									</svg>
                                </div>
                                <div
                                    class="w-20 text-center py-3 pl-2 border-b border-t border-gray-200 bg-gray-100 text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider flex">
                                    Shares

									<svg class="ml-1 w-4 h-4 text-gray-400 hover:text-gray-600 transition ease-in-out duration-150 cursor-pointer" fill="currentColor" viewBox="0 0 20 20"
										 v-tooltip="{ html: 'sharesToolTip', class: 'fade-in w-full md:w-1/4' }">
										xmlns="http://www.w3.org/2000/svg">
										<path fill-rule="evenodd"
											  d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-8-3a1 1 0 00-.867.5 1 1 0 11-1.731-1A3 3 0 0113 8a3.001 3.001 0 01-2 2.83V11a1 1 0 11-2 0v-1a1 1 0 011-1 1 1 0 100-2zm0 8a1 1 0 100-2 1 1 0 000 2z"
											  clip-rule="evenodd"></path>
									</svg>

                                </div>


								<div id="sharesToolTip" class="text-xs text-gray-300">
									Enter in the number of shares you currently hold in this asset. <br>If you don't own any, you may set shares equal to zero.
									<br><br>
									<span class="italic font-bold">Why can't I enter the dollar value of the position?</span><br>
									<span class="ml-2 block">
									Stock market traded assets and funds can change in price very frequently, entering in your position in shares rather than dollars allows us to  assess your portfolio in the future, rather than representing a single snapshot of your portfolio at a point in time. We also automatically update prices regularly.
									</span><br>
									<span class="italic font-bold">Can I just use percentages instead?</span><br>
									<span class="ml-2 block">
										A portfolio must have some tangible amount of money associated to it. If you only provided percentages, we would have to make an assumption about how much money and how many shares each position represents. This would lead to an inaccurate assessment of steps necessary to balance your portfolio versus targets.
									</span>
								</div>
                                <!--<div
                                    class="w-24 text-center py-3 border-b border-t border-gray-200 bg-gray-100 text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
                                    Last Price
                                </div>-->
                                <div
                                    class="pl-2 text-center py-3 border-b border-t border-gray-200 bg-gray-100 text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider flex">
                                    Bought At
									<svg class="ml-1 w-4 h-4 text-gray-400 hover:text-gray-600 transition ease-in-out duration-150 cursor-pointer" fill="currentColor" viewBox="0 0 20 20"
										 v-tooltip="{ content: 'You may optionally enter the average price you acquired a position at and we\'ll calculate a simple return.', class: 'fade-in w-full md:w-1/6' }">
										xmlns="http://www.w3.org/2000/svg">
										<path fill-rule="evenodd"
											  d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-8-3a1 1 0 00-.867.5 1 1 0 11-1.731-1A3 3 0 0113 8a3.001 3.001 0 01-2 2.83V11a1 1 0 11-2 0v-1a1 1 0 011-1 1 1 0 100-2zm0 8a1 1 0 100-2 1 1 0 000 2z"
											  clip-rule="evenodd"></path>
									</svg>
                                </div>

                            </div>

                        </div>
                        <div class="bg-white">

                            <div v-for="(row,index) in table" :key=index>


                                <div class="flex" :class="{'bg-red-100':duplicates.includes(row.symbol)}">
                                    <div class="p-3 w-24 relative">
                                        <!--   <input :disabled="false" type="text" v-model="row.symbol" class="form-input block w-full sm:text-sm sm:leading-5 bg-gray-100">-->
                                        <!--   <input v-model="row.symbol" class="appearance-none block w-20 bg-gray-200 text-gray-700 border border-gray-200 rounded py-2 px-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" type="text" >-->
                                        <input v-model="row.symbol"
                                               @focus="setOriginalValue(index,row.symbol)"
                                               @blur="handleSymbolEntryBlur(index,row)"
                                               :class="[row.symbol==='USD' || row.protect === true ? 'text-gray-600 border-white' : 'text-gray-700 border-gray-300', {'text-red-500':duplicates.includes(row.id)},{'text-red-500':invalid.includes(row.symbol)}]"
                                               class="h-8 appearance-none bg-transparent w-full py-1 px-2 leading-tight focus:outline-none border-b text-sm focus:border-blue-700"
                                               placeholder="ABC" type="text"
                                               :disabled="row.symbol === 'USD' || row.protect === true"
											   ref="symbolEntry"
                                        >
                                        <span v-show="duplicates.includes(row.symbol)" class="absolute mt-1">
                                            <svg class="h-5 text-red-500" fill="none" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" viewBox="0 0 24 24" stroke="currentColor"><path d="M8 16H6a2 2 0 01-2-2V6a2 2 0 012-2h8a2 2 0 012 2v2m-6 12h8a2 2 0 002-2v-8a2 2 0 00-2-2h-8a2 2 0 00-2 2v8a2 2 0 002 2z"></path></svg>
                                        </span>
										<span v-show="invalid.includes(row.symbol)" class="absolute mt-4 top-0 right-0"
											  v-tooltip="{ content: 'This symbol is invalid.', class: 'fade-in' }">
											<svg class="h-5 text-red-500" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
												<path fill-rule="evenodd" d="M8.257 3.099c.765-1.36 2.722-1.36 3.486 0l5.58 9.92c.75 1.334-.213 2.98-1.742 2.98H4.42c-1.53 0-2.493-1.646-1.743-2.98l5.58-9.92zM11 13a1 1 0 11-2 0 1 1 0 012 0zm-1-8a1 1 0 00-1 1v3a1 1 0 002 0V6a1 1 0 00-1-1z" clip-rule="evenodd" />
											</svg>
										</span>
                                    </div>
                                    <div class="hidden sm:block p-3 w-48 self-center">
<!--                                        <div class="relative">-->
<!--                                            <select v-model="row.asset_class"-->
<!--                                                    :disabled="row.symbol === 'USD'"-->
<!--                                                    :class="[row.symbol==='USD' ? 'text-gray-600 border-white' : 'text-gray-700 border-gray-300']"-->
<!--                                                    class="h-8 block appearance-none bg-transparent w-full py-1 px-2 leading-tight focus:outline-none border-b text-sm focus:border-blue-700">-->
<!--                                                &lt;!&ndash; <option value="" disabled selected hidden>Asset Class</option>&ndash;&gt;-->
<!--                                                <option>U.S. Stocks</option>-->
<!--                                                <option>Foreign Stocks</option>-->
<!--                                                <option>Emerging Markets</option>-->
<!--                                                <option>Dividend Stocks</option>-->
<!--                                                <option>Real Estate</option>-->
<!--                                                <option>Government Bonds</option>-->
<!--                                                <option>Corporate Bonds</option>-->
<!--                                                <option>Emrg. Market Bonds</option>-->
<!--                                                <option>Cash or Equivalents</option>-->
<!--                                                <option>Other</option>-->
<!--                                            </select>-->
<!--                                            <div-->
<!--                                                :class="[row.symbol === 'USD' ? 'hidden' : '']"-->
<!--                                                class="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">-->
<!--                                                <svg class="fill-current h-4 w-4" xmlns="http://www.w3.org/2000/svg"-->
<!--                                                     viewBox="0 0 20 20">-->
<!--                                                    <path-->
<!--                                                        d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z"/>-->
<!--                                                </svg>-->
<!--                                            </div>-->
<!--                                        </div>-->
                                      <div class="text-xs">
                                        <span class="text-gray-500" :class="[row.name === 'No Match. Can you verify?' ? 'text-red-500 italic' : '']">
                                          {{ row.name }}
                                        </span>
                                      </div>
                                    </div>
                                    <div class="sm:p-3 py-3 px-1 w-20">
                                        <div class="relative">
                                            <div class="absolute inset-y-0 right-0 flex items-center pointer-events-none">
                                                <span class="text-gray-500 sm:text-sm sm:leading-5">
                                                    %
                                                </span>
                                            </div>
                                            <input v-model="row.target"
                                                   class="h-8 block appearance-none bg-transparent w-full text-gray-700 px-3 py-1 leading-tight focus:outline-none border-b border-gray-300 text-sm focus:border-blue-700"
                                                   placeholder="0"/>

                                        </div>
                                    </div>
                                    <div class="sm:p-3 py-3 px-1 relative"
										 :class="[row.symbol === 'USD' ? 'w-44 text-left' : 'w-20']"
									>
											<div v-if="row.symbol === 'USD'"
												class="absolute inset-y-0 flex items-center pointer-events-none">
												<span class="text-gray-500 sm:text-sm sm:leading-5">
													$
												</span>
											</div>
                                            <input :disabled="row.protect === true" type="text" v-model="row.shares" placeholder="0"
                                               class="h-8 appearance-none bg-transparent w-full py-1 px-2 leading-tight focus:outline-none border-b text-sm focus:border-blue-700"
												   :class="[row.protect === true ? 'text-gray-600 border-white' : 'text-gray-700 border-gray-300',row.symbol==='USD' ? 'text-left pl-3' : 'text-center']"
											>

                                    </div>
                                    <!--<div class="p-3 w-24 self-center">
                                        <div class="relative">
                                            <div
                                                class="absolute inset-y-0 left-0 flex items-center pointer-events-none">
                                                <span class="text-gray-500 sm:text-sm sm:leading-5">
                                                    $
                                                </span>
                                            </div>
                                            <input v-model="row.last_price"
                                                   :disabled="((row.symbol === 'USD') || row.iexprices)"
                                                   :class="[((row.symbol==='USD') || row.iexprices) ? 'text-gray-600 border-white' : 'text-gray-700 border-gray-300']"
                                                   class="h-8 block appearance-none bg-transparent w-full pl-3 py-1 leading-tight focus:outline-none border-b text-sm focus:border-blue-700"
                                                   placeholder="0.00"/>

                                        </div>
                                    </div>-->
                                    <div v-show="row.symbol !== 'USD'" class="sm:p-3 py-3 px-1 w-24">
                                        <div class="relative">
                                            <div
                                                class="absolute inset-y-0 left-0 flex items-center pointer-events-none">
                                                <span class="text-gray-500 sm:text-sm sm:leading-5">
                                                    $
                                                </span>
                                            </div>
                                            <input v-model="row.bought_at"
                                                   :disabled="row.symbol === 'USD' || row.protect === true"
                                                   :class="[row.protect === true ? 'text-gray-600 border-white' : 'text-gray-700 border-gray-300']"
                                                   class="h-8 block appearance-none bg-transparent w-full pl-3 py-1 leading-tight focus:outline-none border-b text-sm focus:border-blue-700"
                                                   placeholder="0.00"/>

                                        </div>
                                    </div>
                                    <button v-show="!row.protect" @click="deleteEvent(index)" class="py-3 pr-2 self-center">
                                        <svg
                                            class="h-4 w-4 stroke-current text-gray-500 hover:text-red-500 cursor-pointer"
                                            fill="none" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                                            viewBox="0 0 24 24" stroke="currentColor">
                                            <path d="M6 18L18 6M6 6l12 12"></path>
                                        </svg>
                                    </button>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
      <div class="flex items-center flex-row-reverse">
        <span v-show="countCashRows < 1" class="pl-3">
          <button @click.prevent="addCashRow" type="button"
                  class="inline-flex justify-center w-full rounded-md border border-gray-300 px-4 py-2 bg-white leading-6 font-medium text-gray-700 shadow-sm hover:text-gray-500 focus:outline-none focus:border-blue-300 focus:ring-blue transition ease-in-out duration-150 text-xs sm:leading-5">
            Add Cash Row
            <svg class="w-4 h-4 ml-2 self-center" fill="none" stroke-linecap="round" stroke-linejoin="round"
                 stroke-width="2" viewBox="0 0 24 24" stroke="currentColor">
              <path d="M12 4v16m8-8H4"></path>
            </svg>
          </button>
        </span>
        <span class="pl-3">
          <button @click.prevent="addRow" type="button"
                  class="inline-flex justify-center w-full rounded-md border border-gray-300 px-4 py-2 bg-white leading-6 font-medium text-gray-700 shadow-sm hover:text-gray-500 focus:outline-none focus:border-blue-300 focus:ring-blue transition ease-in-out duration-150 text-xs sm:leading-5">
            Add Asset Row
            <svg class="w-4 h-4 ml-2 self-center" fill="none" stroke-linecap="round" stroke-linejoin="round"
                 stroke-width="2" viewBox="0 0 24 24" stroke="currentColor">
              <path d="M12 4v16m8-8H4"></path>
            </svg>
          </button>
        </span>
      </div>
    </div>
</template>

<script>

    import EditNoticeCard from "./editNoticeCard";
	export default {
		components: {EditNoticeCard},
		symbolList: {},
        name: "editTable",
        props: {
            tableprop: {type: Array | Object | String, default: null},
            metaprop: {type: Array | Object | String, default: null},
            userprop: {type: Array | Object | String, default: null},
            stocksprop: {type: Array | Object | String, default: null}
        },
        data() {
            return {
                // this is necessary because table needs to be array to modify it later
                table: null,
                meta: {
                    "name": "",
                    "description": "",
                },
                duplicates: [],
				invalid: [],
                originalValues: [],
                isGuest: null,
                deleteModalOpen: false,
                registerModalOpen: false,
                welcomeCardVisible: false,
                processingSave: false,
                isFetching: false,
                pricesFetched: false,
            }
        },
        created() {
			// this.table = JSON.parse(JSON.stringify(this.tableprop));
            // this.table = Object.values(Vue.util.extend({}, this.tableprop));
			this.table = Object.values({ ...this.tableprop });

            if(this.metaprop === 0) {
                this.addRow();
            } else {
                this.meta = {...this.metaprop};
            }

            // if UserProp is 0, then this is a guest accessing this page
            this.isGuest = this.userprop === 0;
            this.welcomeCardVisible = this.isNewScenario;


            this.$options.symbolList = this.stockprop;

            // fetch symbol names
            // axios.get('/symbol-names')
            //     .then(response => {
            //       this.$options.symbolList = response.data;
            //       console.log(response.data);
            //     })
            //     .catch(error => {
            //       console.log("Symbol list couldn't be loaded.");
            //     });

        },
        computed: {
            getSymbols() {
                return this.table.map(function (obj) {
                    return obj['symbol'];
                });

            },
            sumTargetPercentOLD() {
                let sum = 0;
                this.table.forEach(function(item, index) {
                    sum = sum + (parseFloat(item.target) ? parseFloat(item.target) : 0);
                    }
                )
                return sum;
            },
            sumTargetPercent: function(){
              return this.table.reduce(function(total, item){
                  return total + Number(item.target * 100);
              },0);
            },
            countCashRows() {
                return this.table.filter(data => data.symbol == 'USD').length;
            },
            readyToSave() {
                return this.sumTargetPercent === 10000 && this.meta.name.length > 0;
            },
			isNewScenario() {
                return this.metaprop === 0;
            },
			canBeDeleted() {
						// if there is a user and that user is the owner of this scenario, and it's not a new scenario
				return !!((this.meta.user_id && this.metaprop.user_id === this.userprop.id) && (!this.isNewScenario));
			}

        },
        methods: {
			handleSymbolEntryBlur(index, row) {

				if(row.symbol === '') {
					return;
				}

				// check if this row is a duplicate
				this.isDuplicate(index, row.symbol) ? this.duplicates.push(row.symbol) : this.removeFromDuplicates(index, row.symbol);

				// change slashes to periods for multi-class stocks
				const fixedSymbol = row.symbol.replace(".", "/").toUpperCase();
				this.table[index].symbol = fixedSymbol;

				//fetch the stock name by symbol
				try {
					if (fixedSymbol !== 'USD' || fixedSymbol !== '') {

						if(typeof this.stocksprop[fixedSymbol] === 'undefined') {
							this.table[index].name = 'No Match. Can you verify?';
							this.invalid.push(row.symbol);
						} else {
							this.table[index].name = this.stocksprop[fixedSymbol];
						}

					}
				} catch (error) {
					this.table[index].name = 'No Match. Can you verify?';
					this.invalid.push(row.symbol);
				}
			},
            deleteEvent: function (index) {
                this.table.splice(index, 1);
            },
            setOriginalValue(index,value) {
                // console.log("ORIGINAL VALUE: " + value);
                this.originalValues[index] =  value;
            },
            isDuplicate: function (index, symbol) {
                return this.table.filter(data => data.symbol == symbol).length > 1;
            },
            removeFromDuplicates(index,symbol) {
                // console.log("REMOVING: " + this.originalValues[index])
                let cleanedArray = this.duplicates.filter(x => x !== this.originalValues[index]);
                // console.log(this.originalValues[index],symbol,cleanedArray);
                this.duplicates = cleanedArray;
                // this.duplicates = this.duplicates.filter(x => x !== symbol);
            },
            addRow: function (event) {
                let newRow = {symbol:'',asset_class:'',target:'',shares:'',last_price:'',bought_at:''};
                this.table.push(newRow);
                // console.log(this.table.slice(-1)[0].symbol.focus());
                // this.$refs.table.slice(-1)[0].symbol.focus();

				this.$nextTick(() => {
					const rowCount = this.$refs.symbolEntry.length;
					this.$refs.symbolEntry[rowCount - 1].focus();
				});

            },
            addCashRow: function (event) {
                let newRow = {symbol:'USD',asset_class:'Cash or Equivalents',target:'0',shares:'',last_price:'1',bought_at:'1'};
                this.table.push(newRow);
            },
            autoBalance() {
                // sum of all targets
                let totalSum = this.table.reduce(function(total, item){
                  return total + Number(item.target);
                },0);
                console.log(totalSum);
                let tableLength = this.table.length;
                this.table.forEach(function(value,key) {
                  // let symbol = value['symbol'];
                  if(totalSum === 0) {
                    value['target'] = (100 / tableLength).toFixed(1);
                  } else {
                    value['target'] = +((value['target'] / totalSum) * 100).toFixed(1);
                  }
                });
                // this makes sure we're not unbalanced by a fraction, if we are, just update one record
                if(this.sumTargetPercent !== 10000) {
                  let diff = (this.sumTargetPercent - 10000)/100;
                  let record = this.table.find(function(value) {
                      return !value['target'].isInteger;
                  })
                  record['target'] = record['target'] - diff;
                }
            },
            addExample() {
                this.table = [{"symbol":"VTI","asset_class":"U.S. Stocks","target":35,"shares":40,"bought_at":94.23,"name":"Vanguard Total Stock Market ETF"},{"symbol":"VEA","asset_class":"Foreign Stocks","target":25,"shares":75,"bought_at":28.44,"name":"Vanguard FTSE Developed Markets ETF"},{"symbol":"VWO","asset_class":"Emerging Markets","target":16,"shares":30,"bought_at":30.12,"name":"Vanguard FTSE Emerging Markets ETF"},{"symbol":"VNQ","asset_class":"Real Estate","target":12,"shares":10,"bought_at":60.45,"name":"Vanguard Real Estate ETF"},{"symbol":"EMB","asset_class":"Emrg. Market Bonds","target":12,"shares":5,"bought_at":90.34,"name":"iShares JP Morgan USD Emerging Markets Bond ETF"},{"symbol":"USD","name":"Cash or Equivalents","target":0,"shares":400,"last_price":1,"bought_at":1}];
                this.meta = {"name": "Example Scenario","description": "This scenario highlights an ideal asset allocation of a high risk-bearing individual using ETFs."};
                },
            save() {
                this.processingSave = true;
                axios.post('/saveScenario', {
                    pricesFetched: this.pricesFetched,
					isGuest: this.isGuest,
                    isNew: this.isNewScenario,
                    scenario: this.table,
                    meta: this.meta,
                })
                    .then(response => {
						
                    	if(this.isGuest) {
                    		window.location.href = '/scenario/share/' + response.data;
                    		// redirect to the new scenario created
						} else {
							window.location.href = '/dashboard'
						}
                        // location.reload();
                    })
                    .catch(error => {
                        location.reload();
                    });
            },
            fetchStockPrices() {
                this.isFetching = true;
                axios.post('/fetchStockPrices', {
                    symbols: this.getSymbols
                })
                    .then(response => {
                        this.stockPrices = response.data;
                        this.pricesFetched = true;

                        this.table.forEach(function(value,key) {
                            let symbol = value['symbol'];
                            // skip USD record
                            if(value['symbol'] !== 'USD') {
                                // set last price equal to the price returned from API
                                value['last_price'] = response.data[symbol];

                                // update qualifier based on response
                                value['iexprices'] = response.data[symbol] !== undefined;

                            }

                        });
                        this.isFetching = false;

                        // for (let [key, value] of Object.entries(response.data)) {
                        //     console.log(value,key);
                        // }
                    })
                    .catch(error => {
                        this.isFetching = false;
                        console.log(error);
                    });
            }

        }

    }

</script>

<template>

	<div>
		<transition enter-active-class="ease-out duration-200" enter-class="opacity-0" enter-to-class="opacity-100"
					leave-active-class="ease-in duration-100" leave-class="opacity-100" leave-to-class="opacity-0">
			<div v-if="optionsModalOpen">
				<balancer-options-modal :open=optionsModalOpen :settingsProp="primaryScenario"
										:cashProp="(records.USD ? records.USD.target : null)"
										@close="optionsModalOpen = false"></balancer-options-modal>
			</div>
		</transition>

		<div class="flex flex-col">
			<div class="-my-2 py-2 mb-6 sm:-mx-6 sm:px-6 lg:-mx-8 lg:px-8">
				<div class="align-middle inline-block min-w-full shadow overflow-hidden rounded-lg">
					<div class="border-b">
						<div class="sm:flex items-center justify-between pl-5 pr-3 py-3 bg-white">
<!--							<div class="">-->
								<span class="text-blue-dark font-normal self-center">Recommended Actions</span>

								<div class="flex items-center text-xs">

									<div class="text-gray-600 pr-2">Corrected Accuracy</div>
									<div class="w-2 h-2 rounded-full"
										 :style="{ backgroundColor: getValueColor(this.primaryScenario.corrected_accuracy)}"></div>
									<div class="pl-1">
										{{ decimalPercentFormat(this.primaryScenario.corrected_accuracy) }}
									</div>
									<svg class="ml-1 w-4 h-4 text-gray-400 hover:text-gray-600 transition ease-in-out duration-150 cursor-pointer" fill="currentColor" viewBox="0 0 20 20"
										 v-tooltip="{ html: 'correctedAccuracyTooltip', class: 'fade-in' }">
										xmlns="http://www.w3.org/2000/svg">
										<path fill-rule="evenodd"
											  d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-8-3a1 1 0 00-.867.5 1 1 0 11-1.731-1A3 3 0 0113 8a3.001 3.001 0 01-2 2.83V11a1 1 0 11-2 0v-1a1 1 0 011-1 1 1 0 100-2zm0 8a1 1 0 100-2 1 1 0 000 2z"
											  clip-rule="evenodd"></path>
									</svg>

									<div id="correctedAccuracyTooltip" class="text-xs text-gray-300">
										<b>Corrected Accuracy</b> measures how close your portfolio is to your ideal allocation after taking corrective actions. <br>
														In this case, <b>{{ decimalPercentFormat(this.primaryScenario.corrected_accuracy) }}</b> of the value of your portfolio would be correctly aligned to your targets after taking action.
									</div>

<!--								</div>-->


							</div>

							<div class="flex items-center">
								<div class="pr-3 whitespace-nowrap text-xs leading-5 text-gray-600">
									{{ this.scenarioOptions }}
								</div>

								<!-- show this second button on DESKTOP -->
								<span class="hidden sm:block">
									<button @click="optionsModalOpen = !optionsModalOpen" type="button"
											class="inline-flex justify-center w-full rounded-md border border-gray-300 p-2 bg-white text-gray-700 shadow-sm hover:text-gray-500 focus:outline-none focus:border-blue-300 focus:ring-blue transition ease-in-out duration-150 text-xs sm:leading-5">
										<svg
											class="w-4 h-4 text-gray-600 hover:text-blue-500 self-center cursor-pointer"
											fill="none" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
											viewBox="0 0 24 24" stroke="currentColor">
											<path
												d="M12 6V4m0 2a2 2 0 100 4m0-4a2 2 0 110 4m-6 8a2 2 0 100-4m0 4a2 2 0 110-4m0 4v2m0-6V4m6 6v10m6-2a2 2 0 100-4m0 4a2 2 0 110-4m0 4v2m0-6V4"></path>
										</svg>
									</button>
								</span>

							</div>

							<span class="sm:hidden">
								<!-- MOBILE -->
								<button @click="optionsModalOpen = !optionsModalOpen" type="button"
										class="inline-flex justify-center w-full cursor-pointer rounded-md border border-gray-300 p-2 mt-2 bg-white text-gray-700 shadow-sm hover:text-blue-500 focus:outline-none focus:border-blue-300 focus:ring-blue transition ease-in-out duration-150 text-xs sm:leading-5">
									<svg
										class="w-4 h-4 self-center"
										fill="none" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
										viewBox="0 0 24 24" stroke="currentColor">
										<path
											d="M12 6V4m0 2a2 2 0 100 4m0-4a2 2 0 110 4m-6 8a2 2 0 100-4m0 4a2 2 0 110-4m0 4v2m0-6V4m6 6v10m6-2a2 2 0 100-4m0 4a2 2 0 110-4m0 4v2m0-6V4"></path>
									</svg>
									<span class="sm:hidden text-xs text-center leading-3 self-center mx-2">Options</span>
								</button>
							</span>

						</div>
						<table class="min-w-full">
							<thead>
								<tr>
									<th class="sm:pl-5 pl-2 sm:pr-3 pr-1 py-3 border-b border-t border-gray-200 bg-gray-100 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
										Symbol
									</th>
									<th class="sm:px-3 px-1 py-3 border-b border-t border-gray-200 bg-gray-100 text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider text-right">
										Action
									</th>
									<th class="px-3 py-3 border-b border-t border-gray-200 bg-gray-100 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
										Shares (Total)
									</th>
									<!-- blank header for more info -->
									<th class="table-cell sm:hidden border-b border-t border-gray-200"></th>
									<th class="hidden sm:table-cell px-3 py-3 border-b border-t border-gray-200 bg-gray-100 text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider text-right">
										Corrected Value
									</th>
									<th class="hidden sm:table-cell px-3 py-3 border-b border-t border-gray-200 bg-gray-100 text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider text-right">
										Allocation Change
									</th>
									<th class="hidden sm:table-cell px-3 py-3 border-b border-t border-gray-200 bg-gray-100 text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider text-right">
										Target
									</th>
								</tr>
							</thead>
							<tbody class="bg-white">

								<tr v-for="(record,index) in records" :key=index>
									<td class="sm:pl-5 pl-2 sm:pr-3 pr-1 sm:whitespace-nowrap border-b border-gray-200 relative"
										style="max-width: 10rem;">
										<div class="text-sm leading-5 text-gray-900">{{ record.symbol }}</div>
										<!-- Full name scrollable -->
										<div class="hidden sm:block text-xs leading-5 text-gray-400 overflow-hidden">
											<span @mouseover="scrollOver(record.symbol)" :ref="record.symbol"
												  @mouseleave="scrollBack(record.symbol)"
												  class="hidden sm:inline-block whitespace-nowrap ease-linear"

											>{{ record.stock_name }}</span>
											<span class="absolute right-0" :ref="record.symbol + 'arrow'">
												{{ checkLength(record.stock_name) }}
											</span>

										</div>
									</td>
									<td class="sm:px-3 px-1 py-3 whitespace-nowrap border-b border-gray-200 text-center">

										<span v-if="record.symbol ==='USD'"
											  class="px-2 inline-flex text-xs leading-5 font-semibold rounded-full"
											  v-tooltip="{ html: 'cashActionTooltip', class: 'fade-in' }">
											<svg class="w-4 h-4 text-gray-600 hover:text-blue-500 self-center"
												 fill="none" stroke="currentColor" viewBox="0 0 24 24"
												 xmlns="http://www.w3.org/2000/svg">
												<path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
													  d="M7 8h10M7 12h4m1 8l-4-4H5a2 2 0 01-2-2V6a2 2 0 012-2h14a2 2 0 012 2v8a2 2 0 01-2 2h-3l-4 4z"></path>
											</svg>
											<div id="cashActionTooltip" class="text-xs text-gray-300">
												<table>
													<tbody>
														<tr>
															<td class="text-right font-bold">{{ moneyFormat(record.market_value) }}</td>
															<td class="pl-2">starting cash balance</td>
														</tr>
														<tr>
															<td class="text-right font-bold">+{{ moneyFormat(record.total_cash_from_sales) }}</td>
															<td class="pl-2">from sale of stock</td>
														</tr>
														<tr>
															<td class="text-right font-bold">–{{ moneyFormat(record.spent) }}</td>
															<td class="pl-2">set aside based on target</td>
														</tr>
														<tr>
															<td class="text-right font-bold border-b border-white">–{{ moneyFormat(record.total_spent_on_purchases) }}</td>
															<td class="pl-2">from purchase of stock</td>
														</tr>
														<tr class="text-white">
															<td class="text-right font-bold">{{ moneyFormat(record.new_market_value) }}</td>
															<td class="pl-2">resulting cash balance</td>
														</tr>
														<tr class="text-gray-600 italic">
															<td class="text-right font-bold">{{ moneyFormat(record.leftover_cash) }}</td>
															<td class="pl-2">difference to ideal balance</td>
														</tr>
													</tbody>
												</table>
											</div>

										</span>
										<span v-else
											  class="px-2 inline-flex text-xs leading-5 font-semibold rounded-full"
											  :class="actionColumnClasses(record.action)">
											{{ record.action }}
										</span>

									</td>
									<td class="px-3 whitespace-nowrap border-b border-gray-200">
										<!-- <div v-if="record.symbol === 'USD'" class="text-xs leading-5 text-gray-500">${{ record.cash_from_sale }} spent <br>-->
										<div v-if="record.symbol === 'USD'" class="text-xs leading-5 text-gray-500">
											<span
												class="text-xs"> +{{ moneyFormat(record.total_cash_from_sales) }} sale of stock</span><br>
											<span
												class="text-xs"> -{{ moneyFormat(record.total_spent_on_purchases) }} purchase of stock</span><br>
										</div>

										<div v-else
											 class="text-sm leading-5 text-gray-500">{{ roundTo(record.shares_delta) }}
											<span class="text-xs"> @ ${{ record.last_share_price }}</span>
											<span class="text-sm"> ({{ roundTo(record.final_shares) }})</span>
										</div>
									</td>

									<td class="sm:hidden table-cell border-b border-gray-200 px-2">
										<!-- More info for mobile -->
										<svg
											class="w-4 h-4 text-gray-400 hover:text-gray-700 transform hover:scale-110 ease-in-out duration-150"
											xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor"
											v-tooltip.ios="{ html: record.symbol, class: 'fade-in' }">
											<path
												d="M6 10a2 2 0 11-4 0 2 2 0 014 0zM12 10a2 2 0 11-4 0 2 2 0 014 0zM16 12a2 2 0 100-4 2 2 0 000 4z"/>
										</svg>
										<div :id='record.symbol'>
											{{ record.stock_name }}
											<table class="mt-1">
												<tbody class="border-t border-white text-gray-200">
													<tr>
														<td class="text-right font-bold">{{ moneyFormat(record.new_market_value) }}</td>
														<td class="pl-2">Corrected Value</td>
													</tr>
													<tr>
														<td class="text-right font-bold"><span
															class="text-gray-500">{{ decimalPercentFormat(record.current) }} ➡ </span>{{ decimalPercentFormat(record.new_percent) }}
														</td>
														<td class="pl-2">Allocation Change</td>
													</tr>
													<tr>
														<td class="text-right font-bold">{{ decimalPercentFormat(record.target) }}</td>
														<td class="pl-2">Target Percent</td>
													</tr>
												</tbody>
											</table>
										</div>
									</td>

									<td class="hidden sm:table-cell p-3 whitespace-nowrap border-b border-gray-200 text-sm leading-5 text-gray-500 text-right">
										<div
											class="text-sm leading-5 text-gray-900">{{ moneyFormat(record.new_market_value) }}
										</div>
									</td>

									<td class="hidden sm:table-cell p-3 whitespace-nowrap text-right border-b border-gray-200 text-xs leading-5 font-medium">
										<span
											class="text-gray-500">{{ decimalPercentFormat(record.current) }} ➡ </span>{{ decimalPercentFormat(record.new_percent) }}
									</td>
									<td class="hidden sm:table-cell p-3 whitespace-nowrap text-right border-b border-gray-200 text-sm leading-5 font-medium">
										{{ decimalPercentFormat(record.target) }}
									</td>
								</tr>

							</tbody>
						</table>
					</div>
				</div>
			</div>

		</div>
	</div>

</template>

<script>
import {displayHelpers} from "../../mixins/displayHelpers";

export default {
	name: "actionsTable",
	mixins: [displayHelpers],
	data() {
		return {
			optionsModalOpen: false,
		}
	},
	props: {
		primaryScenario: null,
		records: null,
	},
	methods: {
		actionColumnClasses(value) {
			switch (value) {
				case "BUY":
					return 'bg-green-200 text-green-800';
				case "SELL":
					return 'bg-red-200 text-red-800';
				case "HOLD":
					return 'bg-gray-200 text-gray-800';
			}
		},
		cashDetails(record) {
			return '<span>' + record.spent + '<br>' + record.final_shares + '</span>';
		},
		getValueColor(value) {
			if(value >= 0.95) { return '#2ecc71'; } else
			if(value >= 0.9) { return '#90C840'; } else
			if(value >= 0.8) { return '#f1c40f'; } else
			if(value >= 0.75) { return '#EC8826'; } else
			{ return '#e74c3c'; }
		},
	},
	computed: {
		// primary() {
		//     return this.scenarios.find(value => value.is_primary === true);
		// }
		scenarioOptions() {

			let urlParams = new URLSearchParams(window.location.search);

			if(urlParams.has('sharetype') && urlParams.get('strategy') && urlParams.get('sellthreshold')) {

				return this.capitalizeFirstLetter(urlParams.get('sharetype')) + " | " + this.capitalizeFirstLetter(urlParams.get('strategy')) + " | " + (urlParams.get('sellthreshold') === "nosell" ? 'No Selling' : urlParams.get('sellthreshold') + '% Sell Threshold');

			} else {
				return this.primaryScenario.rebalance_share_type + " | " + this.primaryScenario.rebalance_strategy + " | " + (this.primaryScenario.rebalance_selling_threshold === "No Selling" ? 'No Selling' : (this.primaryScenario.rebalance_selling_threshold * 100) + '% Sell Threshold');
			}

		}

	},
}
</script>
<template>

    <div class="flex flex-col">
        <div class="-my-2 py-2 mb-6 sm:-mx-6 sm:px-6 lg:-mx-8 lg:px-8">
            <div class="align-middle inline-block min-w-full shadow overflow-hidden rounded-lg">
                <div class="border-b">
                    <div class="sm:flex justify-between items-center px-5 py-3 bg-white">
                      <span class="text-blue-dark font-normal">Your Portfolio</span>
                      <div class="flex">

                        <div class="flex items-center text-xs">

                          <div class="text-gray-600 pr-2">Accuracy</div>
                          <div class="w-2 h-2 rounded-full"
                               :style="{ backgroundColor: getValueColor(this.primaryScenario.original_accuracy)}"></div>
                          <div class="pl-1">
                            {{ decimalPercentFormat(this.primaryScenario.original_accuracy) }}
                          </div>
                          <svg class="ml-1 w-4 h-4 text-gray-400 hover:text-gray-600 transition ease-in-out duration-150 cursor-pointer" fill="currentColor" viewBox="0 0 20 20"
                               v-tooltip="{ html: 'accuracyTooltip', class: 'fade-in' }">
                               xmlns="http://www.w3.org/2000/svg">
                            <path fill-rule="evenodd"
                                  d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-8-3a1 1 0 00-.867.5 1 1 0 11-1.731-1A3 3 0 0113 8a3.001 3.001 0 01-2 2.83V11a1 1 0 11-2 0v-1a1 1 0 011-1 1 1 0 100-2zm0 8a1 1 0 100-2 1 1 0 000 2z"
                                  clip-rule="evenodd"></path>
                          </svg>

                          <div id="accuracyTooltip" class="text-xs text-gray-300">
                            <b>Accuracy</b> measures how close your portfolio is to your target allocation. <br>
                            In this case, <b>{{ decimalPercentFormat(this.primaryScenario.original_accuracy) }}</b> of the value of your portfolio is correctly aligned to your targets.
                          </div>

                        </div>

                      </div>

                      <div class="flex items-center text-xs">

                        <div class="text-gray-600 pr-2">Overall Value</div>
                        <div class="whitespace-nowrap leading-5 text-gray-900">
                          {{moneyFormat(this.primaryScenario.total_position_value)}}
                        </div>

                      </div>

                    </div>
                    <table class="min-w-full">
                        <thead>
                            <tr>
                                <th class="sm:pl-5 pl-2 sm:pr-3 pr-1 py-3 border-b border-t border-gray-200 bg-gray-100 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
                                    Symbol (Shares)
                                </th>
                                <th class="sm:px-3 px-1 py-3 border-b border-t border-gray-200 bg-gray-100 text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider sm:text-right">
                                    Target
                                </th>
                                <th class="sm:px-3 px-1 py-3 border-b border-t border-gray-200 bg-gray-100 sm:text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider flex items-center h-16 md:h-full">
                                    Current
									<svg class="ml-1 w-4 h-4 text-gray-400 hover:text-gray-600 transition ease-in-out duration-150 cursor-pointer" fill="currentColor" viewBox="0 0 20 20"
										 v-tooltip="{ html: 'currentTooltip', class: 'fade-in w-full md:w-1/4' }">
										xmlns="http://www.w3.org/2000/svg">
										<path fill-rule="evenodd"
											  d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-8-3a1 1 0 00-.867.5 1 1 0 11-1.731-1A3 3 0 0113 8a3.001 3.001 0 01-2 2.83V11a1 1 0 11-2 0v-1a1 1 0 011-1 1 1 0 100-2zm0 8a1 1 0 100-2 1 1 0 000 2z"
											  clip-rule="evenodd"></path>
									</svg>
									<div id="currentTooltip" class="text-xs text-gray-300">
										Represents the current weight of each asset relative to the rest of your portfolio based on your position and the current stock price.<br><br>
										Colors approximately represent the primary sector each asset belongs to. Gradients represent the top two represented sectors. <br><br>
										<div class="flex items-center"><span class="h-2 rounded-full mr-2 w-12 bg-sector-comm"></span>Communication Services</div>
										<div class="flex items-center"><span class="h-2 rounded-full mr-2 w-12 bg-sector-condis"></span>Consumer Discretionary</div>
										<div class="flex items-center"><span class="h-2 rounded-full mr-2 w-12 bg-sector-constap"></span>Consumer Staples</div>
										<div class="flex items-center"><span class="h-2 rounded-full mr-2 w-12 bg-sector-en"></span>Energy</div>
										<div class="flex items-center"><span class="h-2 rounded-full mr-2 w-12 bg-sector-fin"></span>Financials</div>
										<div class="flex items-center"><span class="h-2 rounded-full mr-2 w-12 bg-sector-hc"></span>Health Care</div>
										<div class="flex items-center"><span class="h-2 rounded-full mr-2 w-12 bg-sector-ind"></span>Industrials</div>
										<div class="flex items-center"><span class="h-2 rounded-full mr-2 w-12 bg-sector-tech"></span>Information Technology</div>
										<div class="flex items-center"><span class="h-2 rounded-full mr-2 w-12 bg-sector-mat"></span>Materials</div>
										<div class="flex items-center"><span class="h-2 rounded-full mr-2 w-12 bg-sector-re"></span>Real Estate</div>
										<div class="flex items-center"><span class="h-2 rounded-full mr-2 w-12 bg-sector-util"></span>Utilities</div>
										<div class="flex items-center"><span class="h-2 rounded-full mr-2 w-12" style="background-color: #94A3B8"></span>Other/ Not Specified</div>

									</div>


                                </th>
                                <th class="sm:px-3 px-2 py-3 border-b border-t border-gray-200 bg-gray-100 text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider sm:whitespace-nowrap text-right">
                                    Market Value
                                </th>
                                <th class="hidden sm:block pl-3 pr-5 py-3 border-b border-t border-gray-200 bg-gray-100 text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider text-right">
                                    Return
                                </th>
                            </tr>
                        </thead>
                        <tbody class="bg-white">


                            <tr v-if="scenarioLoaded" v-for="(record,index) in records" :key=index>
                                <td class="sm:pl-5 pl-2 sm:pr-3 pr-1 sm:whitespace-nowrap border-b border-gray-200 relative" style="max-width: 10rem;">
                                    <div class="text-sm leading-5 text-gray-900">{{record.symbol}} ({{roundTo(record.quantity)}})
                                      <svg class="sm:hidden inline-block ml-1 w-4 h-4 text-gray-400 hover:text-gray-600 transition ease-in-out duration-150 cursor-pointer" fill="currentColor" viewBox="0 0 20 20"
                                           v-tooltip="{ content: record.stock_name, class: 'fade-in' }"
                                           xmlns="http://www.w3.org/2000/svg">
                                        <path fill-rule="evenodd"
                                              d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-8-3a1 1 0 00-.867.5 1 1 0 11-1.731-1A3 3 0 0113 8a3.001 3.001 0 01-2 2.83V11a1 1 0 11-2 0v-1a1 1 0 011-1 1 1 0 100-2zm0 8a1 1 0 100-2 1 1 0 000 2z"
                                              clip-rule="evenodd"></path>
                                      </svg>
                                    </div>
                                    <!-- Full name scrollable -->
                                    <div class="hidden sm:block text-xs leading-5 text-gray-400 overflow-hidden">
                                      <span
                                        @mouseover="scrollOver(record.symbol)" :ref="record.symbol"
                                        @mouseleave="scrollBack(record.symbol)"
                                        class="hidden sm:inline-block whitespace-nowrap ease-linear"

                                      >{{record.stock_name}}</span>
                                      <span class="absolute right-0" :ref="record.symbol + 'arrow'">
                                        {{checkLength(record.stock_name)}}
                                      </span>

                                    </div>
                                </td>
                                <td class="sm:px-3 px-1 py-3 whitespace-nowrap border-b border-gray-200 text-center sm:text-right">
                                    <div class="text-sm leading-5 text-gray-500">{{decimalPercentFormat(record.target)}}</div>
                                </td>
                                <td class="sm:px-3 px-1 py-3 whitespace-nowrap border-b border-gray-200 sm:w-64">
                                    <div class="flex items-center">
                                        <div class="text-sm leading-5 text-gray-900 w-16 text-center sm:text-right">{{decimalPercentFormat(record.current)}}</div>
                                        <div class="hidden sm:block ml-2 w-full">
                                            <div class="h-2 rounded-full flex-grow mr-2"
												 :class="record.bar_color"
                                                   :style="'width:' + [record.bar_width ? record.bar_width + '%;' : '0.5rem;'] +
                                                        'background-color: ' + record.bar_color +';'"></div>
                                        </div>
                                    </div>
                                </td>
                                <td class="sm:px-3 px-2 py-3 sm:whitespace-nowrap border-b border-gray-200 text-sm leading-5 text-gray-500 text-right">
                                    {{ moneyFormat(record.market_value) }}
                                </td>
                                <td class="hidden sm:table-cell pl-3 pr-5 py-3 whitespace-nowrap text-right border-b border-gray-200 text-sm leading-5 font-medium">
                                    {{ decimalPercentFormat(record.return)}}
                                </td>
                            </tr>




                        </tbody>
                    </table>
                </div>
            </div>
        </div>

    </div>

</template>

<script>
    import { displayHelpers } from "../../mixins/displayHelpers";
    export default {
        name: "portfolioTable",
        mixins: [displayHelpers],
        data() {
            return {
                scenarioLoaded: false,
                blankScenario: {
                    name: 'None',
                    description: '',
                    total_position_value: '0.00',
                },
            }
        },
        props: {
            primaryScenario: null,
            records: null,
        },
        methods: {
          getValueColor(value) {
            if(value >= 0.9) { return '#2ecc71'; } else
            if(value >= 0.8) { return '#90C840'; } else
            if(value >= 0.7) { return '#f1c40f'; } else
            if(value >= 0.6) { return '#EC8826'; } else
            { return '#e74c3c'; }
          },
        },
        mounted() {
          (this.primaryScenario === undefined) ? this.scenarioLoaded = false : this.scenarioLoaded = true;
          if(this.primaryScenario === undefined) this.primaryScenario = this.blankScenario

        },
    }
</script>
